<template>
  <Card class="template-card">
    <div class="flex flex-row align-middle mb-2">
      <Txt class="grow template-card-title" tabindex="0" role="region" aria-roledescription="card-title" @click="$emit('preview', page)">{{ page.name }}</Txt>
      <Icon v-if="isDeleteEnabled" tabindex="0" name="trash" role="button" :size="24" class="z-10" @click.native="$emit('delete', page)"/>
    </div>

    <!-- Template Preview Card Body -->
    <div class="preview-sm">
      <iframe v-if="previewSm"  tabindex="-1" :srcdoc="previewSm" frameborder="0" scrolling="no"></iframe>
    </div>
    <div v-if="!page.company_id" class="flex justify-end pt-2"><Icon name="web" /></div>
    <div class="iframe-overlay" @click="$emit('preview', page)"></div>
  </Card>
</template>

<script>
import LandingPageService from '@/services/landingpage.service'

export default {
  name: 'LandingPageCard',
  data: function () {
    return {
      previewLanguage: this.$i18n.locale,
      previewSm: null,
      pageContent: [
        {
          language: '',
          body: '',
        },
      ],
    }
  },
  props: ['page', 'search-text', 'company-data'],
  computed: {
    isDeleteEnabled() {
      return this.page.company_id && this.$checkPermission(['phishing-delete-all-landingpage', 'phishing-delete-landingpage']) || 
        !this.page.company_id && this.$checkPermission('phishing-delete-all-landingpage');
    }
  },
  async mounted() {
    this.$eventBus.$emit('loading', true)
    await this.getLandingPageContent()
    this.previewSm = await this.compilePreview(this.pageContent, false, this.previewLanguage, true)
    this.$eventBus.$emit('loading', false)
  },
  methods: {
    async getLandingPageContent() {
      try {
        const res = await LandingPageService.getLandingPageContent(this.page.company_id, this.page.page_id)
        this.pageContent = res.data
      }
      catch (err) {
        console.error("==> Error in fetching landing page content")
      }
    },
    compilePreview(lpContent, highlightSearch, language, isPreview) {
      return new Promise((resolve, reject) => {
        try {
          let lp = lpContent.filter((t) => {
            return t.language == language
          })
          if (lp.length == 0) {
            lp = lpContent.filter((t) => {
              return t.language == this.$profile && this.$profile.language
            })
            if (lp.length == 0 && this.companyData && this.companyData.company) {
              lp = lpContent.filter((t) => {
                return t.language == this.companyData.company.default_language
              })
            }
            if (lp.length == 0) {
              lp = lpContent.filter((t) => {
                return t.language == 'en'
              })
              if (lp.length == 0) {
                lp = lpContent
              }
            }
          }

          lp = lp[0]
          let highlightedBody = lp.body
          if (this.searchText && highlightSearch) {
            var re_search = new RegExp(this.searchText, 'gim')
            highlightedBody = lp.body.replace(re_search, (match) => {
              return `<span style="background: #ffc871;">${match}</span>`
            })
          }

          if (this.previewHints && isPreview) {
            // Replace hint placeholder
            let hintString = `<ul>`
            let hintCount = 0

            this.previewHints.forEach((hint) => {
              if (hintCount == 4) {
                return
              }
              if (hint.language == this.previewLanguage) {
                hintString += `<li><span class="hint">${hint.body}</span></li>`
                hintCount++
              }
            })

            hintString += `</ul>`
            highlightedBody = highlightedBody.replace(/{{.Top4HintsAsHtml}}/gim, hintString)

            let baseURL = this.companyData.gp_landing_url
            if (baseURL) {
              highlightedBody = highlightedBody.replace(/{{.BaseURL}}/gm, baseURL)
            }
          }

          resolve(highlightedBody)
        } catch (error) {
          reject(error)
        }
      })
    },
  },
}
</script>

<style lang="scss">
.preview-sm {
  cursor: pointer;
  height: 20rem;
  overflow: hidden;
  font-family: sans-serif !important;
}
.template-card {
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.125);
  transition: all 0.15s linear;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  .card-body {
    padding: 1rem;
  }
  .template-card-title {
    font-weight: bold;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.ellipsable-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.template-card:hover {
  box-shadow: -1px 10px 19px 0px rgba(0, 0, 0, 0.6);
}
.placeholder-card {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: #c2a7e4;
}
.placeholder-card:after {
  content: '*';
  color: #c2a7e4;
}
.placeholder-card-error {
  cursor: pointer;
  text-decoration: line-through;
  text-decoration-color: #b71c1c;
}
.placeholder-card-error:after {
  content: '*';
  color: #b71c1c;
}
.overlay {
  transition: all 0.4s linear;
  background-color: #eaecf0 !important;
}
.preview-sm iframe {
  height: 800px;
  margin-left: -50%;
  margin-top: -200px;
  width: 200%;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -o-transform: scale(0.5);
  -ms-transform: scale(0.5);
}
.iframe-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
