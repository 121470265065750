<template>
	<Card class="template-card" :class="{'overlay': template.deleted}" tabindex="0" @keydown.enter.native="$emit('preview', template)">
		<div class="flex flex-row align-middle">
			<div class="pt-1 mr-2">
				<font-awesome-icon :icon="getTemplateTypeIcon(template.type)" />
			</div>
			
			<Txt class="grow template-card-title">{{ template.name }}</Txt>
			<div class="z-[9]">
				<Toggle v-if="!campaignId" :value="template.deleted" :checked-value="null" :unchecked-value="1" :disabled="isEditDisabled" @input="$emit('delete', $event)"/>
				<Toggle v-if="campaignId" :value="template.status" :checked-value="1" :unchecked-value="0" :disabled="statusDisabled" @input="$emit('status', $event)"/>
			</div>
		</div>

		<div v-if="campaignId" :class="{ 'overlay': template.status != '1' }"></div>
		<div v-else :class="{ 'overlay': template.deleted == '1' }"></div>
		
		<!-- Template Preview Card Body -->
		<div class="preview-sm">
			<iframe v-if="previewSm" :srcdoc="previewSm" frameborder="0" scrolling="no" tabindex="-1"></iframe>
		</div>

		<div class="flex gap-1 justify-end pt-1">
			<Icon v-if="globalScope" name="web" />
			<Icon v-if="template.type !== TEMPLATE_TYPE_SMS && template.has_attachment" name="attachment" />
		</div>
		<div class="iframe-overlay" @click="$emit('preview', template)"></div>
	</Card>
</template>

<script>
import { TemplateType, TemplateScope, QRCode } from '@/common/constants';

export default {
	name: "TemplateCard",
	data: function() {
		return {
			loading: true,
			locale: this.$i18n.locale,
			previewLanguage: '',
			templateContent: null,
			previewSm: null,
			TEMPLATE_TYPE_SMS: null,
			TEMPLATE_TYPE_EMAIL: null,
			TEMPLATE_TYPE_QRCODE: null,
			TEMPLATE_TYPE_USB: null
		};
	},
	props: ["template", "campaign-id", "search-text", "company-data", "status-disabled"],
	computed: {
		templateId() {
			return this.template.template_id;
		},
		globalScope() {
			return this.template && this.template.scope == TemplateScope.Global;
		},
		isEditDisabled() {
			const companyId = this.template.company_id;
			return companyId && !this.$checkPermission(['phishing-edit-all-template', 'phishing-edit-template']) || !companyId && !this.$checkPermission('phishing-edit-all-template');
		}
	},
	methods: {
		async getTemplateContent() {
			this.templateContent = this.template.body;

			this.previewLanguage = this.templateContent.find(element => element.language == this.locale) ? this.locale : null;
			if (this.previewLanguage == null) {
				// If the 'locale' version does not exist, try displaying the Template in English, otherwise pick the first available language
				this.previewLanguage = this.templateContent.find(element => element.language == "en") ? "en" : this.templateContent[0].language;
			}

			// sanitize all template contents
			let templateContents = JSON.parse(JSON.stringify(this.templateContent))
			templateContents.forEach(template => {
				template.body = this.$options.filters.sanitizeRawHtml(template.body);
			});
			this.previewSm = await this.compilePreview(templateContents, true, this.previewLanguage);

			this.loading = false;
		},
		compilePlaceholders(text, template, highlight) {
			let landingPage = this.template.landing_page_url || '#';
			let baseURL = this.companyData.gp_landing_url;
			let logo = this.companyData.company.logo? this.companyData.company.logo + '?_t=' + this.$dateTime.now().ts : '';

			// Extract baseURL from landing_page_url
			if(this.template.landing_page_url) {
				let firstIndex = (this.template.landing_page_url.indexOf('://') > -1 ) ? this.template.landing_page_url.indexOf('://') + 3 : this.template.landing_page_url.length;
				baseURL = this.template.landing_page_url.substr(0, firstIndex);
				let secondUrlPart = this.template.landing_page_url.substr(firstIndex);
				let lastIndex = (secondUrlPart.indexOf('/') > -1) ? secondUrlPart.indexOf('/') : secondUrlPart.length;
				baseURL += secondUrlPart.substr(0, lastIndex);
			}
			let firstname = this.$i18n.t('Campaigns.PlaceholderFirstname');
			let email = this.$i18n.t('Campaigns.PlaceholderEmail');

			let finalText = text.replace(/{{\.LastName}}/gm, `{{.FirstName}}`)
				.replace(/{{\.FirstName}} {{\.FirstName}}/gm, `{{.FirstName}}`) 
				.replace(/{{\.FirstName}}/gm, (highlight) ? `<span title="${firstname}" class="placeholder-card">${this.$profile.firstname}</span>`: this.$profile.firstname)
				.replace(/{{\.Email}}/gm, (highlight) ?`<span title="${email}" class="placeholder-card">${this.$profile.email}</span>`: this.$profile.email)
				.replace(/{{\.Tracker}}/gm, '')
				.replace(/{{\.TrackingURL}}/gm, '')
				.replace(/{{\.From}}/gm, template.sender)
				.replace(/{{\.Position}}/gm, '')
				.replace(/{{\.RId}}/gm, '')
				.replace(/{{\.Img=(\S.*?)\.(jpg|jpeg|png)}}/gi, (match, name, ext) => {
					return `${this.companyData.img_url}/${name}.${ext}`;
				})
				.replace(/{{\.BaseURL}}/gm, baseURL)
				.replace(/{{\.CompanyLogoAsDataURL}}/gm, logo)
				.replace(/<sms>/gm, '')
				.replace(/<cgtelephone>.*$/gm, '')
				.replace(/{{\.URL}}/gm, this.template.type != this.TEMPLATE_TYPE_SMS ? landingPage : '<a href="' + landingPage + '">' + landingPage + '</a>')
				.replace(/{{\.QRCode}}/gm, QRCode.value)
				.replace(/{{\.CurrentDate([+-])([0-9]+)}}/g, (match, symbol, days) => {
					let date = symbol === "+" ? this.$dateTime.now().plus({days}) : this.$dateTime.now().minus({days});
					return this.$dateTime.fromISO(date).toFormat('dd/MM/yyyy')})
				.replace(/{{\.*[a-zA-Z0-9\s][^}]*}}/gm, (match) => { 
					return (highlight) ? `<span title="${this.$i18n.t('Template.WrongPlaceholderError')}" class="placeholder-card-error">${match}</span>`: '<invalid-placeholder>' 
				});

			return finalText;
		},
		compilePreview(templateContent, highlightSearch, language) {
			return new Promise((resolve, reject) => {
				try {
					let template = templateContent.filter((t) => { return t.language == language; });
					if(template.length == 0) {
						template = templateContent.filter((t) => { return t.language == this.$profile.language; });
						if(template.length == 0 && this.companyData && this.companyData.company) {
							template = templateContent.filter((t) => { return t.language == this.companyData.company.default_language; });
						}
						if(template.length == 0) {
							template = templateContent.filter((t) => { return t.language == 'en'; });
							if(template.length == 0) {
								template = templateContent;
							}
						}
					}
					
					template = template[0];

					if(typeof template == 'undefined' || typeof template.body == 'undefined') {
						return resolve('<i class="far fa-edit"></i>');
					}

					let highlightedBody = template.body;
					if (this.searchText && highlightSearch) {
						var regEx = new RegExp("(" + this.searchText + ")(?!([^<]+)?>)", "gi");
						highlightedBody = template.body.replace(regEx, "<span style='background: #ffc871;'>$1</span>")
					}

					let finalBody = this.compilePlaceholders(highlightedBody, template, true);
					resolve(finalBody);
				} catch (error) {
					reject(error);
				}
			});
		},
		getTemplateTypeIcon(templateType) {
			switch(templateType) {
				case this.TEMPLATE_TYPE_SMS:
					return ['fas', 'mobile'];
				case this.TEMPLATE_TYPE_QRCODE:
					return ['fas', 'qrcode'];
				case this.TEMPLATE_TYPE_USB:
					return ['fab', 'usb'];
				default:
					return ['fas', 'envelope'];
			}
		}
	},
	created() {
		this.TEMPLATE_TYPE_SMS = TemplateType.SMS;
		this.TEMPLATE_TYPE_EMAIL = TemplateType.Email;
		this.TEMPLATE_TYPE_QRCODE = TemplateType.QRCode;
		this.TEMPLATE_TYPE_USB = TemplateType.USB;
		this.TEMPLATE_TYPE_TRAINING = TemplateType.Training;

		// Retrieve locale settings
		this.locale = this.$i18n.locale;

		this.getTemplateContent();
	}
};
</script>

<style lang="scss" scoped>
.preview-sm {
	cursor: pointer;
	height: 20rem;
	overflow: hidden;
	font-family: sans-serif !important;
}
.template-card {
	cursor: pointer;
	border: 1px solid rgba(0,0,0,.125);
	transition: all 0.15s linear;
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
	.template-card-title {
		font-weight: bold;
		font-size: 18px;
		white-space: nowrap; 
		overflow: hidden; 
		text-overflow: ellipsis;
	}
}
.template-card:hover {
	box-shadow: -1px 10px 19px 0px rgba(0, 0, 0, 0.6);
}
.overlay {
	opacity: 0.4;
	transition: all 0.4s linear;
	background-color: #eaecf0!important;
}
.preview-sm iframe {
	height: 800px;
	margin-left: -20%;
	margin-top: -80px;
	width: 200%;
	-webkit-transform: scale(0.8);
	-moz-transform:scale(0.8);
	-o-transform:scale(0.8);
	-ms-transform:scale(0.8);
}
.iframe-overlay {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}
</style>