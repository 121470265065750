<template>
  <InputWrapper
    :errored="errored"
    :error-message="errorMessage"
    :large="large"
    :label="label"
    :disabled="disabled"
    :unstyled-prefix="unstyledPrefix"
    :unstyled-suffix="unstyledSuffix"
  >
    <template v-if="$slots.prefix" #prefix>
      <slot name="prefix" />
    </template>
    <template v-if="$slots.suffix" #suffix>
      <slot name="suffix" />
    </template>
    <input
      :id="id"
      class="input"
      :value="value"
      :type="type"
      :name="name"
      :placeholder="!disabled? placeholder : null"
      :disabled="disabled"
      :readonly="readonly"
      :required="required"
      :min="min"
      :max="max"
      :step="step"
      @input="$emit('input', $event.target.value)"
      @change="$emit('change', $event.target.value)"
      @click="$emit('click')"
    />
  </InputWrapper>
</template>

<script>
import InputWrapper from './InputWrapper.vue'

export default {
  components: { InputWrapper },
  props: {
    type: {
      type: String, // 'text' | 'email' | 'password'
      default: 'text',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    id: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: [String, Number],
      default: undefined,
    },
    unstyledPrefix: {
      type: Boolean,
      default: false,
    },
    unstyledSuffix: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: undefined,
    },
    errored: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: undefined,
    },
    max: {
      type: Number,
      default: undefined,
    },
    step: {
      type: Number,
      default: undefined
    }
  },
  emits: ['input', 'change'],
}
</script>

<style lang="scss" scoped>
.input {
  @apply appearance-none;
  @apply w-full px-2 rounded-none;
  @apply text-sm text-primary-700 placeholder:text-primary-300;
  outline: none;
  box-shadow: none;
  @apply disabled:bg-primary-100 disabled:cursor-not-allowed;
}
input:read-only {
  @apply text-primary-300;
}
</style>
