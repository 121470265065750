<template>
  <component
    :is="inferredTag"
    :class="{
      h1: inferredType === 'h1',
      h2: inferredType === 'h2',
      h3: inferredType === 'h3',
      h4: inferredType === 'h4',
      h5: inferredType === 'h5',
      h6: inferredType === 'h6',
      subtitle: inferredType === 'subtitle',
      footnote: inferredType === 'footnote',
      'font-normal': weight === 'normal',
      'font-medium': weight === 'medium',
      'font-bold': weight === 'bold',
      'text-xs': extraSmall,
      'text-sm': small,
      'text-md': large,
      'text-left': left,
      'text-center': center,
      'text-right': right,
      'v-no-margin': noMargin,
      uppercase,
    }"
    :style="{
      fontSize: typeof size === 'number' ? `${size}px` : size,
    }"
     @click="$emit('click')"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    as: {
      type: String, // 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'small'
      default: undefined,
    },
    type: {
      type: String, // 'h1' | 'h2' | 'h3' | 'h4' | 'subtitle' | 'footnote'
      default: undefined,
    },
    weight: {
      type: String, // 'regular' | 'medium' | 'bold'
      default: undefined,
    },
    size: {
      type: [String, Number],
      default: undefined,
    },
    extraSmall: {
      type: Boolean,
      default: undefined,
    },
    small: {
      type: Boolean,
      default: undefined,
    },
    large: {
      type: Boolean,
      default: undefined,
    },
    uppercase: {
      type: Boolean,
      default: undefined,
    },
    left: {
      type: Boolean,
      default: undefined,
    },
    center: {
      type: Boolean,
      default: undefined,
    },
    right: {
      type: Boolean,
      default: undefined,
    },
    noMargin: {
      type: Boolean,
      default: undefined,
    },
  },
  emits: ['click'],
  computed: {
    inferredTag() {
      return this.as ? this.as : this.type?.startsWith('h') ? this.type : 'p'
    },
    inferredType() {
      return this.type ? this.type : this.inferredTag.startsWith('h') ? this.inferredTag : 'p'
    },
  },
}
</script>

<style lang="scss" scoped>
.h1 {
  @apply text-5xl md:text-7xl xl:text-9xl;
  @apply font-bold;
}

.h2 {
  @apply text-3xl md:text-5xl xl:text-6xl;
  @apply font-bold;
}

.h3 {
  @apply text-xl md:text-2xl xl:text-4xl;
  @apply font-bold;
}

.h4 {
  @apply text-md xl:text-lg;
  @apply font-bold;
}

.h5 {
  @apply text-base;
  @apply font-bold;
}

.h6 {
  @apply text-sm;
  @apply font-bold;
}

.subtitle {
  @apply text-sm md:text-base xl:text-md;
  @apply font-bold;
}

.footnote {
  @apply text-xs;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.subtitle {
  &:not(.v-no-margin) {
    @apply mb-[1em];
  }
}
</style>
