<template>
  <ul class="todo-list">
    <slot />
  </ul>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.todo-list {
  @apply flex flex-col;
  @apply divide-y divide-primary-100;
}
</style>
