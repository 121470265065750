var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{attrs:{"open":_vm.localShowModal,"footer-alignment":"center","header-alignment":"center","with-close-button":false,"with-expand-button":false,"trigger":_vm.trigger,"title":_vm.$t('Wcag.PolicyModalTile')},on:{"update:open":function($event){_vm.localShowModal=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('Button',{on:{"click":_vm.confirmPolicyAgreement}},[_vm._v(_vm._s(_vm.$t('Login.WelcomeButton')))])]},proxy:true}])},[_c('div',{staticClass:"w-full bg-primary-100 text-primary-500 p-10 text-sm text-center"},[(_vm.welcomeMessage)?_c('Txt',{attrs:{"weight":"bold","small":"","no-margin":""}},[_vm._v(_vm._s(_vm.welcomeMessage))]):_vm._e()],1),_c('div',{staticClass:"w-full"},[_c('p',{staticClass:"mx-20 my-5",domProps:{"innerHTML":_vm._s(
        _vm.$t('Login.WelcomeMessage', {
          termsLink: _vm.termsLink,
          policyLink: _vm.policyLink,
          linkStyle: 'download-link',
        })
      )}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }