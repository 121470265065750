import axios from "axios";
import Vue from 'vue'

export const apiClient = axios.create({
  baseURL: "/api",
  headers: {
    Accept: "application/json",
    "Cg-CSRF-CH": "sCtmejAAJ6", // Aggiungo un header custom
  },

});

apiClient.interceptors.request.use((config) => {
  const activeRole = localStorage.getItem('activeRole');

  if (activeRole) {
    config.headers['X-Active-Role'] = activeRole;
  }

  //  get masked state from localStorage
  const maskedAccount = localStorage.getItem('mask');
  if (maskedAccount) {
    config.headers['X-Account-ID'] = maskedAccount
  }
  // console.log(' headers:', config.headers)
  return config
})

apiClient.interceptors.response.use((response) => {
  return response;
},
  (error) => {
    console.log(error)
    if (error.response && error.response.status && 401 === error.response.status) {
      Vue.prototype.$eventBus.$emit('logout');
    }

    return Promise.reject(error);
  });
