var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{class:{
    'navbar-item': true,
    active: _vm.active,
  },on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.active)?_c('span',{staticClass:"navbar-item-arrow",style:({
      ['--arrow-color']: _vm.arrowColor,
    })}):_vm._e(),_c(_vm.tag,_vm._b({tag:"component",class:{
      'navbar-link': true,
      'navbar-button': !_vm.to,
      active: _vm.active,
    },attrs:{"to":_vm.to,"aria-label":_vm.ariaLabel}},'component',_vm.hasPopup,false),[(_vm.icon)?_c('Icon',{attrs:{"name":_vm.icon,"size":24,"alt":_vm.$slots?.default[0]?.text}}):_vm._e(),_c('span',{staticClass:"navbar-link-text"},[_vm._t("default")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }