var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:{
    button: true,
    'c-primary': _vm.color === 'primary',
    'c-accent': _vm.color === 'accent',
    'c-secondary': _vm.color === 'secondary',
    'c-light': _vm.color === 'light',
    'c-green': _vm.color === 'green',
    'c-orange': _vm.color === 'orange',
    'c-yellow': _vm.color === 'yellow',
    'c-red': _vm.color === 'red',
    'c-custom-primary': _vm.color === 'custom-primary',
    'c-custom-secondary': _vm.color === 'custom-secondary',
    'v-fill': _vm.variant === 'fill',
    'v-outline': _vm.variant === 'outline',
    'v-ghost': _vm.variant === 'ghost',
    'f-circle': _vm.shape === 'circle',
    'f-square': _vm.shape === 'square',
    's-small': _vm.small,
    's-extra-small': _vm.extraSmall,
    's-large': _vm.large,
  },attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.$slots.prefix)?_c('span',{staticClass:"button-prefix"},[_vm._t("prefix")],2):_vm._e(),_c('span',{staticClass:"button-content"},[_vm._t("default")],2),(_vm.$slots.suffix)?_c('span',{staticClass:"button-suffix"},[_vm._t("suffix")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }