<template>
  <li
    :class="{
      'todo-list-item': true,
      done,
      disabled,
      loading,
    }"
  >
    <div class="todo-list-item-content">
      <span class="todo-list-item-check">
        <Icon v-if="done" name="checkSmall" />
      </span>
      <div class="todo-list-item-text">
        <slot />
      </div>
      <div v-if="$slots.actions" class="todo-list-item-actions">
        <slot name="actions" />
      </div>
    </div>
    <div v-if="loading" class="todo-list-item-loading">
      <Progress color="violet" :value="loadingValue" :indeterminate="loadingValue == null" />
    </div>
  </li>
</template>

<script>
import Icon from './Icon.vue'
import Progress from './Progress.vue'

export default {
  components: { Icon, Progress },
  props: {
    done: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingValue: {
      type: Number,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.todo-list-item {
  @apply text-sm;
  @apply py-8 md:py-6;

  &.done,
  &.disabled,
  &.loading {
    @apply text-primary-300;
  }

  &.disabled {
    > * {
      @apply cursor-not-allowed;
    }
  }
}

.todo-list-item-content {
  @apply flex flex-wrap items-start;
  @apply md:items-center md:flex-nowrap;
  @apply px-4;
}

.todo-list-item-check {
  @apply flex-shrink-0 flex items-center justify-center;
  @apply w-5 h-5 rounded-full text-3xl;
  @apply border border-primary-300;
  @apply mr-3;

  .done.disabled & {
    @apply border-primary-300 bg-primary-300 text-white;
  }

  .done:not(.disabled) & {
    @apply border-green-500 bg-green-500 text-white;
  }
}

.todo-list-item-text {
  @apply flex-1;
  @apply md:flex md:items-center;
  @apply pt-0.5; // font adjustment
}

.todo-list-item-actions {
  @apply flex-shrink-0 flex items-center space-x-3;
  @apply whitespace-nowrap;
  @apply basis-full md:basis-0;
  @apply pl-5 md:pl-0;
  @apply mt-4 md:mt-0;
  @apply ml-3;
}

.todo-list-item-loading {
  @apply pt-3;
}
</style>
