var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.open)?_c('div',{ref:"modalContainerEl",class:{
    modal: true,
    open: _vm.open,
    closing: !_vm.open,
    's-small': _vm.small,
    's-expanded': _vm.expanded || _vm.fullWidth,
    's-full-height': _vm.fullHeight,
  },attrs:{"role":"dialog","aria-modal":"true","tabindex":_vm.open ? -1: 0,"aria-live":"assertive","aria-label":_vm.title,"aria-hidden":!_vm.open},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;return _vm.handleTab.apply(null, arguments)}}},[_c('div',{staticClass:"modal-overlay"}),_c('div',{ref:"dialogEl",staticClass:"modal-dialog",style:({
      width: _vm.expanding ? `${_vm.maxWidth}px` : _vm.compressing ? `${_vm.initialSize[0]}px` : undefined,
      height: _vm.expanding ? `${_vm.maxHeight}px` : _vm.compressing ? `${_vm.initialSize[1]}px` : undefined,
      maxWidth: _vm.expanding ? '100%' : _vm.compressing ? 'auto' : undefined,
      maxHeight: _vm.expanding ? '100%' : _vm.compressing ? 'auto' : undefined,
    })},[_c('header',{class:{
        'modal-header': true,
        'a-left': _vm.headerAlignment === 'left',
        'a-center': _vm.headerAlignment === 'center',
        'a-right': _vm.headerAlignment === 'right',
      }},[_c('div',{staticClass:"modal-header-actions"},[(_vm.withExpandButton)?_c('Button',{attrs:{"variant":"ghost","aria-label":_vm.$t('Wcag.' + (_vm.expanded ? 'Compress' : 'Expand') + 'Modal')},on:{"click":_vm.toggleExpanded}},[_c('Icon',{attrs:{"name":_vm.expanded ? 'compress' : 'expand'}})],1):_vm._e(),(_vm.withCloseButton)?_c('Button',{staticClass:"modal-close",attrs:{"aria-label":_vm.$t('General.Close'),"variant":"outline"},on:{"click":function($event){return _vm.$emit('update:open', false)}}},[_c('Icon',{attrs:{"name":"close"}})],1):_vm._e()],1),(_vm.iconName)?_c('div',{staticClass:"modal-icon"},[_c('Icon',{attrs:{"name":_vm.iconName}})],1):_vm._e(),(_vm.title)?_c('Txt',{staticClass:"modal-title break-words",class:{ 'text-center' : _vm.titleCentered },attrs:{"as":"h3","no-margin":""}},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.description)?_c('Txt',{staticClass:"modal-description",attrs:{"small":"","no-margin":""}},[_vm._v(_vm._s(_vm.description))]):_vm._e()],1),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"modal-content"},[_vm._t("default")],2)]),(_vm.footerVisible)?_c('footer',{class:{
        'hidden': !_vm.footerVisible,
        'modal-footer': true,
        'c-light': _vm.footerBackground === 'light',
        'a-left': _vm.footerAlignment === 'left',
        'a-center': _vm.footerAlignment === 'center',
        'a-right': _vm.footerAlignment === 'right',
      }},[_vm._t("footer")],2):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }