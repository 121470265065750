<template>
  <router-link
    :to="linkTo"
    :class="{
      link: true,
      'v-unstyled': variant === 'unstyled',
    }"
    :target="target"
    :rel="rel"
  >
    <slot />
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: undefined,
    },
    routeName: {
      type: String,
      default: undefined,
    },
    routeParams: {
      type: Object,
      default: undefined,
    },
    tab: {
      type: String,
      default: undefined,
    },
    nofollow: {
      type: Boolean,
      default: false,
    },
    noreferrer: {
      type: Boolean,
      default: false,
    },
    noopener: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String, // 'unstyled'
      default: undefined,
    },
  },
  computed: {
    linkTo() {
      return this.to || "/"
    },
    target() {
      return this.tab ? '_blank' : undefined
    },
    rel() {
      return [
        this.nofollow ? 'nofollow' : '',
        this.noreferrer ? 'noreferrer' : '',
        this.noopener ? 'noopener' : '',
      ].join(' ')
    },
  },
}
</script>

<style lang="scss" scoped>
.link {
  &:not(.v-unstyled) {
    //@apply text-accent-700;
    @apply text-primary-700;
    @apply underline hover:no-underline;
  }
}
</style>
