<template>
  <component 
    :is="tag" 
    class="menu-item" 
    :class="{
      'font-normal': weight === 'normal',
      'font-medium': weight === 'medium',
      'font-bold': weight === 'bold'
    }" 
    role="menuitem"
    :to="to" 
    tabindex="0"
    variant="unstyled" 
    @click="$emit('click')">
    <Icon v-if="iconName" :name="iconName" />
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    as: {
      type: String, // 'div' | 'button' | 'link'
      default: 'button',
    },
    to: {
      type: String, // 'div' | 'button' | 'a'...
      default: undefined,
    },
    iconName: {
      type: String,
      default: undefined,
    },
    weight: {
      type: String, // 'regular' | 'medium' | 'bold'
      default: undefined,
    }
  },
  emits: ['click'],
  computed: {
    tag() {
      return this.to ? 'Link' : this.as
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-item {
  @apply appearance-none;
  @apply flex items-center text-left;
  @apply px-3.5 py-1.5;
  @apply min-w-[19rem];
  @apply hover:bg-soft-blue-300;

  .icon {
    @apply text-[1.25em] mr-2;
  }
}
</style>
