var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{staticClass:"risk-rating-card",attrs:{"disabled":_vm.disabled},scopedSlots:_vm._u([(_vm.to)?{key:"footer",fn:function(){return [_c('Link',{staticClass:"risk-rating-card-link",attrs:{"to":_vm.to,"variant":"unstyled"}},[_vm._v(_vm._s(_vm.linkLabel))])]},proxy:true}:null],null,true)},[_c('div',{staticClass:"risk-rating-card-content"},[_c('CircularProgress',{staticClass:"risk-rating-card-progress",attrs:{"value":_vm.progress,"size":78,"color":_vm.progressColor},scopedSlots:_vm._u([{key:"default",fn:function({ progress: percent }){return [_c('div',{staticClass:"risk-rating-card-shield"},[_c('Icon',{class:{
              'text-soft-blue-100': _vm.progressColor === 'violet' || _vm.progressColor === 'blue',
              'text-accent-100': _vm.progressColor === 'orange',
              'text-acid-100': _vm.progressColor === 'acid',
              'text-green-100': _vm.progressColor === 'green',
              'text-red-100': _vm.progressColor === 'red',
            },attrs:{"name":"shieldSolid","size":56}}),_c('Txt',{attrs:{"weight":"bold","size":14}},[_vm._v(_vm._s(percent)+"%")])],1)]}}])}),_c('div',{staticClass:"risk-rating-card-text"},[_c('Txt',{staticClass:"risk-rating-card-title",attrs:{"weight":"bold"}},[_vm._v(_vm._s(_vm.title))]),(_vm.description)?_c('Txt',{staticClass:"risk-rating-card-description",attrs:{"small":""}},[_vm._v(_vm._s(_vm.description))]):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }