var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.visible)?_c('li',{class:{
    'contextual-menu-item': true,
    'with-progress': _vm.progress != null,
    'submenu-expanded': _vm.submenuExpanded,
    todo: _vm.progress === 0,
    done: _vm.done,
    active: _vm.active,
  }},[_c('router-link',{staticClass:"contextual-menu-item-link",attrs:{"to":_vm.to},nativeOn:{"!click":function($event){return _vm.handleClick.apply(null, arguments)}}},[_c('Txt',{staticClass:"contextual-menu-item-text",attrs:{"as":"span","small":""}},[_vm._t("default")],2),(_vm.done)?_c('span',{staticClass:"contextual-menu-item-check"},[_c('Icon',{attrs:{"name":"checkSmall","size":24}})],1):_vm._e(),(_vm.progress != null && !_vm.$slots.submenu)?_c('span',{staticClass:"contextual-menu-item-circular-progress"},[_c('CircularProgress',{attrs:{"value":_vm.progress,"size":18,"track-width":2,"color":"orange","hide-content":""}})],1):_vm._e()],1),(_vm.$slots.submenu)?_c('ul',{ref:"submenuEl",staticClass:"contextual-menu-item-submenu",style:({
      height: _vm.submenuExpanded ? `${_vm.submenuHeight}px` : '0px',
    })},[_vm._t("submenu")],2):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }