<template>
  <div>
    <div class="mainContainer" :class="{ 'bg-white': !isDark, 'bg-gray-50': isDark }">
      <button
        :aria-expanded="String(isOpen)"
        :aria-controls="`collapse${_uid}`"
        @click="toggleAccordion()"
      >
        <div class="numRecords">
          <span v-show="showNumberOfRecords" class="numRecords">{{ numberOfRecords }}</span>
          <span>{{ title }}</span>
        </div>

        <svg
          class="w-3 transition-all duration-200 transform"
          :class="{
            'rotate-180': isOpen,
            'rotate-0': !isOpen,
          }"
          fill="none"
          stroke="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 10"
          aria-hidden="true"
        >
          <path
            d="M15 1.2l-7 7-7-7"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <div v-show="extraIcons" class="actions">
        <slot name="actions"/>
      </div>
    </div>
    <div v-show="isOpen" :id="`collapse${_uid}`" class="content">
      <slot name="content"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: undefined
    },
    numberOfRecords: {
      type: Number,
      default: undefined
    },
    showNumberOfRecords: {
      type: Boolean,
      default: undefined
    },
    extraIcons: {
      type: Boolean,
      default: undefined
    },
    isDark: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    }
  }
}
</script>

<style lang="scss" scoped>
.mainContainer {
  @apply flex p-3 w-full items-center;

  .numRecords {
    @apply flex items-center;
  }

  span.numRecords {
    @apply pt-0.5 text-xs w-6 h-6 text-white rounded-full bg-accent-500 flex items-center justify-center mr-2;
  }

  button {
    @apply flex items-center justify-between w-full;
    @apply text-sm text-primary-500;
    @apply font-bold;
  }

  .actions {
    @apply ml-5 flex items-center space-x-2;
  }
}
</style>