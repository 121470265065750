<template>
    <div
    ref="navbarEl"
    :class="{
      navbar: true,
      'navbar-expanded': navbarExpanded,
      'navbar-expanding': navbarExpanding,
      'menu-show': menuOpen,
    }"
  >
    <Link class="navbar-logo" :to="logoLinkTo" variant="unstyled">
      <span class="navbar-logo-default">
        <slot name="logo" />
      </span>
      <span class="navbar-logo-small">
        <slot name="logo-small" />
      </span>
    </Link>
    <div class="navbar-title">
      <slot name="title" />
    </div>
        <div class="navbar-notifications">
          <slot name="notifications"/>
        </div>
        <div class="navbar-toggle">
          <MenuToggleButton :open="menuOpen" @click="menuOpen = !menuOpen"/>
        </div>
    <nav class="navbar-menu">
      <ul v-if="$slots['main-menu']" class="navbar-main-menu">
        <slot name="main-menu" />
      </ul>
      <ul v-if="$slots['footer-menu']" class="navbar-footer-menu">
        <slot name="footer-menu" />
      </ul>
      <button class="navbar-expander" :class="{'navbar-expander-open': navbarExpanded}" 
        :aria-label="navbarExpanded ? $t('Wcag.MenuClose') : $t('Wcag.MenuExpand')" @click="toggleExpanded">
        <Icon :name="navbarExpanded ? 'menuOpen' : 'menuClosed'" :size="24" />
        <span class="navbar-expander-label">{{ $t('Navbar.CollapseMenu') }}</span>
      </button>
      <div v-if="footnote" class="navbar-footnote">
        <span class="navbar-footnote-label">{{ footnoteLabel }}</span>
        <span class="navbar-footnote-text">{{ footnote }}</span>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    defaultExpanded: {
      type: Boolean,
      default: true,
    },
    logoLinkTo: {
      type: String,
      default: '/',
    },
    footnoteLabel: {
      type: String,
      default: 'Powered by',
    },
    footnote: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      menuOpen: false,
      navbarExpanded: this.defaultExpanded,
      navbarExpanding: false,
    }
  },
  methods: {
    toggleExpanded() {
      if (!this.navbarExpanded) {
        this.$refs.navbarEl.addEventListener(
          'transitionend',
          () => {
            this.navbarExpanded = true
            this.navbarExpanding = false
          },
          { once: true }
        )
        this.navbarExpanding = true
      } else {
        this.navbarExpanded = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  @apply flex items-center lg:flex-col;
  @apply w-full lg:w-20;
  @apply h-19 lg:h-full lg:overflow-y-hidden;
  @apply px-5 sm:px-6 lg:px-0 lg:py-8;
  //@apply bg-gradient-to-r from-primary-500 to-primary-700 lg:bg-gradient-to-t;
  @apply bg-custom-primary;
  //@apply text-white;
  @apply text-custom-contrastPrimary;
  @apply duration-200 ease-in-out;
  transition-property: width, background-image;

  &.navbar-expanded,
  &.navbar-expanding {
    @apply lg:w-64;
    @apply duration-200 ease-in-out;
    transition-property: width, background-image;
  }
  &.menu-show {
    //@apply bg-none bg-primary-700;
    @apply bg-none bg-custom-primary;
  }
}

.navbar-right-content {
  @apply flex;
}

.navbar-title {
  @apply flex-1 flex items-center justify-center;
  @apply mx-auto space-x-2;
  @apply font-bold truncate;
  @apply lg:hidden px-4;
}

.navbar-notifications {
  @apply block lg:hidden;
}

.navbar-logo {
  @apply flex;
  @apply shrink-0 lg:p-3;

  > * {
    @apply block w-full h-full;
  }
}

.navbar-logo,
.navbar-toggle {
  @apply w-auto;
}

.navbar-logo-default {
  @apply lg:hidden;

  .navbar-expanded & {
    @apply block;
  }
}

.navbar-logo-small {
  @apply hidden lg:block;

  .navbar-expanded & {
    @apply lg:hidden;
  }
}

.navbar-toggle {
  @apply flex justify-end;
  @apply lg:hidden;
}

.navbar-menu {
  @apply fixed lg:static z-10;
  @apply right-0 top-19 bottom-0;
  @apply w-full max-w-md flex-1;
  //@apply bg-gradient-to-b from-primary-700 to-primary-500 lg:bg-none;
  @apply bg-custom-primary;
  @apply translate-x-full lg:translate-x-0;
  @apply flex flex-col overflow-y-auto;
  @apply py-6 lg:pb-0;
  @apply transition-transform duration-300 ease-in-out;
  @apply z-40;

  .menu-show & {
    @apply translate-x-0;
  }
}

.navbar-main-menu {
  @apply border-b border-white;
  @apply pb-4 mb-4;
}

.navbar-footer-menu {
  @apply flex-1;
}

.navbar-expander {
  @apply hidden lg:flex;
  @apply items-center justify-center space-x-2;
  @apply text-sm font-bold uppercase;
  @apply my-4 py-2;

  &-label {
    @apply hidden;

    .navbar-expanded & {
      @apply inline;
    }
  }

  &-open {
    @apply justify-start px-12;
  }
}

.navbar-footnote {
  @apply flex flex-wrap items-baseline lg:justify-center;
  @apply text-sm tracking-tight;
  @apply px-12 lg:px-3;

  .navbar-expanded &,
  .navbar-expanding & {
    @apply px-12;
  }

  &-label {
    @apply mr-1.5 lg:mr-0;
    @apply lg:text-[8px] lg:leading-normal;

    .navbar-expanded &,
    .navbar-expanding & {
      @apply lg:mr-1.5;
      @apply lg:text-sm;
    }
  }

  &-text {
    @apply whitespace-normal break-words;
    @apply lg:text-xs lg:leading-none;
    @apply lg:max-w-[35px];

    .navbar-expanded &,
    .navbar-expanding & {
      @apply lg:text-sm lg:max-w-none;
    }
  }
}
</style>

<style lang="scss" global>
.navbar-logo svg {
  @apply h-9 w-auto;
}
</style>
