var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[(_vm.displayBanner)?_c('div',{staticClass:"white-overlay"},[_c('div',{staticClass:"banner-wrapper"},[_c('div',{class:{
        banner: true,
        'v-success': _vm.type === 'success',
        'v-danger': _vm.type === 'danger',
        'v-info': _vm.type === 'info',
        'v-warning': _vm.type === 'warning'
      }},[_c('div',{staticClass:"close-wrapper"},[_c('Button',{staticClass:"banner-close",attrs:{"variant":"ghost"},on:{"click":function($event){_vm.displayBanner=false}}},[_c('Icon',{attrs:{"name":"close"}})],1)],1),_c('div',{staticClass:"banner-content"},[_c('Txt',{staticClass:"banner-title",style:({
            lineHeight: _vm.textSize || undefined,
          }),attrs:{"as":"h4","type":"none","weight":"bold","no-margin":""}},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")]),(_vm.buttonLabel)?_c('Button',{attrs:{"color":_vm.buttonColor,"small":""},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._v(_vm._s(_vm.buttonLabel))]):_vm._e(),(_vm.$slots.default)?_c('Txt',{staticClass:"banner-text",attrs:{"small":""}},[_vm._t("default")],2):_vm._e()],1)])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }