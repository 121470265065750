import Vue from 'vue';
import { DateTime } from 'luxon'
import sanitizeHtml from 'sanitize-html'
import { RiskGroup, TemplateType } from '@/common/constants'

Vue.filter('date', function (timestamp) {
    if (!timestamp) return '';

    let date = DateTime.fromFormat(timestamp, 'yyyy-MM-dd HH:mm:ss')
    if (!date.isValid) date = DateTime.fromFormat(timestamp, 'yyyy-MM-dd')

    return new Intl.DateTimeFormat('it', {
        day: 'numeric',
        month: '2-digit',
        year: 'numeric',
    })
        .format(date)
        .replace(/[ /]/g, '.')
})

Vue.filter('time', function (val) {
    const date = new Date(val)
    if (isNaN(+date)) return ''
    return new Intl.DateTimeFormat(undefined, {
        day: '2-digit',
        month: 'short',
        hour: '2-digit',
        minute: '2-digit'
    }).format(date)
})

Vue.filter('seconds', function (seconds){
    seconds = parseInt(`${seconds || 0}`)

    const hours = +(seconds / 3600).toFixed(1)
    const minutes = Math.floor(seconds / 60)

    const value = hours >= 1 ? hours : minutes || seconds
    const label = hours >= 1 ? 'ore' : minutes ? 'min' : 'sec'

    return `${value} ${label}`
})

Vue.filter('campaignDate', function (date) {
    if (!date) return ''

    const cDate = DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT)
    const cTime = DateTime.fromISO(date).toLocaleString(DateTime.TIME_24_SIMPLE)
    return cDate + ' h ' + cTime
})

Vue.filter('diffDate', function (startDateIso, endDateIso) {
    if (!startDateIso || !endDateIso) return ''
    return DateTime.fromISO(endDateIso).diff(DateTime.fromISO(startDateIso), ['days', 'hours', 'minutes']).toObject().days
})

Vue.filter('riskGroup', (rg) =>{
  let res = 'N/A'
  for(const k of Object.keys(RiskGroup)){
      if(rg==RiskGroup[k])
          res=k
  }
  return res
})

Vue.filter('templateType', (type) => {
  let res = 'N/A'
  for(const k of Object.keys(TemplateType)){
      if(type==TemplateType[k])
          res=k
  }
  return res
})

Vue.filter('uppercase', function(text){
	return text.toUpperCase();
})

Vue.filter('capitalize', function(text){
    return text && text.charAt(0).toUpperCase() + text.slice(1);
})

Vue.filter('lowercase', function(text){
	return text.toLowerCase();
})

Vue.filter('integer', function(number){
	return number ?? 0;
})

const regexpLength = [/^inherit$/, /^-?\d+(.\d+)?(?:px|em|rem|pt|%)?$/];
const regexpLengthRepeated = [/^auto$/, /^(-?\d+(.\d+)?(?:px|em|rem|pt|%)?\s*){1,4}$/];
const regexpColor = [/none|white|red|blue|black/, /^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/];

Vue.filter('sanitizeRawHtml', function (rawHTML, customOptions = {}) {
    const sanitizeOption = {
        allowedTags: ["body", "img", "head", "table", "tbody", "tr", "td", "b", "a", "span", "br", "html", "strong", "div", "p"].concat(customOptions),
        allowedAttributes: {
            "img":   ["src", "style", "id", "border", "vspace", "hspace", "alt", "title", "height"],
            "html":  ["style"],
            "body":  ["style", "topmargin", "rightmargin", "bottommargin", "leftmargin", "marginwidth", "marginheight", "width", "height"],
            "table": ["style", "width", "align", "border", "cellpadding", "cellspacing", "class", "id", "bgcolor"],
            "td":    ["style", "class", "align", "valign", "bgcolor"],
            "a":     ["name", "style", "href"],
            "head":  [],
            "tbody": [],
            "tr":    [],
            "b":     [],
            "span":  [],
            "br":    [],
            "div":   ["style"],
        },
        allowedStyles: {
            '*': {
                "overflow-x": [/^visible$/, /^hidden$/, /^scroll$/, /^auto$/, /^initial$/, /^inherit$/],
                "margin": regexpLengthRepeated,
                "margin-left": regexpLengthRepeated,
                "margin-right": regexpLengthRepeated,
                "margin-top": regexpLengthRepeated,
                "margin-bottom": regexpLengthRepeated,
                "padding": regexpLengthRepeated,
                "padding-left": regexpLengthRepeated,
                "padding-right": regexpLengthRepeated,
                "padding-top": regexpLengthRepeated,
                "padding-bottom": regexpLengthRepeated,
                "width": regexpLength,
                "height": regexpLength,
                "-webkit-font-smoothing": [/^antialiased$/],
                "text-size-adjust": regexpLength,
                "-ms-text-size-adjust": regexpLength,
                "-webkit-text-size-adjust": regexpLength,
                "line-height": regexpLength,
                "mso-table-lspace": regexpLength,
                "mso-table-rspace": regexpLength,
                "border-collapse": [/^separate$/, /^collapse$/, /^initial$/, /^inherit$/],
                "border-spacing": regexpLength,
                "border": regexpLength,
                "outline": regexpColor,
                "text-decoration": [/^none$/],
                "background-color": regexpColor,
                "display": [/^inline$/, /^block$/, /^contents$/, /^flex$/, /^grid$/, /^inline-block$/, /^inline-flex$/, /^inline-grid$/, /^inline-table$/, /^list-item$/, /^run-in$/, /^table$/, /^table-caption$/, /^table-column-group$/, /^table-header-group$/, /^table-footer-group$/, /^table-row-group$/, /^table-cell$/, /^table-column$/, /^table-row$/, /^none$/, /^initial$/, /^inherit$/],
                "color": regexpColor,
                "border-radius": regexpLengthRepeated,
                "text-align": [/^left$/, /^right$/, /^center$/],
                "max-width": regexpLength,
                "min-width": regexpLength,
                "font-size": regexpLength,
                "font-weight": regexpLength.concat([/^bold$/]),
                "font-family": [/^([a-z]+-?\s?)+$/],
                "float": [/^none$/, /^left$/, /^right$/, /^initial$/, /^inherit$/],
                "max-height": regexpLength,
                "position": [/^static$/, /^absolute$/, /^fixed$/, /^relative$/, /^sticky$/, /^initial$/, /^inherit$/],
                "top": regexpLength,
                "left": regexpLength,
                "right": regexpLength,
                "bottom": regexpLength,
                "background-image": [/url\((['"])?(.*?)\1\)/g, /^none$/, /^initial$/, /^inherit$/],
                "background-position": [/(top|bottom|left|right|center|[\d.]+(?:px|em|%)|calc\(.+\))+/g], 
                "background-repeat": [/^repeat$/, /^repeat-x$/, /^repeat-y$/, /^no-repeat$/, /^initial$/, /^inherit$/], 
                "background-size": [/(auto|cover|contain|[\d.]+(?:px|em|%)|calc\(.+\))(?:\s+(auto|cover|contain|[\d.]+(?:px|em|%)|calc\(.+\)))?/g]
            }
        },
        disallowedTagsMode: 'discard',
        selfClosing: [ 'img', 'br', 'hr' ],
        allowedSchemes: sanitizeHtml.defaults.allowedSchemes.concat([ 'data'])
    }
  
    return sanitizeHtml(rawHTML, {...sanitizeOption, ...customOptions})
  })