var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    progress: true,
    'v-indeterminate': _vm.indeterminate,
    'c-primary': _vm.color === 'primary',
    'c-accent': _vm.color === 'accent',
    'c-violet': _vm.color === 'violet',
    'c-blue': _vm.color === 'blue',
    'c-red': _vm.color === 'red',
    'c-green': _vm.color === 'green',
  }},[_c('span',{staticClass:"progress-bar",style:({ width: !_vm.indeterminate ? `${_vm.progress}%` : undefined }),attrs:{"role":"progressbar","aria-valuenow":_vm.value,"aria-valuemin":0,"aria-valuemax":_vm.max}})])
}
var staticRenderFns = []

export { render, staticRenderFns }