<template>
  <span ref="tooltipContainerEl" class="tooltip">
    <span
      ref="tooltipToggleEl"
      class="tooltip-toggle"
      @mouseenter="handleEnter"
      @mouseleave="handleLeave"
      @click="handleClick"
    >
      <slot />
    </span>

    <div
      ref="tooltipEl"
      :class="{
        'tooltip-popup': true,
        dark: true,
        open,
      }"
      class="!fixed"
      @mouseenter="handleEnter"
      @mouseleave="handleLeave"
    >
      <div ref="tooltipArrowEl" class="tooltip-arrow"></div>
      <Button v-if="showClose" class="tooltip-close" variant="ghost" color="accent" @click="open = false">
        <Icon name="close" :size="30" />
      </Button>
      <Txt v-if="title" class="tooltip-title" weight="bold">{{ title }}</Txt>
      <div v-if="$slots.content" class="tooltip-content">
        <slot name="content" />
      </div>
    </div>
  </span>
</template>

<script>
import { popperGenerator } from '@popperjs/core/lib/popper-base'
import popperOffsets from '@popperjs/core/lib/modifiers/popperOffsets';
import offset from '@popperjs/core/lib/modifiers/offset';
import arrow from '@popperjs/core/lib/modifiers/arrow';
import computeStyles from '@popperjs/core/lib/modifiers/computeStyles';
import applyStyles from '@popperjs/core/lib/modifiers/applyStyles';
import flip from '@popperjs/core/lib/modifiers/flip';
import preventOverflow from "@popperjs/core/lib/modifiers/preventOverflow";
import eventListeners from "@popperjs/core/lib/modifiers/eventListeners";

import Button from './Button.vue'
import Icon from './Icon.vue'
import Txt from './Txt.vue'


export default {
  components: { Button, Icon, Txt },
  props: {
    placement: {
      type: String, // 'auto-start' | 'auto' | 'auto-end' | 'top-start' | 'top' | 'top-end' | 'right-start' | 'right' | 'right-end' | 'bottom-end' | 'bottom' | 'bottom-start' | 'left-end' | 'left' | 'left-start'
      default: 'auto',
    },
    mode: {
      type: String, // 'hover' | 'click'
      default: 'hover',
    },
    title: {
      type: String,
      default: undefined,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      popper:undefined
    }
  },
  watch: {
    open(isOpen) {
      if (this.mode !== 'click') return
      if (isOpen) {
        document.documentElement.addEventListener('click', this.handleClickOutside.bind(this))
      } else {
        document.documentElement.removeEventListener('click', this.handleClickOutside.bind(this))
      }
    },
  },
  mounted() {
    this.$nextTick(function () {
      const createPopper = popperGenerator({
        defaultModifiers: [offset, popperOffsets, arrow, computeStyles, applyStyles, flip, preventOverflow, eventListeners],
        defaultOptions: {
          placement: this.placement,
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                padding: 8,
              },
            },
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
            {
              name: 'arrow',
              options: {
                element: this.$refs.tooltipArrowEl,
              },
            },
          ],
        },
      });
      this.popper = createPopper(this.$refs.tooltipToggleEl, this.$refs.tooltipEl)

      this.$eventBus.$on('loading', value => setTimeout(() => {
        if (this.popper && !value) this.popper.update()
      }, 50))
    })
  },
  methods: {
    handleEnter() {
      if (this.mode !== 'hover') return
      this.open = true
    },
    handleLeave() {
      if (this.mode !== 'hover') return
      this.open = false
    },
    handleClick() {
      if (this.mode !== 'click') return

      this.open = !this.open
    },
    handleClickOutside(e) {
      if (!this.$refs.tooltipContainerEl) return
      if (!this.$refs.tooltipContainerEl.contains(e.target)) {
        this.open = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.tooltip {
  @apply relative;
  @apply text-sm;
  @apply flex;
  @apply items-center;
  @apply justify-center;
}

.tooltip-toggle {
  @apply inline-flex;
}

.tooltip-popup {
  @apply absolute;
  @apply w-screen max-w-xs z-20;
  @apply px-4 py-7;
  @apply bg-primary-500 text-white;
  @apply opacity-0 pointer-events-none;
  @apply transition-opacity duration-300 ease-in-out;

  &.open {
    @apply opacity-100 pointer-events-auto;
  }
}

.tooltip-title {
  @apply text-base leading-tight;
}

.tooltip-title + .tooltip-content {
  @apply mt-3;
}

.tooltip-close {
  @apply absolute right-2 top-2;
}

.tooltip-arrow {
  @apply absolute w-3 h-3 overflow-hidden;

  &::before {
    content: '';
    @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-45;
    @apply w-3 h-3;
    @apply bg-primary-500 border border-primary-500;
  }

  .tooltip-popup[data-popper-placement^='bottom'] & {
    @apply -top-3 w-6;
    &::before {
      @apply top-full;
    }
  }

  .tooltip-popup[data-popper-placement^='top'] & {
    @apply -bottom-3 w-6;
    &::before {
      @apply top-0;
    }
  }

  .tooltip-popup[data-popper-placement^='left'] & {
    @apply -right-3 h-6;
    &::before {
      @apply left-0;
    }
  }

  .tooltip-popup[data-popper-placement^='right'] & {
    @apply -left-3 h-6;
    &::before {
      @apply left-full;
    }
  }
}
</style>
