var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.inferredTag,{tag:"component",class:{
    h1: _vm.inferredType === 'h1',
    h2: _vm.inferredType === 'h2',
    h3: _vm.inferredType === 'h3',
    h4: _vm.inferredType === 'h4',
    h5: _vm.inferredType === 'h5',
    h6: _vm.inferredType === 'h6',
    subtitle: _vm.inferredType === 'subtitle',
    footnote: _vm.inferredType === 'footnote',
    'font-normal': _vm.weight === 'normal',
    'font-medium': _vm.weight === 'medium',
    'font-bold': _vm.weight === 'bold',
    'text-xs': _vm.extraSmall,
    'text-sm': _vm.small,
    'text-md': _vm.large,
    'text-left': _vm.left,
    'text-center': _vm.center,
    'text-right': _vm.right,
    'v-no-margin': _vm.noMargin,
    uppercase: _vm.uppercase,
  },style:({
    fontSize: typeof _vm.size === 'number' ? `${_vm.size}px` : _vm.size,
  }),on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }