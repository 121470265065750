<template>
  <Card
      :class="{
        'placeholder-empty-chart-card': true,
        'l-horizontal': layout === 'horizontal',
        'l-vertical': layout === 'vertical',
        'l-empty': layout === 'empty',
      }"
      :customClass="customClass"
      :customStyle="customStyle"
  >
    <div class="placeholder-empty-chart-card-content">
      <Txt weight="bold" class="placeholder-empty-chart-card-title" no-margin>
        <span v-if="title">{{ title }}</span>
        <slot v-if="$slots.title" name="title" />
      </Txt>
      <div class="placeholder-empty-chart"
           :style="{ minHeight: cardHeight ? `${cardHeight}px` : undefined }">
        <div class="placeholder-icon-chart" >
          <Icon v-if=" iconChart == 'barchart' " :size="size" name="barchart"/>
          <Icon v-if=" iconChart == 'piechart' " :size="size" name="piechart" />
        </div>
        <div class="placeholder-message">
          <Txt bold>{{ message }}</Txt>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/atoms/Card.vue'
import Txt from '@/components/atoms/Txt.vue'

export default {
  components: { Card, Txt },
  props: {
    title: {
      type: String,
      default: undefined,
    },
    message:{
      type: String,
      default: undefined
    },
    cardHeight: {
      type: Number,
      default: undefined,
    },
    customClass: {
      type: String,
      default: 'bg-white',
    },
    customStyle: {
      type: [String, Object],
      default: '',
    },
    layout: {
      type: String, // 'horizontal' | 'vertical' | 'empty'
      default: 'horizontal',
    },
    iconChart:{
      type: String,
      default: 'bar-chart',
      required: true
    },
    size: {
      type: [String, Number],
      default: undefined,
    },
  }
}
</script>

<style scoped>
.placeholder-empty-chart-card-content {
  @apply  w-full h-full;

  .l-horizontal & {
    @apply flex-row flex-wrap items-center;
  }

  .l-vertical & {
    @apply flex-col;
  }

  .l-empty & {
    @apply flex-col items-center text-center;
    @apply m-auto p-10;
    @apply max-w-md;
  }
}


.placeholder-empty-chart-card-title {
  @apply flex items-start;
  @apply order-1;

  .l-horizontal &,
  .l-vertical & {
    @apply order-1;
  }
  .l-horizontal & {
    @apply basis-full;
  }
  .l-empty & {
    @apply order-2;
  }

}

.placeholder-empty-chart {
  @apply  flex flex-col items-center justify-center p-5 bg-gray-100;

  .l-horizontal & {
    @apply w-1/2;
  }
  .l-vertical & {
    @apply flex-grow min-h-[50%];
  }
  .l-horizontal.l-chart-start &,
  .l-vertical.l-chart-start & {
    @apply order-2;
  }
  .l-horizontal.l-chart-end &,
  .l-vertical.l-chart-end & {
    @apply order-3;
  }
  .l-vertical & {
    @apply mt-4;
  }
  .l-empty & {
    @apply order-1;
    @apply mb-4;
    @apply w-full max-w-xs mx-auto;
  }

}
.placeholder-message{
  @apply text-center mt-2 text-sm;
}
</style>