var render = function render(){var _vm=this,_c=_vm._self._c;return _c('InputWrapper',{ref:"toggleEl",class:{
    'input-tags': true,
    'v-auto-width': _vm.autoWidth,
  },attrs:{"errored":_vm.errored,"error-message":_vm.errorMessage,"large":_vm.large,"label":_vm.label,"disabled":_vm.disabled,"unstyled-prefix":_vm.unstyledPrefix,"unstyled-suffix":_vm.unstyledSuffix},scopedSlots:_vm._u([(_vm.$slots.prefix)?{key:"prefix",fn:function(){return [_vm._t("prefix")]},proxy:true}:null,(_vm.$slots.suffix)?{key:"suffix",fn:function(){return [_vm._t("suffix")]},proxy:true}:null],null,true)},[_c('div',{ref:"contentEl",staticClass:"input-tags-content",attrs:{"aria-expanded":String(_vm.open)},on:{"click":function($event){_vm.open=true}}},[_c('ul',{staticClass:"input-tags-list"},_vm._l((_vm.value),function(tag){return _c('li',{key:tag.value,staticClass:"input-tags-item"},[_c('Tag',{attrs:{"show-remove":"","disabled":_vm.disabled},on:{"remove":function($event){return _vm.toggleTag(tag)}}},[_vm._v(_vm._s(tag.label))])],1)}),0),_c('input',{ref:"inputEl",attrs:{"id":_vm.id,"name":_vm.name,"placeholder":_vm.placeholder,"required":_vm.required,"disabled":_vm.disabled},domProps:{"value":_vm.text},on:{"input":_vm.filterOptions,"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.text? _vm.toggleTag(_vm.text) : _vm.open=true},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.open=false}],"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;return _vm.deleteTag.apply(null, arguments)},"!keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;return _vm.$refs.tagOption0[0].focus()},"!focusout":function($event){return _vm.onFocusOut($event)}}})]),_c('div',{ref:"menuEl",class:{
      'input-tags-options': true,
      open: _vm.open,
    }},_vm._l((_vm.filteredOptions),function(option,i){return _c('div',{key:option.value,ref:'tagOption'+i,refInFor:true,class:{
        'input-tags-option': true,
        selected: _vm.value.indexOf(option) >= 0,
      },attrs:{"role":"button","tabindex":_vm.open?0:-1},on:{"click":function($event){return _vm.toggleTag(option)},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.toggleTag(option)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.target.nextElementSibling?.focus()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.target.previousElementSibling?.focus()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.open=false}],"!focusout":function($event){return _vm.onFocusOut($event)}}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }