<template>
  <div
    ref="datepickerEl"
    :class="{
      datepicker: true,
      disabled,
    }"
  >
    <InputWrapper
      :errored="errored"
      :error-message="errorMessage"
      :label="label"
      :disabled="disabled"
      :active="open"
      unstyled-prefix
      unstyled-suffix
      tabindex="0"
      @click="toggleOpen"
      @keydown.enter="toggleOpen"
    >
      <template v-if="$slots.prefix" #prefix>
        <slot name="prefix" tabindex="-1"/>
      </template>
      <template #suffix>
        <Icon name="calendar" class="text-violet-700" />
      </template>
      <input
        :id="id"
        :value="value"
        :name="name"
        :disabled="disabled"
        type="date"
        @input="updateDate"
        @click="stopDefaultCalendar"
      />
    </InputWrapper>

    <div
      ref="pickerMenuEl"
      :class="{
        'datepicker-menu': true,
        open,
      }"
    >
      <Calendar :date="value" :events="[]" compact background-color="light" @date-click="selectDate" />
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'

import InputWrapper from './InputWrapper.vue'
import Icon from './Icon.vue'
import Calendar from './Calendar.vue'

export default {
  components: { InputWrapper, Icon, Calendar },
  props: {
    value: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    placement: {
      type: String,
      default: 'bottom-end',
    },
    errorMessage: {
      type: String,
      default: undefined,
    },
    errored: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  data() {
    return {
      open: false,
      popper: undefined,
    }
  },
  watch: {
    open(isOpen) {
      if (isOpen) {
        document.documentElement.addEventListener('click', this.handleClickOutside.bind(this))
      } else {
        document.documentElement.removeEventListener('click', this.handleClickOutside.bind(this))
      }
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.popper = createPopper(this.$refs.datepickerEl, this.$refs.pickerMenuEl, {
        placement: this.placement,
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              padding: 8,
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ],
      })
    })
  },
  methods: {
    toggleOpen() {
      this.open = !this.open
    },
    handleClickOutside(e) {
      if (!this.$refs.datepickerEl) return
      if (!this.$refs.datepickerEl.contains(e.target)) {
        this.open = false
      }
    },
    selectDate(date) {
      this.$emit('input', date.dateStr)
    },
    updateDate(e) {
      const value = e.target.value
      this.$emit('input', value)
    },
    stopDefaultCalendar(e) {
      e.preventDefault()
    },
  },
}
</script>

<style lang="scss" scoped>
.datepicker {
  @apply relative;

  input {
    @apply appearance-none;
    @apply h-10 w-full px-2 rounded-none;
    @apply pt-1; // font adjustment
    @apply text-sm text-primary-700 placeholder:text-primary-300;
    outline: none;
    box-shadow: none;
    @apply disabled:bg-primary-100 disabled:cursor-not-allowed;

    &::-webkit-calendar-picker-indicator {
      @apply hidden;
    }
    &::-webkit-input-placeholder {
      @apply hidden;
    }
  }
}

.datepicker-menu {
  @apply absolute;
  @apply w-full max-w-xs min-w-[280px];
  @apply overflow-y-auto z-10;
  @apply bg-extra-light;
  @apply shadow-md shadow-violet-500/20;
  @apply opacity-0 scale-y-95 origin-top pointer-events-none;

  &.open {
    @apply opacity-100 scale-y-100 pointer-events-auto;
  }

  ::v-deep .fc .fc-header-toolbar .fc-toolbar-title {
    width: 9rem !important;
  }

}

</style>
