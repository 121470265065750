var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'page-wrapper': true,
    fit: _vm.fit,
  }},[(_vm.$slots.navbar)?_c('div',{staticClass:"page-wrapper-navbar"},[_vm._t("navbar")],2):_vm._e(),_c('div',{class:{
      'page-wrapper-main': true,
      '!min-h-screen': true,
      'bg-white': _vm.background === 'white',
      'bg-extra-light': _vm.background === 'light',
    }},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }