var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",class:{
    'table-cell': true,
    'v-separator': _vm.separator,
    'v-sortable': _vm.sortKey,
    'v-sort-asc': _vm.sortDirection === 'asc',
    'v-sort-desc': _vm.sortDirection === 'desc',
    'a-center': _vm.center,
    'a-right': _vm.right,
  },attrs:{"width":_vm.width},on:{"click":_vm.cellClick}},[_c('span',{staticClass:"table-cell-content"},[_vm._t("default"),(_vm.sortKey)?_c('span',{staticClass:"table-cell-sort-icon"},[_c('Icon',{attrs:{"name":"chevronDownLarge"}})],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }