var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"containerEl",class:{
    notifications: true,
    'v-unread': _vm.hasUnread && !_vm.hasUnreadError,
    'v-unread-error': _vm.hasUnreadError,
  },attrs:{"title":_vm.$t('Wcag.newNotificationsStatus', {count: _vm.unreadNotifications.length }),"role":"status","aria-live":"polite","aria-atomic":"true"}},[_c('button',{ref:"menuToggleEl",staticClass:"notifications-button",attrs:{"aria-label":_vm.$t('Wcag.NotificationsButton'),"aria-expanded":String(_vm.open),"aria-controls":"notifications-menu-div"},on:{"click":_vm.toggleMenu}},[_c('Icon',{attrs:{"name":"notifications"}})],1),_c('div',{ref:"menuEl",class:{ 'notifications-menu': true, open: _vm.open },attrs:{"id":"notifications-menu-div"}},[_c('div',{ref:"menuArrowEl",staticClass:"notifications-menu-arrow"}),(_vm.open && _vm.mutatedNotifications.length)?_c('div',{class:['notifications-button-readall', _vm.open ? 'is-open' : 'is-closed']},[_c('Checkbox',{attrs:{"value":!_vm.hasUnread,"disabled":!_vm.hasUnread},on:{"input":function($event){return _vm.$eventBus.$emit('notification-set-all-read')}}},[_vm._v(" "+_vm._s(_vm.$t('General.ReadAll'))+" ")])],1):_vm._e(),(_vm.open)?_c('ul',{class:['notifications-list', _vm.open ? 'is-open' : 'is-closed'],attrs:{"role":"list"}},_vm._l((_vm.mutatedNotifications),function(notification){return _c('li',{key:notification.id,class:{
          'notifications-list-item': true,
          'v-read': !!notification.marked
        },attrs:{"role":"listitem"},on:{"click":function($event){return _vm.$eventBus.$emit('notification-click', notification.id)}}},[_c(notification.link ? 'Link' : 'span',{tag:"component",attrs:{"variant":notification.link ? 'unstyled' : undefined}},[_c('Txt',{staticClass:"notifications-list-item-title",attrs:{"weight":"bold","small":""}},[_vm._v(_vm._s(notification.title))]),_c('Txt',{staticClass:"notifications-list-item-description",attrs:{"extra-small":""}},[_vm._v(_vm._s(notification.clampedText || notification.description)+" "),(notification.clampedText)?_c('Button',{ref:'readMore'+notification.id,refInFor:true,attrs:{"variant":"ghost"},on:{"click":function($event){return _vm.openReadMore(notification)}}},[_c('Txt',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.$t('General.ReadMore')))])],1):_vm._e()],1),_c('Txt',{staticClass:"notifications-list-item-time",attrs:{"extra-small":""}},[_vm._v(_vm._s(_vm._f("time")(notification.creation_date)))])],1)],1)}),0):_vm._e(),(_vm.open && !_vm.mutatedNotifications.length)?_c('div',{staticClass:"ml-auto my-4 mr-4 text-sm"},[_c('Txt',[_vm._v(_vm._s(_vm.$t('Profile.EmptyNotifications')))])],1):_vm._e()]),(_vm.readMoreNotification)?_c('Modal',{ref:"readMoreModal",attrs:{"open":_vm.openReadMoreModalStatus,"title":_vm.readMoreNotification.title,"trigger":_vm.readMoreModalRef,"footer-alignment":"center","small":"","with-close-button":""},on:{"update:open":function($event){_vm.openReadMoreModalStatus=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('Button',{attrs:{"variant":"outline"},on:{"click":function($event){_vm.openReadMoreModalStatus = false}}},[_vm._v(_vm._s(_vm.$t('General.Close')))])]},proxy:true}],null,false,2369719476)},[_c('Txt',{staticClass:"mb-2",attrs:{"small":"","weight":"medium"}},[_vm._v(_vm._s(_vm._f("time")(_vm.readMoreNotification.creation_date)))]),_c('Txt',{},[_vm._v(_vm._s(_vm.readMoreNotification.description)+" ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }