<template>
  <div
    ref="containerEl"
    :class="{
      notifications: true,
      'v-unread': hasUnread && !hasUnreadError,
      'v-unread-error': hasUnreadError,
    }"
    :title="$t('Wcag.newNotificationsStatus', {count: unreadNotifications.length })" role="status" aria-live="polite" aria-atomic="true"
  >
  <button ref="menuToggleEl" :aria-label="$t('Wcag.NotificationsButton')" :aria-expanded="String(open)" aria-controls="notifications-menu-div" class="notifications-button" @click="toggleMenu">
      <Icon name="notifications" />
    </button>
    <div id="notifications-menu-div" ref="menuEl" :class="{ 'notifications-menu': true, open }">
      <div ref="menuArrowEl" class="notifications-menu-arrow"></div>
      <div v-if="open && mutatedNotifications.length" :class="['notifications-button-readall', open ? 'is-open' : 'is-closed']" >
        <Checkbox
          :value="!hasUnread"
          :disabled="!hasUnread" @input="$eventBus.$emit('notification-set-all-read')">
          {{ $t('General.ReadAll') }}
        </Checkbox>
      </div>
      <ul v-if="open" :class="['notifications-list', open ? 'is-open' : 'is-closed']" role="list">
        <li
          v-for="notification in mutatedNotifications"
          :key="notification.id"
          :class="{
            'notifications-list-item': true,
            'v-read': !!notification.marked
          }"
          role="listitem"
          @click="$eventBus.$emit('notification-click', notification.id)"
        >
          <component :is="notification.link ? 'Link' : 'span'" :variant="notification.link ? 'unstyled' : undefined">
            <Txt class="notifications-list-item-title" weight="bold" small>{{ notification.title }}</Txt>
            <Txt class="notifications-list-item-description" extra-small>{{ notification.clampedText || notification.description }} 
              <Button v-if="notification.clampedText" :ref="'readMore'+notification.id" variant="ghost" @click="openReadMore(notification)"><Txt class="text-xs">{{ $t('General.ReadMore') }}</Txt></Button>
            </Txt>
            <Txt class="notifications-list-item-time" extra-small>{{ notification.creation_date | time }}</Txt>
          </component>
        </li>
      </ul>
      <div v-if="open && !mutatedNotifications.length" class="ml-auto my-4 mr-4 text-sm">
        <Txt>{{ $t('Profile.EmptyNotifications') }}</Txt>
      </div>
    </div>
    <!-- Read More modal -->
    <Modal v-if="readMoreNotification" ref="readMoreModal" :open.sync="openReadMoreModalStatus" :title="readMoreNotification.title" :trigger="readMoreModalRef" footer-alignment="center" small with-close-button>
      <Txt class="mb-2" small weight="medium">{{ readMoreNotification.creation_date | time }}</Txt>
      <Txt class="">{{ readMoreNotification.description }} </Txt>
      <template #footer>
          <Button variant="outline" @click="openReadMoreModalStatus = false">{{ $t('General.Close') }}</Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';
import { EventType } from '@/common/constants.js'

export default {
  props: {
    notifications: {
      type: Array, // Array<{ id: string, title: string, description: string, marked: 0/1, link?: string, timestamp: number }>
      default: () => []
    }
  },
  emits: ['notification-click', 'notification-set-all-read'],
  data() {
    return {
      open: false,
      openReadMoreModalStatus: false,
      readMoreModalRef: null,
      readMoreNotification: null,
    }
  },
  computed: {
    hasUnread() {
      return this.notifications.some((notif) => !notif.marked);
    },
    unreadNotifications() {
      return this.notifications.filter((notif) => !notif.marked);
    },
    hasUnreadError() {
      return this.notifications.some((notif) => !notif.marked &&notif.event_type == EventType.InternalEvent && notif.is_error);
    },
    mutatedNotifications(){
      const maxCharacter = 60;
      return this.notifications.map(n => {
        n.clampedText = n.description.length >= maxCharacter ? n.description.substring(0, maxCharacter) + "... ": ''
        return n
      });
    }
  },
  watch: {
    open(isOpen) {
      if (isOpen) {
        document.documentElement.addEventListener('click', this.handleClickOutside.bind(this), { capture: true });
      } else {
        document.documentElement.removeEventListener('click', this.handleClickOutside.bind(this));
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.popper = createPopper(this.$refs.menuToggleEl, this.$refs.menuEl, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              padding: 8
            }
          },
          {
            name: 'offset',
            options: {
              offset: [8, 8]
            }
          },
          {
            name: 'arrow',
            options: {
              element: this.$refs.menuArrowEl
            }
          }
        ]
      });
    });
  },
  methods: {
    toggleMenu() {
      this.open = !this.open;
    },
    handleClickOutside(e) {
      if (this.readMoreModal?.open || !this.$refs.containerEl) return;
      if (!this.$refs.containerEl.contains(e.target)) {
        this.open = false;
      }
    },
    openReadMore(notification){
      this.readMoreModalRef = this.$refs['readMore'+notification.id]
      this.readMoreNotification = notification
      this.openReadMoreModalStatus = true
    },
  }
}
</script>

<style lang="scss" scoped>
.notifications {
  @apply relative;
}

.notifications-button {
  @apply relative;
  @apply inline-flex items-center justify-center;
  //@apply w-[42px] h-[42px] text-2xl text-white lg:text-violet-700;
  @apply w-[42px] h-[42px] text-2xl text-custom-contrastPrimary lg:text-violet-700;
  @apply bg-transparent lg:bg-white;
  @apply border border-transparent lg:border-primary-100;

  .v-unread &::after {
    content: '';
    @apply block absolute right-2.5 top-2.5;
    @apply w-2 h-2 rounded-full;
    @apply bg-green-500 border border-white;
  }
  .v-unread-error &::after {
  content: '';
    @apply block absolute right-2.5 top-2.5;
    @apply w-2 h-2 rounded-full;
    @apply bg-red-600 border border-white;
  }
}
.notifications-checkbox-readall {
  @apply relative ml-auto my-4 mr-4
}
.notifications-button-readall {
  @apply relative ml-auto my-4 mr-4;
  @apply inline-flex items-center justify-center;
  @apply text-soft-blue-700 disabled:text-primary-300 disabled:cursor-not-allowed;
  @apply text-xs;
}
.notifications-button-readall.is-closed {
  @apply hidden;
}
.notifications-button-readall.is-open {
  @apply block;
}

.notifications-menu {
  @apply absolute flex flex-col;
  @apply w-screen max-w-xs z-20;
  @apply max-h-80 pr-0.5;
  @apply bg-extra-light;
  @apply shadow-md shadow-violet-500/20;
  @apply opacity-0 pointer-events-none;
  @apply transition-opacity duration-300 ease-in-out;

  &.open {
    @apply opacity-100 pointer-events-auto;
  }
}

.notifications-menu-arrow {
  @apply absolute w-3 h-3;

  &::before {
    content: '';
    @apply absolute -translate-y-1/2 rotate-45;
    @apply w-3 h-3;
    @apply bg-extra-light border border-extra-light;
  }

  .tooltip-popup[data-popper-placement^='bottom'] & {
    @apply -top-3 w-6;
    &::before {
      @apply top-full;
    }
  }

  .tooltip-popup[data-popper-placement^='top'] & {
    @apply -bottom-3 w-6;
    &::before {
      @apply top-0;
    }
  }

  .tooltip-popup[data-popper-placement^='left'] & {
    @apply -right-3 h-6;
    &::before {
      @apply left-0;
    }
  }

  .tooltip-popup[data-popper-placement^='right'] & {
    @apply -left-3 h-6;
    &::before {
      @apply left-full;
    }
  }
}

.notifications-list {
  @apply flex-grow flex flex-col overflow-y-auto;
  @apply px-1 pb-2;

  &::-webkit-scrollbar {
    @apply appearance-none;
    @apply w-1.5;
  }
  &::-webkit-scrollbar-track {
    @apply bg-transparent;
    @apply rounded-full;
  }
  &::-webkit-scrollbar-thumb {
    @apply rounded-full;
    @apply bg-primary-300/50;
  }
}

.notifications-list.is-closed {
  @apply hidden;
}

.notifications-list.is-open {
  @apply block;
}
.notifications-list-item {
  @apply relative flex flex-col;
  @apply px-6 py-2;
  @apply cursor-pointer;
  @apply transition-colors duration-100 ease-in-out;
  @apply hover:bg-soft-blue-500;

  &:not(.v-read) {
    &::after {
      content: '';
      @apply block absolute left-2 top-3;
      @apply w-2 h-2 rounded-full;
      @apply bg-soft-blue-700;
    }
  }
}

.notifications-list-item-title {
  @apply mb-1;
  @apply transition-colors duration-100 ease-in-out;

  .v-read & {
    @apply text-primary-300/75;
  }

  .v-read:hover & {
    @apply text-primary-500;
  }
}

.notifications-list-item-description {
  @apply text-primary-700/75;
  @apply line-clamp-2 overflow-hidden text-ellipsis;
  @apply transition-colors duration-100 ease-in-out;

  .v-read & {
    @apply text-primary-300/75;
  }

  .v-read:hover & {
    @apply text-primary-500/75;
  }
}

.notifications-list-item-time {
  @apply mt-2;
  @apply text-primary-700/60;
  @apply transition-colors duration-100 ease-in-out;

  .v-read & {
    @apply text-primary-300/50;
  }

  .v-read:hover & {
    @apply text-primary-500/75;
  }
}
</style>
