<template>
  <ChartJSRadar
    ref="chart"
    :class="{
      'pointer-events-none': noInteractions,
    }"
    :title="title"
    :chart-data="chartData"
    :dataset-id-key="datasetIdKey"
    :chart-options="config"
    :styles="{ width: '100%', height: '100%' }"
    :width="0"
    :height="0"
    tabindex="0"
    :aria-label="title"
    role="graphics-object"
    :aria-roledescription="$t('Wcag.RadarChart')"
  />
</template>

<script>
import { Radar as ChartJSRadar } from 'vue-chartjs/legacy'
import { mergeObjects } from '@/utils/object'
import '@/utils/charts'

export default {
  components: { ChartJSRadar },
  props: {
    labels: {
      type: Array,
      required: true,
    },
    datasets: {
      type: Array,
      required: true,
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    chartOptions: {
      type: Object,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
    showLabels: {
      type: Boolean,
      default: false,
    },
    showPointLabels: {
      type: Boolean,
      default: false,
    },
    noInteractions: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Number,
      default: undefined,
    },
    ticksColor: {
      type: String,
      default: "black"
    }
  },
  data() {
    return {
      renderComponent: false,
    }
  },
  computed: {
    chartData() {
      return {
        labels: this.labels,
        datasets: this.datasets.map((dataset) =>
          mergeObjects({ pointRadius: 0, pointHoverRadius: 0, borderWidth: 2 }, dataset)
        ),
      }
    },
    config() {
      const defaultConfig = {
        responsive: true,
        aspectRatio: this.aspectRatio,
        maintainAspectRatio: !!this.aspectRatio,
        plugins: {
          title: {
            display: !!this.title,
            text: this.title,
            padding: {
              bottom: 20
            }
          },
          legend: {
            display: this.showLegend,
          },
        },
        scales: {
          r: {
            pointLabels: {
              padding: 5,
              display: this.showPointLabels,
              color: this.ticksColor
            },
            ticks: {
              padding: 5,
              display: this.showLabels,
              backdropColor: 'transparent',
              z: 1,
            },
          },
        },
      }
      return mergeObjects(defaultConfig, this.chartOptions || {})
    },
  },
  watch: {
    $props: {
      handler() {
        // force chart refresh
        this.$refs.chart.renderChart(this.chartData, this.config)
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped></style>
