var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pie-chart-wrapper"},[_c('ChartJSPie',{ref:"chart",class:{
      'pointer-events-none': _vm.noInteractions,
    },attrs:{"title":_vm.title,"chart-data":_vm.chartData,"dataset-id-key":_vm.datasetIdKey,"chart-options":_vm.config,"styles":{ width: '100%', height: '100%' },"width":0,"height":0,"tabindex":"0","aria-label":_vm.title,"role":"graphics-object","aria-roledescription":_vm.$t('Wcag.PieChart')}}),(_vm.$slots.default)?_c('div',{staticClass:"pie-chart-content",style:({
      left: _vm.chartBounds ? `${_vm.chartBounds.left}px` : undefined,
      top: _vm.chartBounds ? `${_vm.chartBounds.top}px` : undefined,
      right: _vm.chartBounds ? `${_vm.chartBounds.right}px` : undefined,
      bottom: _vm.chartBounds ? `${_vm.chartBounds.bottom}px` : undefined,
      width: _vm.chartBounds ? `${_vm.chartBounds.width}px` : undefined,
      height: _vm.chartBounds ? `${_vm.chartBounds.height}px` : undefined,
    })},[_vm._t("default")],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }