<template>
  <InputWrapper
    ref="toggleEl"
    :class="{
      'input-tags': true,
      'v-auto-width': autoWidth,
    }"
    :errored="errored"
    :error-message="errorMessage"
    :large="large"
    :label="label"
    :disabled="disabled"
    :unstyled-prefix="unstyledPrefix"
    :unstyled-suffix="unstyledSuffix"
  >
    <template v-if="$slots.prefix" #prefix>
      <slot name="prefix" />
    </template>
    <template v-if="$slots.suffix" #suffix>
      <slot name="suffix" />
    </template>
    <div ref="contentEl" class="input-tags-content" :aria-expanded="String(open)" @click="open=true">
      <ul class="input-tags-list">
        <li v-for="tag in value" :key="tag.value" class="input-tags-item">
          <Tag show-remove :disabled="disabled" @remove="toggleTag(tag)">{{ tag.label }}</Tag>
        </li>
      </ul>
      <input
        :id="id"
        ref="inputEl"
        :value="text"
        :name="name"
        :placeholder="placeholder"
        :required="required"
        :disabled="disabled"
        @input="filterOptions"
        @keyup.enter="text? toggleTag(text) : open=true"
        @keydown.delete="deleteTag"
        @keyup.esc="open=false"
        @keyup.down.capture="$refs.tagOption0[0].focus()"
        @focusout.capture='onFocusOut($event)'
      />
    </div>
    <div
      ref="menuEl"
      :class="{
        'input-tags-options': true,
        open,
      }"
    >
      <div
        v-for="(option, i) in filteredOptions"
        :ref="'tagOption'+i"
        :key="option.value"
        :class="{
          'input-tags-option': true,
          selected: value.indexOf(option) >= 0,
        }"
        role="button"
        :tabindex="open?0:-1"
        @click="toggleTag(option)"
        @keyup.enter="toggleTag(option)"
        @keyup.down="$event.target.nextElementSibling?.focus()"
        @keyup.up="$event.target.previousElementSibling?.focus()"
        @keyup.esc="open=false"
        @focusout.capture='onFocusOut($event)'
      >
        {{ option.label }}
      </div>
    </div>
  </InputWrapper>
</template>
<script>
import { createPopper } from '@popperjs/core'
import InputWrapper from './InputWrapper.vue'
import Tag from './Tag.vue'
export default {
  components: { InputWrapper, Tag },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: undefined,
    },
    max: {
      type: Number,
      default: undefined,
    },
    allowOthers: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    unstyledPrefix: {
      type: Boolean,
      default: false,
    },
    unstyledSuffix: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: undefined,
    },
    autoWidth: {
      type: Boolean,
      default: false,
    },
    errored: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  emits: ['remove', 'input'],
  data() {
    return {
      open: false,
      text: '',
    }
  },
  computed: {
    filteredOptions() {
      return (this.text)? this.options.filter((option) => option.label && (option.label+'').toLowerCase().includes(this.text.toLowerCase())) : this.options;
    },
  },
  watch: {
    open(isOpen) {
      if (isOpen) {
        document.documentElement.addEventListener('click', this.handleClickOutside.bind(this))
      } else {
        document.documentElement.removeEventListener('click', this.handleClickOutside.bind(this))
      }
    },
    text() {
      const width = this.$refs.inputEl.clientWidth
      const scrollWidth = this.$refs.inputEl.scrollWidth
      this.$refs.inputEl.style.width = scrollWidth > width ? `${scrollWidth}px` : ''
      this.$refs.contentEl.scrollLeft = this.$refs.contentEl.scrollWidth
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.popper = createPopper(this.$refs.toggleEl.$el, this.$refs.menuEl, {
        placement: 'bottom-start',
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              padding: 8,
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ],
      })
    })
  },
  methods: {
    filterOptions(e) {
      this.text = e.target.value
    },
    toggleTag(tag) {
      if (!tag) {
        return
      }
        
      const tags = [...this.value]
      const index = tags.indexOf(tag)
      if (index >= 0) {
        tags.splice(index, 1)
        this.$emit('remove', tags)
      } else if (this.max == null || tags.length + 1 <= this.max) {
        tags.push(tag)
        this.text = ''
        this.scrollToLast()
        this.$emit('input', tags)
      }
      this.$refs.inputEl.focus()
    },
    scrollToLast() {
      setTimeout(() => {
        this.$refs.contentEl.scrollLeft = this.$refs.contentEl.scrollWidth
      }, 50)
    },
    deleteTag() {
      if (this.value.length && this.text === '') this.toggleTag(this.value[this.value.length - 1])
    },
    handleClickOutside(e) {
      if (!this.$refs.toggleEl || !this.$refs.toggleEl.$el) return
      if (!this.$refs.toggleEl.$el.contains(e.target)) {
        this.open = false
      }
    },
    onFocusOut(event) {
      if (!this.$refs.toggleEl.$el.contains(event.relatedTarget) || !this.$refs.menuEl.contains(event.relatedTarget)) this.open = false
    },
  },
}
</script>
<style lang="scss" scoped>
.input-tags {
  input {
    @apply flex-grow shrink-0 w-12;
    @apply appearance-none;
    @apply px-2 rounded-none;
    @apply text-sm text-primary-700 placeholder:text-primary-300;
    outline: none;
    box-shadow: none;
    @apply disabled:bg-primary-100 disabled:cursor-not-allowed;
  }
}
.input-tags-content {
  @apply flex-grow flex items-center;
  @apply overflow-auto;
  .disabled & {
    @apply bg-primary-100;
  }
  &::-webkit-scrollbar {
    @apply hidden;
  }
}
.input-tags-list {
  @apply shrink-0;
  @apply flex items-center space-x-2;
  &:not(:empty) {
    @apply pl-2;
  }
  .tag.disabled {
    @apply bg-white/50;
  }
}
.input-tags-item {
  @apply inline-flex;
}
.input-tags-options {
  @apply absolute;
  @apply max-h-56 overflow-y-auto z-10;
  @apply bg-extra-light;
  @apply shadow-md shadow-violet-500/20;
  @apply opacity-0 scale-y-95 origin-top pointer-events-none;
  &.open {
    @apply opacity-100 scale-y-100 pointer-events-auto;
    
    &::-webkit-scrollbar {
      @apply appearance-none;
      width: 0.375rem !important;
      @apply invisible;
    }
    &::-webkit-scrollbar-track {
      @apply bg-transparent;
      @apply rounded-sm;
      @apply my-3;
    }
    &::-webkit-scrollbar-thumb {
      @apply rounded-sm;
      @apply bg-primary-300/50;
    }
    & {
      scrollbar-width: thin;
      scrollbar-color: rgb(131 135 154 / 0.5)  transparent;
      @apply scroll-my-3;
    }
  }
  
}
.input-tags-option {
  @apply flex items-center w-full;
  @apply text-left text-sm;
  @apply px-3.5 py-1.5;
  @apply cursor-default;
  @apply hover:bg-soft-blue-300;
  &.selected {
    @apply text-violet-700 font-bold;
  }
  .input-tags:not(.v-auto-width) & {
    @apply min-w-[19rem];
  }
}
</style>