<template>
  <div>
    <div class='sm:block overflow-x-visible'>
      <div class='border-b border-gray-200 scroll-multiple-tabs'>
        <nav class= '-mb-px flex scroll-multiple-tabs' aria-label='Tabs' role="tablist">
          <a
            v-for='tab in tabNav'
            :key='tab.name'
            :href='tab.href'
            :class="[
              'mr-6',
              'display' in tab && !tab.display? 'hidden':'',
              tab.isActive
                ? 'border-gray-500 text-gray-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
              'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer',
              tab.disabled
                ? 'pointer-events-none cursor-default text-gray-400' : ''
            ]"
            tabindex="0"
            :aria-label="tab.name"
            role="tab"
            :aria-selected="tab.isActive"
            @click='selectTab(tab)'
            @keydown.enter="selectTab(tab)"
          >
            {{ tab.name }}
          </a>
        </nav>
      </div>
    </div>
    <div class='flex flex-col grow overflow-auto'>
      <template v-for='(tab, index) in tabNav'>
        <div v-if='tab.isActive === true' :key='index' class='grow h-full' role="tabpanel">
          <slot :name='`tabContent-${index + 1}`' />
        </div>
      </template>
    </div>

    <!-- footer -->
    <div 
    :class="{
      'tabs-footer': true,
      'a-left': footerAlignment === 'left',
      'a-center': footerAlignment === 'center',
      'a-right': footerAlignment === 'right',
    }">
      <slot name="tabFooter" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabNav: {
      type: Array, // Array<{ name: string, isActive: bool, display?: bool }>
      required: true
    },
    footerAlignment: {
      type: String, // 'left' | 'center' | 'right'
      default: undefined,
    },
  },
  computed: {
    href() {
      return this.name ? '#' + this.name.toLowerCase().replace(/ /g, '-') : ''
    }
  },
  methods: {
    selectTab(selectedTab) {
      if (selectedTab.disabled) return

      this.tabNav.forEach((tab, index) => {
        tab.isActive = tab.name === selectedTab.name
        this.tabActive = tab.isActive
        selectedTab.index = index
      })
      this.$emit('select', selectedTab)
    }
  }
}
</script>

<style  lang="scss" scoped>
.scroll-multiple-tabs {
  @apply overflow-x-auto;
  @apply overflow-y-hidden;
  @apply whitespace-nowrap;

  *::-webkit-scrollbar {
    width: 0.2rem !important;
  }
  *::-webkit-scrollbar-track {
    @apply bg-white;
  }
  *::-webkit-scrollbar-thumb {
    @apply bg-gray-300 rounded;
  }
  scrollbar-width: thin;
}
.tabs-footer {
  @apply flex flex-col space-y-3;
  @apply sm:flex-row sm:space-y-0;

  &.a-left {
    @apply sm:justify-start;
  }

  &.a-center {
    @apply sm:justify-center;
  }

  &.a-right {
    @apply sm:justify-end;
  }

}
</style>
