<template>
  <nav
    ref="pageNavEl"
    :class="{
      'page-nav': true,
      'v-scroll': scroll,
      'v-left-dimming': showLeftDimming,
      'v-right-dimming': showRightDimming,
      'd-light': dimmingBackground === 'light',
    }"
  >
    <ul ref="menuEl" class="page-nav-menu">
      <slot />
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    scroll: {
      type: Boolean,
      default: false,
    },
    autoScrollOffset: {
      type: Number,
      default: 32, // dimming width
    },
    dimmingBackground: {
      type: String, // 'white' | 'light'
      default: 'white',
    },
  },
  data() {
    return {
      showLeftDimming: false,
      showRightDimming: false,
      resizeObserver: undefined,
    }
  },
  mounted() {
    if (this.scroll) {
      this.updateDimmings()
      this.updateAutoScroll()
      this.setupResizeObserver()
      this.$refs.menuEl.onscroll = () => {
        this.updateDimmings()
      }
    }
  },
  methods: {
    setupResizeObserver() {
      if (typeof ResizeObserver === 'undefined') return

      this.resizeObserver = new ResizeObserver(() => {
        this.updateDimmings()
        this.updateAutoScroll()
      })
      this.resizeObserver.observe(this.$refs.menuEl)
    },
    updateDimmings() {
      if (this.$refs.menuEl.scrollWidth <= this.$refs.menuEl.clientWidth) {
        this.showLeftDimming = false
        this.showRightDimming = false
        return
      }
      this.showLeftDimming = this.$refs.menuEl.scrollLeft > 0
      this.showRightDimming =
        this.$refs.menuEl.scrollLeft < this.$refs.menuEl.scrollWidth - this.$refs.menuEl.clientWidth
    },
    updateAutoScroll() {
      const activeChild = this.$refs.menuEl.querySelector('.active')
      const negativeMargin = -parseInt(getComputedStyle(this.$refs.pageNavEl).marginLeft)
      if (activeChild) {
        const scrollAmount = activeChild.offsetLeft - negativeMargin - (this.autoScrollOffset || 0)
        this.$refs.menuEl.scrollLeft = scrollAmount
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page-nav {
  --dimming-color: 255 255 255;
  @apply relative w-full;
  @apply -mx-2.5 -my-1.5;

  &.v-scroll {
    &::before,
    &::after {
      content: '';
      @apply absolute block inset-y-0 w-8;
      @apply opacity-0 z-[1];
      @apply transition-opacity duration-300;
      @apply from-white to-white/0;
    }

    &::before {
      @apply left-2.5;
      @apply bg-gradient-to-r;
    }

    &::after {
      @apply -right-2.5;
      @apply bg-gradient-to-l;
    }
  }

  &.d-light {
    &::before,
    &::after {
      @apply from-extra-light to-extra-light/0;
    }
  }

  &.v-left-dimming {
    &::before {
      @apply opacity-100;
    }
  }

  &.v-right-dimming {
    &::after {
      @apply opacity-100;
    }
  }
}

.page-nav-menu {
  @apply flex flex-wrap items-start;

  .v-scroll & {
    @apply flex-nowrap overflow-x-auto scroll-smooth;

    &::-webkit-scrollbar {
      @apply hidden;
    }
  }

  > * {
    @apply mx-2.5 my-1.5;
  }
}
</style>
