<template>
  <tr
    :class="{
      'table-row': true,
      'v-link': to,
    }"
  >
    <TableCell v-if="selectable">
      <Checkbox v-if="!disableSelection" :value="selected" @input="toggleSelected" />
    </TableCell>
    <slot />

    <div @click="onClick">
      <Icon v-if="to" name="chevronRight" class="table-row-indicator" />
    </div>
  </tr>
</template>

<script>
export default {
  props: {
    record: {
      type: [Object, String, Number],
      required: true,
    },
    to: {
      type: String,
      default: undefined,
    },
    disableSelection: {
      type: Boolean,
      defualt: false,
    },
  },
  data() {
    return {
      selectable: this.isSelectable(),
      selected: this.isSelected(),
    }
  },
  watch: {
    '$parent.$props': {
      handler() {
        const selectable = this.isSelectable()
        const selected = this.isSelected()

        if (selectable !== this.selectable) {
          this.selectable = selectable
        }
        if (selected !== this.selected) {
          this.selected = selected
        }
      },
      deep: true,
    },
  },
  methods: {
    isSelectable() {
      return this.$parent.$props.selectable
    },
    isSelected() {
      if (!this.selectable) return false
      if (!this.$parent.$props.selectedRecords) return false
      const index = this.$parent.$props.selectedRecords.indexOf(this.record)
      return index >= 0
    },
    toggleSelected(checked) {
      if (!this.$parent.$props.selectedRecords) return false

      let selectedRecords;
      if (this.$parent.$props.singleSelection && checked) {
        selectedRecords = [this.record];
      } else {
        const index = this.$parent.$props.selectedRecords.indexOf(this.record);
        selectedRecords = this.$parent.$props.selectedRecords;
        if (index >= 0 && !checked) {
          selectedRecords.splice(index, 1)
        } else if (index === -1 && checked) {
          selectedRecords.push(this.record)
        }
      }

      this.$parent.$emit('update:selectedRecords', selectedRecords)
    },
    onClick() {
      if (!this.to) return
      if (!this.$router) return
      this.$router.push(this.to)
    },
  },
}
</script>

<style lang="scss" scoped>
.table-row {
  @apply relative;

  &.v-link {
    @apply cursor-pointer;
    @apply transition-colors duration-200 ease-in-out;
    @apply hover:bg-soft-blue-300;

    .table-cell:last-of-type {
      @apply pr-8;
    }

    .table-row-indicator {
      @apply text-2xl text-primary-300;
      @apply absolute right-1 top-1/2 -translate-y-1/2;
    }
  }
}
</style>

<style lang="scss" global>
.table.v-alternate-light {
  .table-row.v-link:nth-child(odd) {
    @apply hover:bg-soft-blue-300;
  }
}
</style>
