<template>
  <div
    :class="{
      'contextual-navigation': true,
      expanded: menuExpanded,
    }"
  >
    <header class="contextual-navigation-header">
      <div class="contextual-navigation-avatar" :style="{ backgroundImage: `url(${avatarUrl})` }" />
      <div class="contextual-navigation-info">
        <Txt class="contextual-navigation-title" weight="bold" small>{{ title }}</Txt>
        <Txt v-if="subtitle" class="contextual-navigation-subtitle" type="footnote">
          {{ subtitle }}
        </Txt>
      </div>
    </header>

    <button class="contextual-navigation-mobile-toggle" @click="toggleMenuExpanded">
      <Txt as="span" small>{{ currentItemTitle }}</Txt>
      <Icon name="chevronDown" :size="30" />
    </button>

    <nav
      ref="menuEl"
      class="contextual-navigation-menu"
      :style="{
        height: animating ? (menuExpanded ? `${menuHeight}px` : '0px') : undefined,
      }"
    >
      <ul>
        <slot />
      </ul>
    </nav>

    <div v-if="currentProgress != null" class="contextual-menu-item-progress-wrapper">
      <div class="contextual-menu-item-progress">
        <span :style="{ width: `${currentProgress}%` }" />
      </div>
    </div>
  </div>
</template>

<script>
import Txt from './Txt.vue'
import Icon from './Icon.vue'

export default {
  components: { Txt, Icon },
  props: {
    title: {
      type: String,
      default: undefined,
    },
    subtitle: {
      type: String,
      default: undefined,
    },
    avatarUrl: {
      type: String,
      default: undefined,
    },
    currentItemTitle: {
      type: String,
      default: undefined,
    },
    currentProgress: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      menuExpanded: false,
      animating: false,
      menuHeight: 0,
    }
  },
  mounted() {
    this.$refs.menuEl.addEventListener('transitionend', () => {
      this.animating = false
    })
  },
  methods: {
    toggleMenuExpanded() {
      const expand = !this.menuExpanded

      this.updateMenuHeight()
      this.animating = true
      setTimeout(
        () => {
          this.menuExpanded = expand
        },
        expand ? 0 : 100
      )
    },
    updateMenuHeight(wait = false) {
      // wait for the content to appear
      setTimeout(
        () => {
          const calcHeight = Array.from(this.$refs.menuEl.children)
            .map((child) => child.scrollHeight)
            .reduce((hTot, h) => hTot + h, 0)
          this.menuHeight = calcHeight
        },
        wait ? 50 : 0
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.contextual-navigation {
  @apply flex flex-col relative;
  @apply lg:h-full;
}

.contextual-navigation-header {
  @apply flex items-center px-5;
  @apply pt-6 lg:pt-0;
  @apply mb-5 lg:mb-10;
  @apply px-0;
}

.contextual-navigation-avatar {
  @apply shrink-0;
  @apply w-8 h-8;
  @apply md:w-14 md:h-14;
  @apply bg-soft-blue-500 rounded-full overflow-hidden;
  @apply bg-center bg-cover bg-no-repeat;
}

.contextual-navigation-info {
  @apply flex-1 ml-4;
  @apply overflow-hidden
}

.contextual-navigation-title {
  @apply md:text-md;
}

.contextual-navigation-subtitle {
  @apply mt-1;
}

.contextual-navigation-mobile-toggle {
  @apply flex items-center justify-between;
  @apply px-5 py-0.5 h-10;
  @apply text-violet-700 font-bold;
  @apply lg:hidden;

  .expanded & {
    @apply bg-soft-blue-300;

    .icon {
      @apply rotate-180;
    }
  }

  .icon {
    @apply transition-transform duration-200 ease-in-out;
  }
}

.contextual-navigation-menu {
  @apply h-0 overflow-auto;
  @apply duration-300 ease-in-out;
  transition-property: height;
  @apply lg:h-auto;

  .expanded & {
    @apply h-auto overflow-y-auto;
  }
}

.contextual-menu-item-progress-wrapper {
  @apply absolute top-full inset-x-0;
  @apply lg:hidden;
}

.contextual-menu-item-progress {
  @apply relative h-[3px];

  > span {
    @apply absolute left-0 inset-y-0;
    @apply bg-accent-500;
    @apply duration-300 ease-in-out;
    transition-property: width;
  }
}
</style>
