<template>
  <Card
      :class="{
      'cyber-risk-card': true,
    }"
      :disabled="disabled"
      :to="to"
      :unstyled="true"
  >
    <Modal :open.sync="showCyberRiskModal" :trigger="$refs.cyberRiskBtn" :title="$t('Dashboard.CyberScoreModalTitle')" :description="$t('Dashboard.CyberScoreModalDescription')" footer-alignment="right">
      <ul class="space-y-3">
        <li><Txt size="small"><strong>{{ $t('Dashboard.CyberScoreModalKPI_1_title') }}:</strong> {{ $t('Dashboard.CyberScoreModalKPI_1_description') }}</Txt></li>
        <li><Txt size="small"><strong>{{ $t('Dashboard.CyberScoreModalKPI_2_title') }}:</strong> {{ $t('Dashboard.CyberScoreModalKPI_2_description') }}</Txt></li>
        <li><Txt size="small"><strong>{{ $t('Dashboard.CyberScoreModalKPI_3_title') }}:</strong> {{ $t('Dashboard.CyberScoreModalKPI_3_description') }}</Txt></li>
      </ul>
      <template #footer>
        <Button @click="toggleModalInfo()">{{$t('General.Close')}}</Button>
      </template>
    </Modal>
    <header class="cyber-risk-card-header">
      <div class="flex items-center">
        <Txt weight="bold" no-margin>{{ title }}</Txt>
        <Button ref="cyberRiskBtn" variant="ghost" :aria-label="$t('Wcag.ToggleCyberRisk')" @click="toggleModalInfo()">
          <Icon name="info" class="-mt-0.5 ml-1" size="18"></Icon>
        </Button>
      </div>
      <Txt weight="bold" class="risk-rating-average" small>
        <span>{{ averageValue }}%</span>
        <Txt v-if="averageLabel" class="risk-rating-average-label" extra-small>{{ averageLabel }}</Txt>
      </Txt>
    </header>
    <div v-if='typeof forceHistogram !== "undefined"'>
      <div v-if='radarOrIstoChart' class="cyber-risk-card-body">
        <RadarChart
            :datasets="datasets"
            :labels="labels"
            :chart-options="chartConfig"
            :aspect-ratio="1"
            :show-labels="showLabels"
            :show-point-labels="showPointLabels"
            :show-legend="showLegend"
        />
      </div>
      <div :class="radarOrIstoChart? 'sr-only' : 'cyber-risk-progress-body'">
        <div v-for='i in progressDatasets' :key='i.label' class='progress-entry'>
          <div class='label'>
            <Txt small weight='bold'>{{ i.label }}</Txt>
            <div v-if='i.active'>
              <Txt small>{{ i.value }}%</Txt>
            </div>
            <div v-else class='label'>
              <Txt extra-small class="mt-1 mr-1">{{ $t('General.NotAvailableShort') }}</Txt>
              <Tooltip placement="bottom-end">
                <icon name="info" size="1.5rem"/>
                <template #content>
                  {{ $t("Dashboard.EnableService", {plan: i.service}) }}
                </template>
              </Tooltip>
            </div>
          </div>
          <Progress :max='100' :value='i.value'/>
        </div>
      </div>
    </div>

  </Card>
</template>

<script>
import {mergeObjects} from '@/utils/object';
import {kpiServiceAssociation} from '@/common/constants';

export default {
  props: {
    title: {
      type: String,
      default: 'Cyber Risk',
    },
    averageLabel: {
      type: String,
      default: undefined,
    },
    datasetLabel: {
      type: String,
      default: 'Attacchi',
    },
    params: {
      type: Array, // Array<{ id: string, name: string, display: string }>
      default: () => [
        {
          id: 'phishing',
          name: 'Phishing',
          display: 'Phish.',
        },
        {
          id: 'ransomware',
          name: 'Ransomware',
          display: 'Ransom.',
        },
        {
          id: 'ddos',
          name: 'DDOS',
          display: 'DDOS',
        },
        {
          id: 'mitm',
          name: 'Man in the Middle',
          display: 'MITM',
        },
        {
          id: 'sqlInjection',
          name: 'SQL Injection',
          display: 'SQL Inj.',
        },
      ],
    },
    data: {
      type: Object, // Record<string, number>
      default: () => ({
        phishing: 0,
        ransomware: 0,
        ddos: 0,
        mitm: 0,
        sqlInjection: 0,
      }),
    },
    average: {
      type: Number,
      default: undefined,
    },
    to: {
      type: String,
      default: undefined,
    },
    showLabels: {
      type: Boolean,
      default: false,
    },
    showLegend: {
      type: Boolean,
      default: false
    },
    showPointLabels: {
      type: Boolean,
      default: false,
    },
    chartOptions: {
      type: Object,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      forceHistogram: undefined,
      showCyberRiskModal: false,
    };
  },
  computed: {
    labels() {
      return this.params.map((param) => param.display);
    },
    chartConfig() {
      return mergeObjects(
          {
            plugins: {
              tooltip: {
                callbacks: {
                  title: (tooltipItems) => {
                    const param = this.params.find((param) => param.display === tooltipItems.label);
                    return param ? '  ' + param.name : '';
                  },
                  label: (tooltipItems) => {
                    const param = this.params.find((param) => param.display === tooltipItems.label);

                    /* prevent 0 to be mistaken as undefined */
                    return param
                        ? ` ${param.name}  ${(!!(this.data[param.id]) || this.data[param.id] === 0) ? this.data[param.id].toFixed(2) : '-'}`
                        : '';
                  },
                },
              },
            },
          },
          this.chartOptions
      );
    },
    barChartConfig() {
      return mergeObjects(
          {
            indexAxis: 'y',
            plugins: {
              legend: {
                display: true,
              },
            },
          },
          this.chartOptions
      );
    },
    datasets() {
      return [
        {
          label: this.datasetLabel,
          borderColor: this.$company.company_options.primary_color.value,
          backgroundColor: this.$company.company_options.primary_color.value + '50',
          data: this.params.map((param) => this.data[param.id] || 0),
        },
      ];
    },
    progressDatasets() {
      return this.params
          .map((param) => ({
            value: +this.data[param.id].toFixed(2) || 0,
            label: param.name,
            active: this.$profile.hasService(param.service),
            service: this.$services.service(param.service),
          }));
    },
    radarOrIstoChart() {
      return !this.forceHistogram && Object.values(kpiServiceAssociation).filter(e => this.$profile.hasService(e)).length >= 3;
    },
    averageValue() {
      if (this.average) return this.average;

      const data = this.datasets[0].data;
      if (!data.length) return "- ";

      const totalAttacks = data.reduce((sum, val) => sum + val, 0);
      const average = totalAttacks / data.length;
      return average.toFixed(2);
    },
  },
  async beforeMount() {
    this.forceHistogram = this.$company.company_options.force_histogram?.value || false;
  },
  methods: {
    toggleModalInfo() {
      this.showCyberRiskModal = !this.showCyberRiskModal;
    }
  }
};
</script>

<style lang="scss" scoped>
.cyber-risk-card {
}

.cyber-risk-card-header {
  @apply mb-0;
  @apply flex items-start;
}

.risk-rating-average {
  @apply ml-auto text-right;
}

.risk-rating-average-label {
  @apply font-light
}

.cyber-risk-card-body {
  @apply my-0 mx-2 pb-4;
}

.cyber-risk-progress-body {
  @apply flex flex-col space-y-4 my-4 pb-4;
}

.label {
  @apply flex flex-row justify-between items-center h-8;
}
</style>
