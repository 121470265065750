import { apiClient } from '@/plugins/api'

export default {
  getPhishingCompanies() {
    return apiClient.get(`phishing/company/`)
  },
  updatePhishingCompany(companyId, payload) {
    return apiClient.put(`phishing/company/${companyId}`, payload)
  },
  getPhishingCompany(companyId) {
    return apiClient.get(`phishing/company/${companyId}`)
  },
  getCompanyGlobalStats(companyId, queryParams) {
    let opt = { params: queryParams }
    return apiClient.get(`phishing/company/${companyId}/globalStats`, opt)
  },
  getNumAttackedTargets(companyId, queryParams) {
		return apiClient.get(`phishing/company/${companyId}/numAttackedTargets`, { params: queryParams });
	},
  getReliabilityScore(companyId, queryParams) {
    let opt = { params: queryParams }
    return apiClient.get(`phishing/company/${companyId}/reliability`, opt)
  },
  getRiskByLocation(companyId, queryParams) {
    let opt = { params: queryParams }
    return apiClient.get(`phishing/company/${companyId}/riskByLocation`, opt)
  },
  getLastCompanyCampaigns(companyId, queryParams) {
    let opt = { params: queryParams }
    return apiClient.get(`phishing/company/${companyId}/lastCampaigns`, opt)
  },
  getCompanySummary(companyId, queryParams) {
    let opt = { params: queryParams }
    return apiClient.get(`phishing/company/${companyId}/summary`, opt)
  },
  getCompanyTagSummary(companyId, tag, queryParams) {
    let opt = { params: queryParams }
    return apiClient.get(`phishing/company/${companyId}/tagSummary/${tag}`, opt)
  },
  getClickersByTagSummary(companyId, tag, queryParams) {
    let opt = { params: queryParams }
    return apiClient.get(`phishing/company/${companyId}/tagClickersSummary/${tag}`, opt)
  },
  getCompanyRiskByTag(companyId, tag, queryParams) {
    let opt = { params: queryParams }
    return apiClient.get(`phishing/company/${companyId}/tagRisk/${tag}`, opt)
  },
  getCompanyCampaigns(companyId, queryParams) {
    let opt = { params: queryParams }
    return apiClient.get(`phishing/company/${companyId}/campaign`, opt)
  },
  getCampaigns(queryParams){
    let opt = { params: queryParams }
    return apiClient.get(`phishing/campaign`, opt);
  },
  createCompanyReport(companyId, body, queryParams) {
    return apiClient.post(`phishing/company/${companyId}/report`, body ,  { params: queryParams, responseType: 'blob' });
  },
  createCampaignReport(companyId, campaignId, body, queryParams) {
    return apiClient.post(`phishing/company/${companyId}/campaign/${campaignId}/report`, body,  { params: queryParams, responseType: 'blob' });
  },
  createRemediationReport(companyId, campaignId, body, queryParams) {
    return apiClient.post(`phishing/company/${companyId}/campaign/${campaignId}/remediation/report`, body,  { params: queryParams, responseType: 'blob' });
  },
  exportData(companyId, queryParams) {
    return apiClient.get(`phishing/company/${companyId}/export`, { params: queryParams, responseType: 'blob' });
  },
  exportCampaignData(companyId, campaignId, queryParams) {
    return apiClient.get(`phishing/company/${companyId}/campaign/${campaignId}/export`, { params: queryParams, responseType: 'blob' });
  },
  exportRemediationData(companyId, campaignId, queryParams) {
    return apiClient.get(`phishing/company/${companyId}/campaign/${campaignId}/remediation/export`, { params: queryParams, responseType: 'blob' });
  },
  getSysprop(prop, queryParams) {
    let opt = { params: queryParams }
    return apiClient.get(`phishing/sysprop/${prop}`, opt)
  },
  getConfig() {
    let opt = {
      params: { seed: Math.random().toString(36).substring(2, 15) }
    }
    return apiClient.get('/phishing/sysprop/config', opt)
  },
  getLanguages() {
    return apiClient.get('/phishing/sysprop/languages')
  }
}
