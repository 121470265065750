import Vue from 'vue'
import VueRouter from 'vue-router'
import companyService from "@/services/company.service"
import { default as routes, defaultPaths} from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  companyService.ping();
  next();
})

router.beforeResolve(async (to, from, next) => {
  Vue.prototype.$eventBus.$emit('route-changed', to.path)

  try {
    const roleRouteConf = Vue.prototype.$roleMap[(localStorage.getItem('activeRole') || '').split('_')[0]] || ''

    if (defaultPaths.includes(to.path) || to.path.toLowerCase().startsWith(roleRouteConf.baseRoute)) {
      next()
    } else {
      next({ path: roleRouteConf.baseRoute })
    }
  } catch (error) {
    console.error(error)
    Vue.prototype.$eventBus.$emit('logout');
  }
})

export default router
