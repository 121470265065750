<template>
  <Card
    :class="{
      'module-card': true,
      'v-progress': completion >= 0 && completion < 100,
    }"
    :thumbnail-url="thumbnailUrl"
    :disabled="locked"
    :to="to"
    :text="module"
    :content-description="$t('Wcag.LearningContent')"
  >
    <div>
      <span class="sr-only">{{ $t('Wcag.LearningPath') }}</span>
      <CategoryLabel v-if="modulePath" class="module-card-category" :color="locked ? colors.primary[500] : colors.primary[700]">{{ modulePath }}</CategoryLabel>
      <div class='module-title'>
        <div v-if="!locked" role="button" tabindex="0" :aria-label="$t('Wcag.OpenVideoInNewPage', {title: title || modulePath} )" @click="$emit('action-click')" @keyup.enter="$emit('action-click')">
          <Icon :alt="$t('Wcag.CoursePlayButton', { course: title})" class='module-level-play-button' name='play'></Icon>
        </div>
        <span class="sr-only">{{ $t('Wcag.Title') }}</span>
        <Txt as="p" class="module-card-title font-bold" no-margin>{{ title }}</Txt>
      </div>
      <span class="sr-only">{{ $t('Wcag.Description') }}</span>
      <Tooltip :mode="tooltipMode">
        <Txt class="module-card-description" extra-small>
          {{ description }}
        </Txt>
        <template #content>
          <Txt aria-hidden="true" extra-small>
            {{ description }}
          </Txt>
        </template>
      </Tooltip>
    </div>
    <div class='mt-4'>
      <Txt v-if="completionStatus === moduleCompletionStatuses.Passed && completion === 100" class="module-card-completion" weight="bold" extra-small>
        {{ $t('Modules.CompletedDate') }} {{ completionTimestamp | date }}
      </Txt>
      <Txt v-if="completionStatus === moduleCompletionStatuses.Passed && completion < 100" class="module-card-retry" weight="bold" extra-small>
        {{ $t('Modules.CompletedDate') }} {{ completionTimestamp | date }}
        <Tooltip placement="auto" class="ml-1 -mt-1">
          <Icon name="alert" :size="20" />
          <template v-if="!showSingleAttemptMessage" #content>
            {{ $t('Modules.MessageFailed') }}
          </template>
          <template v-else #content>
            {{ $t('Modules.MessageSingleAttemptCupFailed') }}
          </template>
        </Tooltip>
      </Txt>
      <Txt v-if="completionStatus === moduleCompletionStatuses.Failed" class="module-card-fail" weight="bold" extra-small>
        {{ $t('Modules.NotReachedRetry') }}
        <Tooltip placement="auto" class="ml-1 -mt-1">
          <Icon name="alert" :size="20" />
          <template #content>
            {{ $t('Modules.MessageRetry') }}
          </template>
        </Tooltip>
      </Txt>
      <Txt v-if="locked" class="module-card-locked-notice" weight="bold" extra-small>
        <span v-if="availableTimestamp && $dateTime.now() < $dateTime.fromFormat(availableTimestamp, 'yyyy-MM-dd HH:mm:ss')">
          {{ $t('Modules.ArrivalDate') }} {{ availableTimestamp | date }}
        </span>
        <span v-else class="module-card-locked-txt">
          <span :class="{ 'module-card-locked-txt': lockReasonDisplay === 'tooltip' }">
            <span class="-mb-1">{{ $t('Modules.BlockedContent') }}</span>
            <span v-if="lockReason">
              <Tooltip v-if="lockReasonDisplay === 'tooltip'" placement="top" class="flex">
                <Icon name="alert" :size="20" />
                <template #content>
                  {{ lockReason }}
                </template>
              </Tooltip>
              <span v-else>: {{ lockReason }}</span>
            </span>
          </span>

          <Icon v-if="!lockReason" name="alert" :size="20" class="-mt-1" />

        </span>
      </Txt>
    </div>

    <template #accessory>
      <Button
          v-if="$listeners['action-click']"
          :aria-label="$t('Wcag.OpenVideoInNewPage', {title: title || modulePath})"
          :color="statusColor"
          class="module-card-action"
          shape="circle"
          @click="$emit('action-click')"
      >
        <Icon v-if="buttonIcon" :name="buttonIcon" />
        <Icon class="play-icon" name="play" />
        <CircularProgress
          v-if="completionStatus === moduleCompletionStatuses.Incomplete && !locked"
          :value="completion"
          :font-size="14"
          :size="48"
          :track-width="2"
          color="orange"
        >
          <template #default="{ progress }">
            <Txt v-if="completionStatus === moduleCompletionStatuses.Incomplete && completion > 0" :size="14">{{ progress }}%</Txt>
          </template>
        </CircularProgress>
      </Button>
    </template>

    <template #footer>
      <div class="module-card-footer">
        <div>
          <Txt v-if='duration' extra-small>{{ $t('Modules.Duration') }}:&nbsp;</Txt>
          <Txt weight='bold' extra-small>{{ duration | seconds }}</Txt>
        </div>
        <div>
          <div
            v-if='badgeStatus != badgeModuleStatuses.NotAvailable'
            :class="{'grayscale': badgeStatus == badgeModuleStatuses.NotEarned}"
            :style="{ backgroundImage: 'url(' + badgeIcon + ')' }"
            class='w-8 h-8 bg-auto bg-no-repeat'>
            <span class="sr-only">
              {{ badgeStatus == badgeModuleStatuses.NotEarned ? $t('Wcag.BadgeNotEarned') : $t('Wcag.BadgeEarned') }}
            </span>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { ModuleCompletionStatuses, BadgeModuleStatuses } from '@/common/constants'
import colors from "@/utils/colors";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: undefined,
    },
    to: {
      type: String,
      default: undefined,
    },
    module: {
      type: String,
      required: true,
    },
    moduleColor: {
      type: String,
      default: undefined,
    },
    thumbnailUrl: {
      type: String,
      default: undefined,
    },
    completion: {
      type: Number,
      default: undefined,
    },
    completionStatus: {
      type: Number,
      default: undefined,
    },
    progressMeasure:{
      type: Number,
      default: undefined
    },
    completionTimestamp: {
      type: String,
      default: undefined,
    },
    availableTimestamp: {
      type: String,
      default: undefined,
    },
    duration: {
      type: Number,
      default: undefined,
    },
    coins: {
      type: Number,
      default: undefined,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    lockReason: {
      type: String,
      default: undefined,
    },
    lockReasonDisplay: {
      type: String, // 'inline' | 'tooltip'
      default: 'inline',
    },
    tooltipMode: {
      type: String, // see Tooltip.vue
      default: 'click',
    },
    badgeStatus:{
      type: Number,
      default: 0,
    },
    modulePath: {
      type: String,
      default: undefined,
    },
    showSingleAttemptMessage:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      badgeIcon: document.location.origin + require('../../assets/badges/badge.svg'),
      badgeModuleStatuses: BadgeModuleStatuses,
      moduleCompletionStatuses: ModuleCompletionStatuses
    }
  },
  emits: ['action-click'],
  computed: {
    colors() {
      return colors
    },
    buttonIcon() {
      if (this.locked) return 'lock'
      if (this.completionStatus === this.moduleCompletionStatuses.Failed) return 'restart'
      if (this.completionStatus === this.moduleCompletionStatuses.Passed && this.completion === 100) return 'check'
      if (this.completionStatus === this.moduleCompletionStatuses.Passed && this.completion < 100) return 'check'
      if (this.completionStatus === this.moduleCompletionStatuses.Incomplete && this.completion == 0) return 'play'
      return null
    },
    statusColor() {
      if (this.completionStatus === this.moduleCompletionStatuses.Failed) return 'red'
      if (this.completionStatus === this.moduleCompletionStatuses.Passed && this.completion === 100) return 'green'
      if (this.completionStatus === this.moduleCompletionStatuses.Passed && this.completion < 100) return 'yellow'
      return 'light'
    },
  },
}
</script>

<style lang="scss" scoped>
.module-card-title {
  @apply text-sm;
}

.module-card-category {
  @apply mb-1.5;
  @apply pr-12;
}

.module-card-description {
  @apply mt-1.5;
  @apply leading-tight line-clamp-2;
  @apply cursor-default;

  .disabled & {
    @apply cursor-default;
  }
}

.module-card-locked-notice {
  @apply mt-3;

.module-card-locked-txt {
    @apply flex items-center space-x-1;
 }
}

.module-card-completion {
  @apply text-green-700;
}

.module-card-retry {
  @apply text-accent-700;
  display: inline-flex;
  align-items: center;
}

.module-card-fail {
  @apply text-red-700;
  display: inline-flex;
  align-items: center;
}

.module-card-action {
  &.f-circle {
    @apply shadow-none;
    @apply w-12 h-12;
  }
  &.c-light {
    @apply bg-white;
    &:not(:disabled):hover {
      @apply bg-white text-primary-700;
    }
  }

  .icon {
    @apply flex items-center justify-center;
  }

  .play-icon {
    @apply opacity-0;
  }
}

.module-title {
  @apply flex items-center mb-2;
  h3 {
    @apply mt-0.5;
  }
}

.module-level-play-button {
  @apply border-[0.8px] border-primary-500 rounded-full w-6 h-6 flex items-center justify-center mr-1.5 cursor-pointer hover:bg-primary-500 hover:text-white transition-colors pl-[.9px];

}

span.tooltip {
  @apply justify-start;
}

.module-card-footer {
  @apply flex items-center justify-between space-x-3;
  @apply text-primary-700;
  @apply min-h-[35px];

  > * {
    @apply inline-flex items-center;
  }
}
</style>

<style lang="scss" global>
.module-card-action {
  .button-content {
    @apply w-full h-full;

    > * {
      @apply absolute inset-0;
      @apply transition-opacity duration-300 ease-in-out;
    }
  }

  .circular-progress {
    .circular-progress-circle-track {
      @apply stroke-primary-100;
    }
  }

  &:hover {
    .circular-progress-text {
      @apply opacity-0;
    }
    .icon {
      @apply opacity-0;
    }
    .play-icon {
      @apply opacity-100;
    }
  }
}
</style>
