<template>
    <div v-if="hideRiskEvolution">
        <MockedCyberChainStrength></MockedCyberChainStrength>
    </div>
    <div v-else>
        <AnalyticsCard layout="vertical" :title="title" :chart-height="chartHeight" :showLoader="isLoading">
            <template #chart>
                <BarChart
                    v-if="timelineGraph.labels?.length"
                    :datasets="timelineGraph.datasets"
                    :labels="timelineGraph.labels"
                    show-x-border
                    show-y-labels
                    show-x-labels
                    stacked
                    show-legend
                    show-x-intra-lines
                    show-y-intra-lines
                    :aspect-ratio="aspectRatio"
                    :chart-options="options"
                    :chart-height="500"
                />
                <div v-else  class="!min-h-[13rem] flex font-bold text-xl">
                    <div class="m-auto"> <Txt bold>{{ $t('Dashboard.Charts.NoData') }}</Txt> </div>
                </div>
            </template>
            <template #menu-items>
                <MenuItem @click="setTimelineMode(1)">{{$t('Dashboard.Charts.RiskEvolution')}}</MenuItem>
                <MenuItem @click="setTimelineMode(0)">{{$t('Dashboard.Charts.ClickerPerCampaign')}}</MenuItem>
            </template>
        </AnalyticsCard>
    </div>
</template> 

<script>
import colors from '@/utils/colors'
import { RiskGroup } from '@/common/constants'

export default {
	data() {
		return {
            options : {},
            timelineGraphMode : 1, 
            timelineGraph : {
                datasets : [],
                labels : []
            },
            riskEvolution: {},
            clickerPerCampaign : {}
		}
	},
	props: {
        chartHeight: Number,
        aspectRatio: Number,
		data: Object,
        hideEvolution: Boolean,
        isLoading: Boolean
	},
    watch: {
        $props: {
            handler() {
                this.loadData()
            },
            deep: true,
            immediate: true
        }
    },
	mounted(){
		this.loadData()
	},
    computed : {
        title(){
            return (this.timelineGraphMode) ? this.$t('Dashboard.Charts.RiskEvolution') : this.$t('Dashboard.Charts.ClickersPerCampaign')
        },
        hideRiskEvolution() {
            return this.hideEvolution;
        }
    },
	methods : {
        loadData() {
            this.transformData();
            this.setTimelineMode(1)
        },
        initDatasets(){
            this.clickerPerCampaign = {
                datasets: [
                    {
                        label: this.$t('Dashboard.Charts.Defender'),
                        borderColor: colors['soft-blue'][700],
                        backgroundColor: colors['soft-blue'][500],
                        data: [],
                        sent: [],
                        num_targets : [],
                        percentage : [],
                        maxBarThickness: 150
                    },
                    {
                        label: this.$t('Dashboard.Charts.Strong'),
                        borderColor: colors.green[700],
                        backgroundColor: colors.green[500],
                        data: [],
                        sent: [],
                        num_targets : [],
                        percentage : [],
                        maxBarThickness: 150
                    },
                    {
                        label: this.$t('Dashboard.Charts.Intermediate'),
                        borderColor: colors.accent[700],
                        backgroundColor: colors.accent[500],
                        data: [],
                        sent: [],
                        num_targets : [],
                        percentage : [],
                        maxBarThickness: 150
                    },
                    {
                        label: this.$t('Dashboard.Charts.Weak'),
                        borderColor: colors.red[700],
                        backgroundColor: colors.red[500],
                        data: [],
                        sent: [],
                        num_targets : [],
                        percentage : [],
                        maxBarThickness: 150
                    },
                ],
                labels : [],
                start: [],
                end : [],
            }
            this.riskEvolution = {
                labels: [],
                start: [],
                end : [],
                datasets: [
                    {
                        label: this.$t('Dashboard.Charts.Defender'),
                        borderColor: colors['soft-blue'][700],
                        backgroundColor: colors['soft-blue'][500],
                        data: [],
                        num_targets : [],
                        clicked : [],
                        sent : [],
                        maxBarThickness: 150
                    },
                    {
                        label: this.$t('Dashboard.Charts.Strong'),
                        borderColor: colors.green[700],
                        backgroundColor: colors.green[500],
                        data: [],
                        num_targets : [],
                        clicked : [],
                        sent : [],
                        maxBarThickness: 150
                    },
                    {
                        label: this.$t('Dashboard.Charts.Intermediate'),
                        borderColor: colors.accent[700],
                        backgroundColor: colors.accent[500],
                        data: [],
                        num_targets : [],
                        clicked : [],
                        sent : [],
                        maxBarThickness: 150
                    },
                    {
                        label: this.$t('Dashboard.Charts.Weak'),
                        borderColor: colors.red[700],
                        backgroundColor: colors.red[500],
                        data: [],
                        num_targets : [],
                        clicked : [],
                        sent : [],
                        maxBarThickness: 150
                    },
                    
                    
                ],
            }
        },
        setTimelineMode(mode){
            this.timelineGraph = (mode) ? this.riskEvolution : this.clickerPerCampaign
            this.setOptions(mode);
            this.timelineGraphMode = mode
            this.$forceUpdate();
        },
		transformData(){
            this.initDatasets()
            const globalInfo = (this.data.globalInfo.length>0) ?  [...this.data.globalInfo].slice().reverse() : []
            const activeInfo = (this.data.active.length>0) ? [...this.data.active].slice().reverse() : []
            const statsInfo =  (this.data.stats.length>0) ? [...this.data.stats].slice().reverse() : []

			globalInfo.forEach( c =>{
				this.riskEvolution.labels.push(c.name)
                this.clickerPerCampaign.labels.push(c.name)
				
                this.riskEvolution.start.push(c.planned_start)
                this.clickerPerCampaign.start.push(c.planned_start)

                this.riskEvolution.end.push(c.planned_end)
                this.clickerPerCampaign.end.push(c.planned_end)

			})
            
            let campaigns = 0;

            for( const campaignActiveStats of activeInfo){
                let totalCampaignTargets = 0
                campaignActiveStats.forEach( a => totalCampaignTargets+=a.count ) ;

                for( const data of campaignActiveStats){
                    const percentage = ((data.count/totalCampaignTargets)*100).toFixed(2)
                    switch (data.target_risk) {
                        case RiskGroup.Defender:
                            this.riskEvolution.datasets[0].data.push(percentage)
                            this.riskEvolution.datasets[0].num_targets.push(data.count)
                            this.clickerPerCampaign.datasets[0].num_targets.push(data.count)
                            this.clickerPerCampaign.datasets[0].percentage.push(percentage)
                            break;
                        case RiskGroup.Rare:
                            this.riskEvolution.datasets[1].data.push(percentage)
                            this.riskEvolution.datasets[1].num_targets.push(data.count)
                            this.clickerPerCampaign.datasets[1].num_targets.push(data.count)
                            this.clickerPerCampaign.datasets[1].percentage.push(percentage)
                            break;
                        case RiskGroup.Frequent:
                            this.riskEvolution.datasets[2].data.push(percentage)
                            this.riskEvolution.datasets[2].num_targets.push(data.count)
                            this.clickerPerCampaign.datasets[2].num_targets.push(data.count)
                            this.clickerPerCampaign.datasets[2].percentage.push(percentage)
                            break;
                        case RiskGroup.Serial:
                            this.riskEvolution.datasets[3].data.push(percentage)
                            this.riskEvolution.datasets[3].num_targets.push(data.count)
                            this.clickerPerCampaign.datasets[3].num_targets.push(data.count)
                            this.clickerPerCampaign.datasets[3].percentage.push(percentage)
                            break;
                    
                        default:
                            break;
                    }
                }

                // ensure every dataset hase same number of values
                campaigns++;
                for(const d of this.riskEvolution.datasets){
                    if(d.data.length < campaigns)
                        d.data.push(0)
                    if(d.num_targets.length < campaigns)
                        d.num_targets.push(0)
                }
                for(const d of this.clickerPerCampaign.datasets){
                    if(d.percentage.length < campaigns)
                        d.percentage.push(0)
                    if(d.num_targets.length < campaigns)
                        d.num_targets.push(0)
                }


            }
            
            campaigns = 0;
            for(const campaignStats of statsInfo){
                for(const data of campaignStats){
                    switch (data.target_risk) {
                        case RiskGroup.Defender:
                            this.clickerPerCampaign.datasets[0].data.push(data.clicked)
                            this.clickerPerCampaign.datasets[0].sent.push(data.sent)
                            this.riskEvolution.datasets[0].clicked.push(data.clicked)
                            this.riskEvolution.datasets[0].sent.push(data.sent)
                            break;
                        case RiskGroup.Rare:
                            this.clickerPerCampaign.datasets[1].data.push(data.clicked)
                            this.clickerPerCampaign.datasets[1].sent.push(data.sent)
                            this.riskEvolution.datasets[1].clicked.push(data.clicked)
                            this.riskEvolution.datasets[1].sent.push(data.sent)
                            break;
                        case RiskGroup.Frequent:
                            this.clickerPerCampaign.datasets[2].data.push(data.clicked)
                            this.clickerPerCampaign.datasets[2].sent.push(data.sent)
                            this.riskEvolution.datasets[2].clicked.push(data.clicked)
                            this.riskEvolution.datasets[2].sent.push(data.sent)
                            break;
                        case RiskGroup.Serial:
                            this.clickerPerCampaign.datasets[3].data.push(data.clicked)
                            this.clickerPerCampaign.datasets[3].sent.push(data.sent)
                            this.riskEvolution.datasets[3].clicked.push(data.clicked)
                            this.riskEvolution.datasets[3].sent.push(data.sent)
                            break;
                    
                        default:
                            break;
                    }
                }
                
                campaigns++;
                for(const d of this.clickerPerCampaign.datasets){
                    if(d.data.length < campaigns)
                        d.data.push(0)
                    if(d.sent.length < campaigns)
                        d.sent.push(0)
                }

                for(const d of this.riskEvolution.datasets){
                    if(d.clicked.length < campaigns)
                        d.clicked.push(0)
                    if(d.sent.length < campaigns)
                        d.sent.push(0)
                }
                    

            }


		},
        setOptions(mode){
            if(mode){
                // set options for Risk Evolution mode
                this.options = {
                    scales:{
                        y : {
                            ticks : {
                                callback : (val) => {
                                    return val+ '%';
                                }
                            },
                            max: 100,
                            min: 0
                        }
                    },
                    plugins: {
                        tooltip: {
                            yAlign :'bottom',
                            callbacks : {
                                title : (tooltipItem) =>{
                                    return this.$t('Dashboard.Charts.ActivePopulation', {param: tooltipItem[0].label} )
                                },
                                label: (tooltipItem) => {
                                    let index = tooltipItem.dataIndex;
        
                                    let numTargets = tooltipItem.dataset.num_targets[index];
                                    return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue} % (${numTargets})`;
                                },
                                afterBody: (tooltipItem) => {
                                    
                                    let body = []

                                    const index = tooltipItem[0].dataIndex;
                                    const sent = tooltipItem[0].dataset.sent[index];
                                    const clicked = tooltipItem[0].dataset.clicked[index];
                                    const perc = sent > 0 ? ((clicked/sent)*100).toFixed(1) : 0;
                                    body.push("");
                                    body.push( `${this.$t('Dashboard.Charts.Clicks')}: ${clicked} ( ${perc}% ${tooltipItem[0].dataset.label})` );

                                    let start = this.$dateTime.fromISO(this.clickerPerCampaign.start[index]).toFormat('dd.MM.yyyy');
				                    let end = this.$dateTime.fromISO(this.clickerPerCampaign.end[index]).toFormat('dd.MM.yyyy');
                                    body.push( `${this.$t('Dashboard.Charts.CampaignPeriod')}: ${start} - ${end}` );
                                    
                                    return body;
                                }
                            }
                        }
                    }
                }
            }else{
                // set options for Clicker per campaign mode
                this.options = {
                    scales: {
                        y : {
                            scaleLabel: {
								display: true
							}
                        }
                    },
                    plugins: {
                        tooltip: {
                            yAlign :'bottom',
                            callbacks : {
                                title : (tooltipItem) =>{
                                    return this.$t('Dashboard.Charts.ActivePopulation', {param: tooltipItem[0].label} )
                                },
                                beforeBody : (tooltipItem)=>{
                                    const index = tooltipItem[0].dataIndex;
                                    const numTargets = tooltipItem[0].dataset.num_targets[index];
                                    const percentage = tooltipItem[0].dataset.percentage[index];
                                    return `${tooltipItem[0].dataset.label}: ${numTargets} (${percentage}%)`;
                                },
                                label: (tooltipItem) => {
                                    const index = tooltipItem.dataIndex;
        
                                    // let numTargets = tooltipItem.dataset.num_targets[index];
                                    const clickPercentage = ((tooltipItem.formattedValue / tooltipItem.dataset.sent[index]) * 100).toFixed(1)
                                    return `${this.$t('Dashboard.Charts.Clicks')}: ${tooltipItem.formattedValue} (${clickPercentage}%)`;
                                },
                                afterBody: (tooltipItem) => {
                                    
                                    const index = tooltipItem[0].dataIndex;
                                    let body = []

                                    let start = this.$dateTime.fromISO(this.clickerPerCampaign.start[index]).toFormat('dd.MM.yyyy');
				                    let end = this.$dateTime.fromISO(this.clickerPerCampaign.end[index]).toFormat('dd.MM.yyyy');
                                    body.push("");
                                    body.push( `${this.$t('Dashboard.Charts.CampaignPeriod')}: ${start} - ${end}` );
                                    
                                    return body;
                                }
                            }
                        }
                    }
                }
            }
        }

	}
}
</script>