<template>
  <InputWrapper
    :errored="errored"
    :error-message="errorMessage"
    :large="large"
    :label="label"
    :disabled="disabled"
    unstyled-prefix
    unstyled-suffix
  >
    <textarea
      :id="id"
      v-model="textAreaValue"
      :name="name"
      :rows="rows"
      :cols="cols"
      :placeholder="!disabled? placeholder : null"
      class="textarea"
      :disabled="disabled"
      :readonly="readonly"
      :required="required"
      :class="{'resize-none': noResize }"
      @input="$emit('input', $event.target.value)"
      @change="$emit('change', $event.target.value)"
      @click="$emit('click')"
    ></textarea>
  </InputWrapper>
</template>

<script>
import InputWrapper from './InputWrapper.vue'

export default {
  components: { InputWrapper },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    id: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
   
    errorMessage: {
      type: String,
      default: undefined,
    },
    errored: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: undefined,
    },
    cols: {
      type: Number,
      default: undefined,
    },
    noResize: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'change'],
  data() {
    return {
      textAreaValue: this.value
    }
  }
}
</script>

<style lang="scss" scoped>
.textarea {
  @apply w-full px-2 pt-3 rounded-none;
  @apply appearance-none;
  @apply text-sm text-primary-700 placeholder:text-primary-300;
  outline: none;
  box-shadow: none;
  @apply disabled:bg-primary-100 disabled:cursor-not-allowed;
  @apply min-h-[34px];
  overflow-y: visible;
}
</style>
