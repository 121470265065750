var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{on:{"click":_vm.onClick}},[_c('div',{ref:"wrapperEl",class:{
      'input-wrapper': true,
      'v-unstyled-prefix': _vm.unstyledPrefix,
      'v-unstyled-suffix': _vm.unstyledSuffix,
      'v-errored': _vm.errored,
      's-large': _vm.large,
      active: _vm.active,
      disabled: _vm.disabled,
    }},[(_vm.$slots.prefix)?_c('span',{staticClass:"input-wrapper-prefix"},[_vm._t("prefix")],2):_vm._e(),(_vm.$slots.suffix)?_c('span',{staticClass:"input-wrapper-suffix"},[_vm._t("suffix")],2):_vm._e(),_c('div',{staticClass:"input-wrapper-content"},[(_vm.label)?_c('span',{staticClass:"input-wrapper-label",on:{"click":_vm.onLabelClick}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('span',{staticClass:"input-wrapper-slot"},[_vm._t("default")],2)])]),(_vm.errorMessage)?_c('span',{staticClass:"input-wrapper-error",attrs:{"role":"alert"}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }