var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{class:{
    'analytics-card': true,
    'l-horizontal': _vm.layout === 'horizontal',
    'l-vertical': _vm.layout === 'vertical',
    'l-empty': _vm.layout === 'empty',
    'l-chart-start': _vm.chartPosition === 'start',
    'l-chart-end': _vm.chartPosition === 'end',
    'v-has-menu': !!_vm.$slots['menu-items'],
  },attrs:{"to":_vm.to,"customClass":_vm.customClass,"customStyle":_vm.customStyle}},[_c('Loader',{attrs:{"show":_vm.showLoader}}),_c('div',{staticClass:"analytics-card-content"},[_c('Txt',{staticClass:"analytics-card-title",attrs:{"weight":"bold","no-margin":""}},[(_vm.title)?_c('span',[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.$slots.title)?_vm._t("title"):_vm._e()],2),_c('div',{staticClass:"analytics-card-data"},[(_vm.description)?_c('Txt',{staticClass:"analytics-card-description",attrs:{"extra-small":""}},[_vm._v(_vm._s(_vm.description))]):_vm._e(),_c('div',{staticClass:"flex items-center"},[(_vm.dotColor)?_c('div',{staticClass:"dot",style:({ backgroundColor: _vm.dotColor })}):_vm._e(),(_vm.value)?_c('Txt',{staticClass:"analytics-card-value",attrs:{"no-margin":""}},[_vm._v(_vm._s(_vm.value))]):_vm._e()],1),(_vm.$slots.sub)?_c('div',{staticClass:"analytics-card-sub"},[_vm._t("sub")],2):_vm._e()],1),(_vm.$slots.chart)?_c('div',{staticClass:"analytics-card-chart",style:({ minHeight: _vm.chartHeight ? `${_vm.chartHeight}px` : undefined, minWidth: _vm.chartWidth? `${_vm.chartWidth}px` : undefined })},[_vm._t("chart")],2):_vm._e(),(_vm.$slots['menu-items'])?_c('div',{staticClass:"analytics-card-menu"},[_c('Menu',{scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('Button',{attrs:{"small":"","variant":"ghost","aria-label":_vm.$t('General.Actions')}},[_c('Icon',{staticClass:"text-violet-700/50",attrs:{"name":"more","size":20}})],1)]},proxy:true}],null,false,4142579004)},[_vm._t("menu-items")],2)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }