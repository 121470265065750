<template>
  <transition appear name='fade' >
    <div v-if="display" class="white-overlay">
      <div class="loader-wrapper">
        <div class="hidden-loader" role="status" aria-live="assertive">{{ $t('General.Loading') }}</div>
        <div class="loader" aria-hidden="true"/>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return {
      display: false,
      timer: null
    }
  },
  watch: {
      $props: {
          handler() {
            if (!this.show) {
              this.timer = setTimeout(() => {
                this.display = this.show
                this.clearTimer()
              }, 50)
            } else {
              if (this.timer) this.clearTimer()
              this.display = this.show
            }
          },
          deep: true,
          immediate: true
      }
  },
  methods: {
    clearTimer(){
      clearTimeout(this.timer)
      this.timer = null
    }
  }
}
</script>

<style scoped lang="scss">
.white-overlay {
  background-color: rgba(255, 255, 255, 0.5);
  @apply fixed w-full h-full z-[9999];
  @apply transition-opacity duration-300;
}
.fade-enter, .fade-leave-to {
  @apply opacity-0;
}
.fade-enter-to, .fade-leave {
  @apply opacity-100;
}
.loader-wrapper {
  @apply relative m-auto w-[65px] top-[45%];
}
.loader {
  @apply border-solid border-8 border-gray-300 rounded-full;
  @apply border-t-8 border-t-violet-700;
  @apply w-16 h-16;
  animation: spin 1s linear infinite;
}
.hidden-loader {
  @apply sr-only;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>