<template>
  <Card id="remediationWidget" class="max-h-xs !block" tabindex="0" footerCustomClass="!p-0 !border-transparent">
    <div class="flex flex-row">
      <Txt as="h4" no-margin class="grow !leading-[40px] ellipsable-text">
        {{ title }}
      </Txt>
      <Button v-if="statusRunning" class="float-right" disabled>
        {{ label | uppercase }}
      </Button>
      <Button v-else class="float-right" tabindex="0" :aria-label="label" :disabled="disableRemediation" @click="remediate">
        {{ label }}
      </Button>
    </div>

    <div class="mx-2 mt-4 flex grid-cols-2 gap-1 items-center" align-v="center" tabindex="-1">
      <div>

        <!-- Weak Remediation -->
        <div v-if="type == REM_WEAK" class="chart-wrapper">
          <CgBarChart ref="chart_3" :chart-data="chartData" :chart-options="options" :height="340"></CgBarChart>
          <div class="x-axis bar-chart"></div>
        </div>

        <!-- Worsened Remediation -->
        <div v-if="type == REM_WORSEN && chartData" class="chart-wrapper">
          <PieChart
            :datasets="chartData.datasets"
            :labels="chartData.labels"
            :chart-options="options"
            :cutout-percentage="0"
          />
        </div>

        <!-- Top Clickers -->
        <div v-if="type == REM_CLICKERS" class="chart-wrapper">
          <table class="table table-bordered table-topclicker">
            <caption class="sr-only">{{title}}</caption>
            <thead> 
              <tr>
                <th class="th-topclicker"> 
                  <font-awesome-icon :icon="['fas', 'arrow-down-short-wide']" class="mx-1"/>
                  <span style="font-weight: 60;">{{ $t("Remediation.ClickRate") }}</span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="i in (topclick.data[0])" :key="i" :style="'background-color:' + topclick.backgroundColors[0]">
                <td v-if="i<(topclick.data[0])"></td>
                <td v-if="i===(topclick.data[0])" :style="'border-bottom: 0.5px solid ' + topclick.colorSeparator"></td>
              </tr>
              <tr v-for="j in (topclick.data[1])" :key="j+10" :style="'background-color:' + topclick.backgroundColors[1]">
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Remediation Light -->
        <div v-if="type==REM_LIGHT" class="chart-wrapper custom">
          <div class="remediation-wrapper">
            <div class="remediation-light-circle">
              <div class="remediation-icon-wrapper user">
                <font-awesome-icon class="remediation-icon user" :icon="['fas', 'users']" />
              </div>
              <div class="remediation-icon-wrapper other">
                <font-awesome-icon class="remediation-icon calendar" :icon="['fas', 'calendar']" />
                <font-awesome-icon class="remediation-icon email" :icon="['fas', 'envelope-open-text']" />
              </div>
            </div>
          </div>
        </div>

        <!--Adaptive Remediation -->
        <div v-if="type==REM_ADAPTIVE && $profile.hasService(SERVICE_TYPE_REM)" class="chart-wrapper">
          <div class="remediation-wrapper flex justify-center mb-4">
            <font-awesome-icon class="remediation-single-icon" :icon="['fas', 'chalkboard-user']" />
          </div>
        </div>

        <!-- QR Code Remediation -->
        <div v-if="type==REM_QRCODE" class="chart-wrapper">
          <div class="remediation-wrapper flex justify-center mb-4">
            <font-awesome-icon class="remediation-single-icon" :icon="['fas', 'qrcode']" />
          </div>
        </div>

        <!-- USB Remediation -->
        <div v-if="type==REM_USB" class="chart-wrapper">
          <div class="remediation-wrapper flex justify-center mb-4">
            <font-awesome-icon class="remediation-single-icon" :icon="['fab', 'usb']" />
          </div>
        </div>
      </div>

      <div>
        <Txt small>
          {{ $t('Remediation.Text_' + type, { param: type == REM_CLICKERS ? data.dataset[1] * 100 + '%' : data.dataset[1] }) }}
        </Txt>
      </div>
    </div>

    <template #footer> 
      <div class="p-3 absolute bottom-0 right-0 left-0" :tabindex="(sendingProgress && type==REM_LIGHT || statusRunning) ? 0 : -1">
        <Txt v-if="sendingProgress && type==REM_LIGHT" small right weight="bold">{{ sendingProgress }}</Txt>
        <Txt v-if="statusRunning" small as="em">{{ $t('Remediation.PlannedEnd', { param: plannedEnd }) }}</Txt>
      </div>
    </template>
  </Card>
</template>

<script>
import colors from '@/utils/colors'
import { Campaign, ServiceType } from '@/common/constants.js'

export default {
  props: {
    data: Object,
    type: Number
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [],
      },
      options: {},
      map: [],
      topclick: [],
      total: 0,
      fraction: 0,
      REM_WEAK: null,
      REM_WORSEN: null,
      REM_CLICKERS: null,
      REM_LIGHT: null,
      REM_ADAPTIVE: null,
      REM_QRCODE: null,
      REM_USB: null,
      SERVICE_TYPE: null
    }
  },
  created() {
    this.REM_WEAK = Campaign.Type.RemediationWeak;
    this.REM_WORSEN = Campaign.Type.RemediationWorsen;
    this.REM_CLICKERS = Campaign.Type.RemediationClickers;
    this.REM_LIGHT = Campaign.Type.RemediationLight;
    this.REM_ADAPTIVE = Campaign.Type.RemediationAdaptive;
    this.REM_QRCODE = Campaign.Type.RemediationQRCode;
    this.REM_USB = Campaign.Type.RemediationUSB;

    this.SERVICE_TYPE_REM = ServiceType.Remediation;
    
    if(this.type == this.REM_CLICKERS) {
      this.setTopClickers();
    }
  },
  computed: {
    disableRemediation() {
      if(!this.$checkPermission('phishing-approve-campaign')) return true;
      else if([this.REM_LIGHT, this.REM_QRCODE, this.REM_USB].includes(this.type)) return false;
      else return this.data && this.data.targets && this.data.targets.length == 0;
    },
    title() {
      if([this.REM_LIGHT, this.REM_QRCODE, this.REM_ADAPTIVE, this.REM_USB].includes(this.type)) return this.$t("Remediation." + this.type);

      let param = this.data && this.type == this.REM_CLICKERS? this.data.dataset[1]*100 + "%" : this.data.dataset[1];
      return this.$t("Remediation." + this.type, { param });
    },
    statusRunning() {
      return this.data && this.data.status ? this.data.status > Campaign.Status.PendingApproval && this.data.status < Campaign.Status.Completed : null;
    },
    label() {
      return this.data && this.data.status > Campaign.Status.PendingApproval && this.data.status < Campaign.Status.Completed ? 
        this.$t("Remediation.Running") : this.$t("Remediation.Initialize");
    },
    plannedEnd() {
      return this.data && this.data.planned_end? 
        this.$dateTime.fromFormat(this.data.planned_end, 'yyyy-MM-dd HH:mm:ss', { zone: 'utc', locale: this.$i18n.locale }).toRelative() : "";
        // this.$dateTime.fromISO(this.$dateTime.fromFormat(this.data.planned_end, 'yyyy-MM-dd HH:mm:ss', { zone: 'utc' }).toISO(), { locale: this.$i18n.locale }).toRelative() : "";
    },
    sendingProgress() {
      if([Campaign.Status.Starting, Campaign.Status.Running].includes(this.data.status) && this.data.type == this.REM_LIGHT) {
        let sent = this.data && this.data.dataset[1] || null;
        let toSend = this.data && this.data.dataset[0] || null;
        return this.$t("Remediation.MailSendingProgress", { sent: sent, toSend: toSend });
      } else return null;
    }
  },
  mounted() {
    this.refresh_ui();
  },
  methods: {
    refresh_ui() {
      if([this.REM_LIGHT, this.REM_QRCODE, this.REM_TRAINING, this.REM_USB].includes(this.type)) return;
      this.setData();
      this.setOptions();

      this.$forceUpdate();
    },
    remediate() {
      this.$emit('remediate', this.type);
    },
    setTopClickers() { 
      this.topclick = { 
        backgroundColors: [colors['soft-blue'][300], colors.red[300]],
        data: [10-Math.trunc(this.data.dataset[1]*10), Math.trunc(this.data.dataset[1]*10)],
        colorSeparator: colors.primary[700],
        color: colors.primary[300]
      }
    },
    setData() {
      this.total = this.data.dataset[0] - this.data.dataset[1];
      this.fraction = this.data.dataset[1];

      switch(this.type) {
        case this.REM_WEAK:
          this.chartData = {
            labels: [''],
            datasets: [
              {
                data: [this.total],
                label: this.$t('Remediation.Charts.TotalUsers'),
                backgroundColor: colors['soft-blue'][300],
                borderColor: colors.primary[300],
                borderWidth: 1
              },
              {
                data: [this.fraction],
                label: this.$t('Remediation.Charts.WeakUsers'),
                backgroundColor: colors.red[300],
                borderColor: colors.red[300],
                borderWidth: 1
              }
            ]
          }
          break;
        case this.REM_WORSEN:
          this.chartData = {
            labels: [''],
            datasets: [
              {
                data: [this.total, this.fraction],
                backgroundColor: [colors['soft-blue'][300], colors.red[300]],
                borderColor: [colors.primary[300], colors.red[500]],
                borderWidth: 1,
              }
            ]
          }
          break;
        default:
          return;
      }
    },
    setOptions() {
      switch (this.type) {
        case this.REM_WEAK:
          this.options = {
            layout: {
              padding: {
                left: -20
              }
            },
            scales: {
              x: {
                grid: {
                  display: false
                },
                stacked: true,
                ticks: {
                  callback: function () {
                    return '';
                  }
                }
              },
              y: {
                display: false,
                stacked: true,
                grid: {
                  display: false
                },
                ticks: {
                  display: false
                }
              }
            },
            legend: {
              display: false
            },
            responsive: true,
            maintainAspectRatio: true,
            animation: {
              animateRotate: true,
              animateScale: true,
            },
            tooltips: {
              enabled: false
            },
            plugins: {
              legend: {
                display: false
              },
              tooltips: {
                enabled: false
              },
              datalabels: {
                formatter: (_value) => {
                  return _value;
                },
                color: colors.primary[300],
                textAlign: "center",
                font: {
                  size: 12
                }
              },
              labels: {
                fontSize: 0
              }
            }
          }
          break;
        case this.REM_WORSEN:
          this.options = {
            layout: {
              padding: {
                bottom: 30,
              },
            },
            legend: {
              display: false
            },
            responsive: true,
            maintainAspectRatio: true,
            animation: {
              animateRotate: true,
              animateScale: true,
            },
            tooltips: {
              enabled: false
            },
            cutoutPercentage: 0,
            plugins: {
              datalabels: {
                formatter: (_value) => {
                  const sum = this.data.dataset[0];
                  const percentage = (100 * _value / sum).toFixed(1);
                  return percentage > 0 && !isNaN(percentage) ? percentage + "%" : "";
                },
                display: (context) => {
                  const data = context.dataset.data;

                  // Hide labels in PieChart when the value to be shown is too little for the slice to contain
                  const { dataIndex } = context;
                  const sum = this.data.dataset[0];
                  let calculatedData = data.slice();

                  const percentage = (100 * calculatedData[dataIndex] / sum).toFixed(1);

                  // Empiric value choosen to display the value plus the "%" character in the basic PieChart
                  return percentage >= 6;	
                },
                color: colors.primary[300],
                font: {
                  size: 12
                }
              }
            }
          }
          break;
        default:
          return;
      }
    }
  }
}
</script>

<style lang="scss">
#remediationWidget {
  min-height: 310px;
  .remediation-title {
    font-size: 18px; 
    font-weight: bold; 
    vertical-align: sub;
  }
  .remediation-status {
    float: right; 
    line-height: 28px;
  }
  .chart-wrapper {
    margin-right: 0.5rem;
    height: 200px !important;
    width: 200px !important;
    &.custom {
      height: 170px !important;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 990px) {
    .chart-wrapper {
      margin-right: 0.2rem;
      height: 150px !important;
      width: 150px !important;
      &.custom {
        height: 130px !important;
      }
    }
  }
  .x-axis {
    background: rgb(222, 226, 230);
    height: 2px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    &:before,
    &:after {
      content: "";
      background: rgb(222, 226, 230);
      position: absolute;
      height: 2px;
      width: 5px;
    }
    &:before {
      bottom: -1px;
      right: -3px;
      transform: rotate(-45deg);
    }
    &:after {
      top: -1px;
      right: -3px;
      transform: rotate(45deg);
    }
  }
  .bar-chart {
    top: -29px;
  }
  .table-topclicker {
    top: 0px;
    width: 80%;
    margin: 0 auto;
    height: 90%;
  }
  .th-topclicker {
    background-color: rgba(221, 221, 221, 0.72);
    font-size: 12px;
    padding: 2px 0 0 5px;
  }
  .table-topclicker td {
    border-bottom: 1px solid  rgba(221, 221, 221, 0.72);
  }
  .ellipsable-text {
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
  }
  .remediation-wrapper {
    position: relative;
    margin: 0 auto;
  }
  .remediation-single-icon {
    height: 120px;
    width: 120px;
    margin: 30px auto 0;
  }
  .remediation-light-circle {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    border: 4px solid lightgray;
    margin: 20px auto 0;
  }
  .remediation-icon-wrapper {
    height: 40px;
    width: 40px;
    &.user {
      margin: -18px auto 0;
    }
    &.other {
      margin-top: 36px;
      display: inline-flex;
      justify-content: space-between;
      width: 150px;
      margin-left: -22px;
    }
  }
  .remediation-icon {
    background: white;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    &.user {
      padding: 2px;
    }
    &.calendar, &.email {
      margin: 2px;
      padding: 4px;
      border-radius: 10%;
    }
  }
}
</style>
