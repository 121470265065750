var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"sm:block overflow-x-visible"},[_c('div',{staticClass:"border-b border-gray-200 scroll-multiple-tabs"},[_c('nav',{staticClass:"-mb-px flex scroll-multiple-tabs",attrs:{"aria-label":"Tabs","role":"tablist"}},_vm._l((_vm.tabNav),function(tab){return _c('a',{key:tab.name,class:[
            'mr-6',
            'display' in tab && !tab.display? 'hidden':'',
            tab.isActive
              ? 'border-gray-500 text-gray-600'
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
            'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer',
            tab.disabled
              ? 'pointer-events-none cursor-default text-gray-400' : ''
          ],attrs:{"href":tab.href,"tabindex":"0","aria-label":tab.name,"role":"tab","aria-selected":tab.isActive},on:{"click":function($event){return _vm.selectTab(tab)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.selectTab(tab)}}},[_vm._v(" "+_vm._s(tab.name)+" ")])}),0)])]),_c('div',{staticClass:"flex flex-col grow overflow-auto"},[_vm._l((_vm.tabNav),function(tab,index){return [(tab.isActive === true)?_c('div',{key:index,staticClass:"grow h-full",attrs:{"role":"tabpanel"}},[_vm._t(`tabContent-${index + 1}`)],2):_vm._e()]})],2),_c('div',{class:{
    'tabs-footer': true,
    'a-left': _vm.footerAlignment === 'left',
    'a-center': _vm.footerAlignment === 'center',
    'a-right': _vm.footerAlignment === 'right',
  }},[_vm._t("tabFooter")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }