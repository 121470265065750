import { apiClient } from '@/plugins/api'

export default {
  getAllLanguages() {
    return apiClient.get(`sysprop/languages`)
  },

  getLanguages() {
    return apiClient.get(`localization/lang/`)
  },
  getLanguage(langId) {
    return apiClient.get(`localization/lang/${langId}`)
  },
  createLanguage(payload) {
    return apiClient.post(`localization/lang/`, payload)
  },

  getCompanyLangList(companyId) {
    return apiClient.get(`localization/company/${companyId}/lang`)
  },
  getCompanyDefaultLang(companyId) {
    return apiClient.get(`localization/company/${companyId}/lang/default`)
  },
  getCompanyLang(companyId, langCode) {
    return apiClient.get(`localization/company/${companyId}/lang/${langCode}`)
  },
  addCompanyLang(companyId, langCodeArr, defaultLang) {
    let cLangs = []
    langCodeArr.forEach((lang) => {
      cLangs.push({ lang_code: lang, is_default: lang === defaultLang })
    })
    return apiClient.post(`localization/company/${companyId}/lang/`, { company_langs: cLangs })
  },
  deleteCompanyLang(companyId, langCode) {
    return apiClient.delete(`localization/company/${companyId}/lang/${langCode}`)
  },
  createCompanyTranslation(companyId, payload) {
    return apiClient.post(`localization/company/${companyId}/lang/`, payload)
  },
  createCompanyTranslationByCode(companyId, langCode, payload) {
    return apiClient.post(`localization/company/${companyId}/lang/${langCode}`, payload)
  },
  setDefaultCompanyLang(companyId, langCode) {
    return apiClient.put(`localization/company/${companyId}/lang/${langCode}/default`)
  },
  //companyTranslation
  getCompanyTranslations(companyId, langCode) {
    let lang = langCode ? `&lang_code=` + langCode : ``
    return apiClient.get(`localization/company/${companyId}/translations${lang}`)
  },
  getCompanyTranslation(companyId, key, langCode) {
    let params = {};
    if (langCode) { params.lang_code = langCode; }
    if (key) { params.key = key; }
    return apiClient.get(`localization/company/${companyId}/translations`, { params });
  },
  createCompanyTranslations(companyId, payload) {
    return apiClient.post(`localization/company/${companyId}/translations`, payload)
  },
  updateCompanyTranslations(companyId, payload) {
    return apiClient.put(`localization/company/${companyId}/translations`, payload)
  },
  getAllLangTranslationsByUuids(uuids, companyId = null) {
    const params = {}
    if(companyId) params.companyId = companyId
    return apiClient.post(`localization/translations/all-langs`, uuids, {params : params })
  },
  getAllTranslation(translationUuid) {
    return apiClient.get(`localization/translations/${translationUuid}`)
  },
  deleteAllLangTranslation(translationUuid, langCode) {
    return apiClient.delete(`localization/translations/${translationUuid}/lang/${langCode}`)
  },
  searchTranslation(payload) {
    return apiClient.post(`localization/translations/search`, payload)
  },


  getTranslations(queryParams) {
    return apiClient.get(`localization/translations`, { params: queryParams })
  },
  createTranslation(payload) {
    return apiClient.post(`localization/translations`, payload)
  },
  updateTranslation(payload, companyId = null) {
    let companyQParam = companyId ? `/company/${companyId}` : ''
    return apiClient.put( `localization${companyQParam}/translations`, payload)
  },
  getTranslation(uuid) {
    return apiClient.get(`localization/translations/${uuid}`)
  },
  deleteTranslationLanguage(uuid, lang, params) {
    return apiClient.delete(`localization/translations/${uuid}/lang/${lang}`,{ params: params || {} })
  }
}
