/**
 * Deep merge 2 objects
 *
 * @param {object} target Initial object
 * @param {object} source Object to merge with target
 * @returns {object} Deep merged object
 */
export const mergeObjects = (target, source) => {
  const mergedObject = { ...target }

  for (const key in source) {
    if (key in source) {
      if (typeof mergedObject[key] === 'object' && typeof source[key] === 'object') {
        mergedObject[key] = mergeObjects(mergedObject[key], source[key])
        continue
      }
      mergedObject[key] = source[key]
    }
  }

  return mergedObject
}

export const copyToClipboard = (text)=>{
  if (!navigator.clipboard) {
        fallbackCopyValue(text);
        return;
    }
    navigator.clipboard.writeText(text).then(()=> {
        //console.error('Value copied', text);
    }, function(err) {
        console.error('Async: Could not copy text: ', err);
    });
}

const fallbackCopyValue = (text) =>  {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        if(successful){
          console.log('valore copiato correttamente')
        }
    } catch (err) {
        console.error('unable to copy', err);
    }
    document.body.removeChild(textArea);
}
