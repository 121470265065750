var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{class:{
    status: true,
    'v-emoji': _vm.variant === 'emoji',
    'c-success': _vm.type === 'success',
    'c-warning': _vm.type === 'warning',
    'c-info': _vm.type === 'info',
    'c-danger': _vm.type === 'danger',
  }},[(_vm.variant === 'emoji')?_c('span',[_vm._v(_vm._s(_vm.emojiConfig[_vm.type]))]):_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }