<template>
  <div
    :class="{
      toggle: true,
      disabled,
      checked: isChecked,
    }"
  >
    <button ref="toggleBtn" class="toggle-switch" :aria-checked="String(isChecked)" role="switch" type="button" tabindex="0" :disabled="disabled" @click="toggleChecked">
      <span aria-hidden="true" class="toggle-knob" />
    </button>

    <span v-if="$slots.default" class="toggle-text" @click="toggleChecked">
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Boolean, Number],
      default: undefined,
    },
    checkedValue: {
      type: [Boolean, Number],
      default: undefined,
    },
    uncheckedValue: {
      type: [Boolean, Number],
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: undefined,
    },
  },
  emits: ['input'],
  computed: {
    isChecked() {
      if (this.checkedValue === undefined && this.uncheckedValue === undefined) return Boolean(this.value)

      return (this.checkedValue !== undefined)? this.checkedValue === this.value : this.uncheckedValue === this.value
    }
  },
  methods: {
    toggleChecked() {
      if (this.disabled) return

      if (this.checkedValue === undefined && this.uncheckedValue === undefined) this.$emit('input', !this.value)
      else if (this.value === this.checkedValue) this.$emit('input', this.uncheckedValue)
      else this.$emit('input', this.checkedValue)
    },
  },
}
</script>

<style lang="scss" scoped>
.toggle {
  @apply flex;

  &.disabled,
  &.disabled > * {
    @apply cursor-not-allowed;
  }
}

.toggle-switch {
  @apply relative shrink-0;
  @apply w-10 h-5 rounded-full;
  @apply bg-primary-300;

  .checked & {
    @apply bg-violet-700 disabled:bg-primary-300;
  }
}

.toggle-knob {
  @apply block absolute left-0.5 top-0.5;
  @apply w-4 h-4 rounded-full;
  @apply bg-white;
  @apply transition-transform duration-200 ease-in-out;
  @apply pointer-events-none;

  .checked & {
    @apply translate-x-5;
  }

  .disabled & {
    @apply bg-primary-100;
  }
}

.toggle-text {
  @apply flex-grow;
  @apply ml-2;
  @apply cursor-default;
  @apply text-sm;
  line-height: inherit;
}
</style>
