var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{class:{
    'progress-card': true,
    's-large': _vm.large,
    'c-primary': _vm.color === 'primary',
    'c-orange': _vm.color === 'orange',
    'c-blue': _vm.color === 'blue',
    'c-violet': _vm.color === 'violet',
    'c-green': _vm.color === 'green',
    'c-red': _vm.color === 'red',
    'c-yellow': _vm.color === 'yellow',
    'c-inactive': _vm.color === 'inactive',
    'c-on-track': _vm.color === 'on-track',
    'c-regular': _vm.color === 'regular',
    'c-not-regular': _vm.color === 'not-regular',
    'c-bw': _vm.color === 'bw',
  },attrs:{"disabled":_vm.disabled,"to":_vm.to}},[(_vm.unlicensed && _vm.tooltip)?_c('div',{staticClass:"unlicensed"},[_c('Tooltip',{attrs:{"placement":"left"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(_vm._s(_vm.tooltip))]},proxy:true}],null,false,2498700492)},[_c('Icon',{attrs:{"name":"info","size":30}})],1)],1):_vm._e(),_c('div',{staticClass:"progress-card-content",class:{ 'overlay-background': _vm.unlicensed }},[_c('div',{staticClass:"progress-card-icon"},[_c('Icon',{attrs:{"name":_vm.icon}})],1),_c('div',{staticClass:"progress-card-details"},[_c('Txt',{staticClass:"progress-card-value",attrs:{"weight":"bold"}},[_vm._v(_vm._s(_vm.value))]),_c('Txt',{staticClass:"progress-card-title",attrs:{"extra-small":"","weight":_vm.readMoreText ? 'bold' : ''}},[_vm._v(_vm._s(_vm.title))]),(_vm.readMoreText)?_c('div',[_c('Txt',{ref:"description",class:{'line-clamp-2': !_vm.readMore, 'text-xs': true},attrs:{"extra-small":""}},[_vm._v(_vm._s(_vm.readMoreText))]),(_vm.isTextClamped)?_c('div',{staticClass:"mt-1.5"},[_c('Button',{attrs:{"variant":"outline","color":"light","extra-small":""},on:{"click":_vm.toggleText}},[_vm._v(_vm._s(_vm.readMoreButtonText))])],1):_vm._e()],1):_vm._e()],1),(_vm.link && _vm.link.to)?_c('Button',{staticClass:"progress-card-value",attrs:{"variant":"unstyled","aria-label":_vm.ariaLabel},on:{"click":function($event){!_vm.value==0? _vm.$emit('click') : null}}},[(!_vm.link.icon)?_c('Txt',{staticClass:"font-bold text-primary-700 text-xs"},[_vm._v(_vm._s(_vm.link.text))]):_vm._e(),(_vm.link.icon && _vm.value!=0)?_c('Icon',{attrs:{"name":_vm.link.icon,"size":"16"}}):_vm._e()],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }