import { apiClient } from '@/plugins/api'

export default {
  sendMail(payload) {
    return apiClient.post('/email', payload)
  },
  testSmtpMailConfig(companyId, payload){
    return apiClient.post(`/email/company/${companyId}/test`, payload)
  }
}
