import { apiClient } from '@/plugins/api'

export default {
  getLandingPages(companyId, scope, filter) {
    let opt = {
      params: {
        companyId: companyId,
        scope: scope,
        searchText: filter
      }
    }
    return apiClient.get(`phishing/landingpage`, opt)
  },
  createLandingPage(companyId, body, name, language) {
    let data = { body: body, name: name, language: language, companyId: companyId }
    return apiClient.post(`phishing/landingpage`, data)
  },
  copyLandingPage(companyId, landingPageId, body) {
    return companyId?
      apiClient.post(`phishing/company/${companyId}/landingpage/${landingPageId}/copy`, body) :
      apiClient.post(`phishing/landingpage/${landingPageId}/copy`, body)
  },
  getLandingPageContent(companyId, landingPageId) {
    return companyId?
      apiClient.get(`phishing/company/${companyId}/landingpage/${landingPageId}`) : 
      apiClient.get(`phishing/landingpage/${landingPageId}`)
  },
  deleteLandingPage(companyId, landingPageId) {
    return companyId?
      apiClient.delete(`phishing/company/${companyId}/landingpage/${landingPageId}`) :
      apiClient.delete(`phishing/landingpage/${landingPageId}`)
  },
  deleteLandingPageContent(companyId, landingPageId, language) {
    return companyId?
      apiClient.delete(`phishing/company/${companyId}/landingpage/${landingPageId}/${language}`) :
      apiClient.delete(`phishing/landingpage/${landingPageId}/${language}`)
  },
  updateLandingPageInfo(companyId, landingPageId, body) {
    return companyId?
      apiClient.put(`phishing/company/${companyId}/landingpage/${landingPageId}`, body) :
      apiClient.put(`phishing/landingpage/${landingPageId}`, body)
  },
  updateLandingPage(companyId, landingPageId, language, body) {
    return companyId?
      apiClient.put(`phishing/company/${companyId}/landingpage/${landingPageId}/content/${language}`, body) :
      apiClient.put(`phishing/landingpage/${landingPageId}/content/${language}`, body)
  },
  addLandingPageLanguage(companyId, landingPageId, language, body) {
    return companyId?
      apiClient.put(`phishing/company/${companyId}/landingpage/${landingPageId}/${language}`, body) :
      apiClient.put(`phishing/landingpage/${landingPageId}/${language}`, body)
  },
  assignLandingPage(companyId, landingPageId, destCompanyId, assign) {
    let payload = {
      companyId: destCompanyId,
      assign
    }
    return companyId?
      apiClient.put(`phishing/company/${companyId}/landingpage/${landingPageId}/assign`, payload) :
      apiClient.put(`phishing/landingpage/${landingPageId}/assign`, payload)
  }
}
