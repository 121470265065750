var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"datepickerEl",class:{
    datepicker: true,
    disabled: _vm.disabled,
  }},[_c('InputWrapper',{attrs:{"errored":_vm.errored,"error-message":_vm.errorMessage,"label":_vm.label,"disabled":_vm.disabled,"active":_vm.open,"unstyled-prefix":"","unstyled-suffix":"","tabindex":"0"},on:{"click":_vm.toggleOpen,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.toggleOpen.apply(null, arguments)}},scopedSlots:_vm._u([(_vm.$slots.prefix)?{key:"prefix",fn:function(){return [_vm._t("prefix",null,{"tabindex":"-1"})]},proxy:true}:null,{key:"suffix",fn:function(){return [_c('Icon',{staticClass:"text-violet-700",attrs:{"name":"calendar"}})]},proxy:true}],null,true)},[_c('input',{attrs:{"id":_vm.id,"name":_vm.name,"disabled":_vm.disabled,"type":"date"},domProps:{"value":_vm.value},on:{"input":_vm.updateDate,"click":_vm.stopDefaultCalendar}})]),_c('div',{ref:"pickerMenuEl",class:{
      'datepicker-menu': true,
      open: _vm.open,
    }},[_c('Calendar',{attrs:{"date":_vm.value,"events":[],"compact":"","background-color":"light"},on:{"date-click":_vm.selectDate}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }