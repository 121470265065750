var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'table-wrapper': true,
    'v-adjust-padding': _vm.adjustPadding,
    'v-selection': _vm.selectedRecords && _vm.selectedRecords.length > 0,
  }},[_c('table',{class:{
      table: true,
      'v-alternate-white': _vm.alternateColor === 'white',
      'v-alternate-light': _vm.alternateColor === 'light',
      'size-xs': !_vm.fontSize || _vm.fontSize === 'xs',
      'size-sm': _vm.fontSize === 'sm',
      'size-md': _vm.fontSize === 'md'
    }},[_c('caption',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.name))]),(_vm.$slots.head)?_c('thead',[_c('tr',[(_vm.selectable && !_vm.singleSelection)?_c('TableCell',{staticClass:"table-selection-cell",attrs:{"head":""}},[_c('Checkbox',{attrs:{"value":_vm.selectedRecords && _vm.selectedRecords.length > 0},on:{"input":_vm.toggleSelectAll}})],1):_vm._e(),_vm._t("head")],2)]):_vm._e(),_c('tbody',[_vm._t("default")],2)]),_c('div',{staticClass:"table-selection-toolbar"},[(_vm.selectable && !_vm.singleSelection)?_c('Txt',{staticClass:"-mb-1",attrs:{"extra-small":""}},[_vm._v(_vm._s(_vm.$tc('Components.SelectedElements', _vm.selectedRecords ? _vm.selectedRecords.length : 0)))]):_vm._e(),_c('div',{class:{
        'table-selection-toolbar-actions': true,
        'v-grouped': _vm.groupedActions,
      }},[_vm._t("selection-actions")],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }