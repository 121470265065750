<template>
  <button
      :aria-label="menuStatus"
      :class="{
      'menu-toggle-button': true,
      open,
    }"
      @click="$emit('click')"
  >
    <div class="toggle-icon">
      <span class="toggle-icon-line-top"></span>
      <span class="toggle-icon-line-bottom"></span>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  computed: {
    menuStatus() {
      return this.open ? this.$t('Wcag.MenuClose') : this.$t('Wcag.MenuExpand')
    }
  },
};
</script>

<style lang="scss" scoped>
.menu-toggle-button {
  @apply flex items-center justify-center;
}

.toggle-icon {
  @apply relative w-[30px] h-[30px];
}

.toggle-icon-line-top,
.toggle-icon-line-bottom {
  @apply absolute left-0 top-0 w-full h-full;
  @apply transition duration-300 ease-in-out;

  &::after {
    content: '';
    //@apply block absolute bg-white;
    @apply block absolute bg-custom-contrastPrimary;
    @apply w-[18px] h-0.5 rounded-[1px] left-1.5;
  }
}

.toggle-icon-line-top {
  &::after {
    @apply top-2.5;
  }

  .open & {
    @apply rotate-45;
    &::after {
      @apply top-3 left-1.5;
    }
  }
}

.toggle-icon-line-bottom {
  &::after {
    @apply bottom-2.5;
  }

  .open & {
    @apply -rotate-45;
    &::after {
      @apply top-3.5 left-2;
    }
  }
}
</style>
