var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{ref:"pageNavEl",class:{
    'page-nav': true,
    'v-scroll': _vm.scroll,
    'v-left-dimming': _vm.showLeftDimming,
    'v-right-dimming': _vm.showRightDimming,
    'd-light': _vm.dimmingBackground === 'light',
  }},[_c('ul',{ref:"menuEl",staticClass:"page-nav-menu"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }