var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'circular-progress': true,
    'c-violet': _vm.color === 'violet',
    'c-orange': _vm.color === 'orange',
    'c-acid': _vm.color === 'acid',
    'c-green': _vm.color === 'green',
    'c-blue': _vm.color === 'blue',
    'c-red': _vm.color === 'red',
  },style:({
    width: _vm.size ? `${_vm.size}px` : undefined,
    height: _vm.size ? `${_vm.size}px` : undefined,
  })},[_c('svg',{staticClass:"circular-progress-indicator",style:({
      width: `${_vm.size}px`,
      height: `${_vm.size}px`,
    })},[_c('g',{attrs:{"transform":`translate(${_vm.radius + _vm.trackWidth / 2}, ${_vm.radius + _vm.trackWidth / 2})`}},[_c('circle',{staticClass:"circular-progress-circle-track",attrs:{"fill":"transparent","stroke-width":_vm.trackWidth,"r":_vm.radius,"cx":"0","cy":"0"}})])]),_c('svg',{staticClass:"circular-progress-indicator",style:({
      width: `${_vm.size}px`,
      height: `${_vm.size}px`,
    })},[_c('circle',{staticClass:"circular-progress-circle-progress",style:({ strokeDashoffset: _vm.strokeDashoffset }),attrs:{"stroke-dasharray":`${_vm.circumference}  ${_vm.circumference}`,"fill":"transparent","stroke":"#333","stroke-width":_vm.trackWidth,"r":_vm.radius,"cx":_vm.radius + _vm.trackWidth / 2,"cy":_vm.radius + _vm.trackWidth / 2}})]),(!_vm.hideContent)?_c('Txt',{staticClass:"circular-progress-text",attrs:{"as":"span","weight":"bold","size":_vm.fontSize}},[(_vm.$scopedSlots.default)?_vm._t("default",null,{"progress":_vm.progress}):_c('span',[_vm._v(_vm._s(_vm.progress)+"%")])],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }