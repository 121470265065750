<template>
  <div
    :class="{
      'page-wrapper': true,
      fit,
    }"
  >
    <div v-if="$slots.navbar" class="page-wrapper-navbar">
      <slot name="navbar" />
    </div>
    <div
      :class="{
        'page-wrapper-main': true,
        '!min-h-screen': true,
        'bg-white': background === 'white',
        'bg-extra-light': background === 'light',
      }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    background: {
      type: String,
      default: 'light', // 'white' | 'light'
    },
    fit: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.page-wrapper {
  @apply flex flex-col lg:flex-row;

  &:not(.fit) {
    @apply w-screen h-full lg:h-screen;
  }
  &.fit {
    @apply w-full h-full;
  }
}

.page-wrapper-main {
  @apply w-full h-full overflow-y-auto;
  @apply flex flex-col lg:flex-row;
}

.page-wrapper-content {
  @apply flex-1;
  @apply order-2;
  @apply p-6 md:p-8 xl:p-11;
  @apply overflow-y-auto overflow-x-hidden;
}

.page-wrapper-content {
  &::-webkit-scrollbar {
    @apply appearance-none;
    // @apply w-3 #{!important};
    width: 0.75rem !important;
    @apply invisible;
  }
  &:hover, &:focus, &:active {
    &::-webkit-scrollbar {
      @apply visible
    }
    &::-webkit-scrollbar-track {
      @apply bg-transparent;
      @apply rounded-full;
      @apply my-5;
    }
    &::-webkit-scrollbar-thumb {
      @apply rounded-full;
      @apply bg-primary-300/50;
    }
    & {
      scrollbar-width: auto;
      scrollbar-color: rgb(131 135 154 / 0.5)  transparent;
      @apply scroll-my-3;
    }
  }
}

.page-wrapper-contextual {
  @apply flex-shrink-0;
  @apply order-1;
  @apply mb-6 md:mb-8 lg:mb-0;
  @apply lg:pt-8 xl:pt-19;

  @screen lg {
    @apply h-full w-[30%];

    &.p-left {
      @apply order-1 max-w-xs;
    }
    &.p-right {
      @apply order-3 max-w-md;
    }
  }

  &.v-padding {
    @apply p-6 lg:p-8;
    @apply lg:pb-0 xl:pb-0;
  }
}
</style>
