var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'page-wrapper-contextual': true,
    'v-padding': _vm.contextualPadding,
    'p-left': _vm.contextualPosition === 'left',
    'p-right': _vm.contextualPosition === 'right',
    'bg-white': _vm.contextualBackground === 'white',
    'bg-extra-light': _vm.contextualBackground === 'light',
  }},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }