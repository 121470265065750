var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[(_vm.dismissCountDown)?_c('div',{staticClass:"white-overlay"},[_c('div',{staticClass:"alert-wrapper"},[_c('div',{class:{
          alert: true,
          'v-success': _vm.type === 'success',
          'v-danger': _vm.type === 'danger',
          'v-info': _vm.type === 'info',
          'v-warning': _vm.type === 'warning'
        },attrs:{"role":"alert"}},[(_vm.iconName)?_c('div',{staticClass:"alert-icon"},[_c('Icon',{style:({
              padding: _vm.iconSize ? '3px' : undefined,
              margin: _vm.iconSize ? '0' : undefined,
            }),attrs:{"name":_vm.iconName,"size":_vm.iconSize}})],1):_vm._e(),_c('div',{staticClass:"alert-content"},[_c('Txt',{staticClass:"alert-title",style:({
              lineHeight: _vm.iconSize ? `${_vm.iconSize + 6}px` : undefined,
            }),attrs:{"as":"h4","type":"none","small":"","weight":"bold","no-margin":""}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.$slots.default)?_c('Txt',{staticClass:"alert-text",attrs:{"small":""}},[_vm._t("default")],2):_vm._e(),(_vm.link)?_c('Link',{staticClass:"alert-link"},[_vm._v(_vm._s(_vm.linkLabel))]):_vm._e()],1)])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }