import {
  Chart as ChartJS,
  LineController,
  Title,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  Filler,
} from 'chart.js'

import colors from './colors'

ChartJS.register(
  LineController,
  Title,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  Filler
)
ChartJS.defaults.font.family = 'Epilogue, sans-sarif'
ChartJS.defaults.borderColor = colors.primary[100]
ChartJS.defaults.backgroundColor = 'transparent'
ChartJS.defaults.color = colors.primary[300]
ChartJS.defaults.responsive = true
ChartJS.defaults.maintainAspectRatio = false
ChartJS.defaults.plugins.title.align = 'start'
ChartJS.defaults.plugins.title.color = colors.primary[700]
ChartJS.defaults.plugins.title.font = {
  size: 14,
  weight: 700,
}
ChartJS.defaults.plugins.legend.position = 'bottom'
ChartJS.defaults.plugins.legend.labels.color = colors.primary[700]
ChartJS.defaults.plugins.legend.labels.usePointStyle = true
ChartJS.defaults.plugins.legend.labels.font = {
  size: 14,
  weight: 700,
}
ChartJS.defaults.plugins.tooltip = {
  ...ChartJS.defaults.plugins.tooltip,
  intersect: false,
  backgroundColor: colors['soft-blue'][700],
  padding: 12,
  font: {
    size: 14,
    weight: 700,
  },
}
ChartJS.defaults.scales.x = {
  grid: {
    drawTicks: false,
  },
}
ChartJS.defaults.scales.y = {
  grid: {
    drawTicks: false,
  },
}

export default ChartJS
