<template>
  <Txt
    class="category-label"
    small
    uppercase
    weight="bold"
    :style="{
      ['--label-color']: color,
    }"
  >
    <router-link v-if="to" :to="to">
      <slot />
    </router-link>
    <span v-else>
      <slot />
    </span>
  </Txt>
</template>

<script>
import Txt from './Txt.vue'

export default {
  components: { Txt },
  props: {
    color: {
      type: String,
      default: undefined,
    },
    to: {
      type: String,
      default: undefined,
    },
  },
}
</script>

<style lang="scss">
.category-label {
  --label-color: ;
  color: var(--label-color);
}
</style>
