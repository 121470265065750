<template>
  <div
    :class="{
      'list-select': true,
      'v-errored': errored,
      disabled,
    }"
  >
    <select :id="id" :value="value" :name="name" :multiple="multiple" />

    <div
      v-for="option in options"
      :key="option.value"
      :class="{
        'list-select-option': true,
        selected: value === option.value || (Array.isArray(value) && value.includes(option.value)),
      }"
      @click="selectValue(option)"
    >
      <span>{{ option.label }}</span>
    </div>
    <span v-if="errorMessage" class="list-select-error">{{ errorMessage }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Array],
      default: undefined,
    },
    options: {
      type: Array, // Array<{ value: string, label: string }>
      default() {
        return []
      },
    },
    id: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    errorMessage: {
      type: String,
      default: undefined,
    },
    errored: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  methods: {
    selectValue(opt) {
      if (this.disabled) return

      if (!this.multiple) {
        this.$emit('input', opt.value)
      } else {
        const values = [...this.value]
        const index = values.indexOf(opt.value)
        if (index >= 0) {
          values.splice(index, 1)
        } else {
          values.push(opt.value)
        }
        this.$emit('input', values)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.list-select {
  @apply relative flex flex-col;
  @apply space-y-4;

  select {
    @apply hidden;
  }
}

.list-select-option {
  @apply flex items-center w-full;
  @apply text-left text-sm;
  @apply px-3.5 py-2;
  @apply cursor-pointer;

  span {
    @apply -mb-1; // font adjustment
  }

  &.selected {
    @apply bg-soft-blue-300;
    @apply text-violet-700;
  }

  .disabled & {
    @apply text-primary-300 cursor-not-allowed;

    &.selected {
      @apply bg-primary-100;
    }
  }

  :not(.disabled) > & {
    @apply hover:bg-soft-blue-300;
  }

  .v-errored &.selected {
    @apply text-red-700 bg-red-100;
  }
}

.list-select-error {
  @apply text-sm text-red-500;
}
</style>
