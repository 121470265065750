<template>
  <transition name="fade">
    <div v-if="displayBanner" class="white-overlay">
      <div class="banner-wrapper">
        <div 
        :class="{
          banner: true,
          'v-success': type === 'success',
          'v-danger': type === 'danger',
          'v-info': type === 'info',
          'v-warning': type === 'warning'
        }">
          <div class="close-wrapper"> 
            <Button class="banner-close" variant="ghost" @click="displayBanner=false">
              <Icon name="close" />
            </Button>
          </div>
          <div class="banner-content">
            <Txt as="h4" type="none" weight="bold" class="banner-title" no-margin :style="{
              lineHeight: textSize || undefined,
            }">
              {{ $t(title) }}
            </Txt>
            <Button v-if="buttonLabel" :color="buttonColor" small @click="$emit('click')">{{ buttonLabel
            }}</Button>
            <Txt v-if="$slots.default" small class="banner-text">
              <slot />
            </Txt>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
  
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String, // 'success' | 'danger' | 'info'
      default: undefined,
    },
    textSize: {
      type: Number,
      default: undefined,
    },
    buttonLabel: {
      type: String,
      default: undefined
    },
    buttonColor: {
      type: String,
      default: undefined
    }
  },
  data(){
    return {
      displayBanner: false
    }
  },
  methods: {
    showBanner() {
      this.displayBanner = true
    }
  }
}
</script>
  
<style lang="scss" scoped>
.banner {
  @apply flex items-start w-full p-4;
  @apply bg-gray-100;
  @apply shadow-md;

  &.v-success {
    @apply bg-green-700;
  }

  &.v-danger {
    @apply bg-red-700;
  }

  &.v-info {
    @apply bg-soft-blue-700;
  }

  &.v-warning {
    @apply bg-yellow-500;
  }
}

.close-wrapper {
 @apply flex items-center justify-end order-last;
 .banner-close {
     @apply w-8 h-8 px-0;
     @apply border-primary-300;
   }
}

.banner-content {
  @apply flex-1 flex flex-wrap;
  @apply mx-2
}

.banner-title {
  @apply flex-1;
  @apply mt-2;
}

.banner-text {
  @apply flex-grow basis-full;
  @apply mt-2;

}

.banner-wrapper {
  @apply w-auto relative;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-62px);
}
</style>
  