<template>
  <span
    :class="{
      tag: true,
      disabled,
    }"
    @click="$emit('click')"
  >
    <Button v-if="showRemove" class="tag-remove" variant="ghost" :disabled="disabled" :aria-label="$t('Wcag.RemoveTag')" @click="$emit('remove')">
      <Icon name="close" />
    </Button>
    <span class="tag-content">
      <slot />
    </span>
  </span>
</template>

<script>
import Button from './Button.vue'
import Icon from './Icon.vue'

export default {
  components: { Button, Icon },
  props: {
    showRemove: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click', 'remove'],
}
</script>

<style lang="scss" scoped>
.tag {
  @apply inline-flex items-center;
  @apply bg-soft-blue-300;
  @apply text-xs;
  @apply h-7 px-2.5 rounded-lg;
  @apply cursor-default;
  @apply transition-colors duration-200 ease-in-out;
  @apply hover:bg-soft-blue-500;

  &.disabled {
    @apply bg-primary-100 text-primary-500/70;
    @apply cursor-not-allowed;
  }
}

.tag-content {
  @apply -mb-1; // font-adjustment
}

.tag-remove {
  @apply mr-0.5;
  @apply -ml-1; // icon adjustment

  .disabled & {
    .icon {
      @apply text-primary-300;
    }
  }
}
</style>
