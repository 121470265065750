var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'list-select': true,
    'v-errored': _vm.errored,
    disabled: _vm.disabled,
  }},[_c('select',{attrs:{"id":_vm.id,"name":_vm.name,"multiple":_vm.multiple},domProps:{"value":_vm.value}}),_vm._l((_vm.options),function(option){return _c('div',{key:option.value,class:{
      'list-select-option': true,
      selected: _vm.value === option.value || (Array.isArray(_vm.value) && _vm.value.includes(option.value)),
    },on:{"click":function($event){return _vm.selectValue(option)}}},[_c('span',[_vm._v(_vm._s(option.label))])])}),(_vm.errorMessage)?_c('span',{staticClass:"list-select-error"},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }