<template>
  <span
    :class="{
      status: true,
      'v-emoji': variant === 'emoji',
      'c-success': type === 'success',
      'c-warning': type === 'warning',
      'c-info': type === 'info',
      'c-danger': type === 'danger',
    }"
  >
    <span v-if="variant === 'emoji'">{{ emojiConfig[type] }}</span>
    <slot v-else />
  </span>
</template>

<script>
export default {
  props: {
    variant: {
      type: String, // 'text' | 'emoji'
      default: 'text',
    },
    type: {
      type: String, // 'success' | 'warning' | 'info' | 'danger'
      default: undefined,
    },
    emojiConfig: {
      type: Object,
      default: () => ({
        success: '😍',
        info: '🙂',
        warning: '😕',
        danger: '😩',
      }),
    },
  },
}
</script>

<style lang="scss" scoped>
.status {
  @apply inline-block;
  @apply text-xs font-bold text-gray-500;
  @apply p-0.5;
  @apply pt-1; // font-adjustment

  &.v-emoji {
    @apply px-4 py-1;
    @apply pt-1.5; // font-adjustment
    @apply text-lg;
  }
}

.c-success {
  @apply text-green-500;

  &.v-emoji {
    @apply bg-green-100;
  }
}

.c-info {
  @apply text-soft-blue-700;

  &.v-emoji {
    @apply bg-soft-blue-100;
  }
}

.c-warning {
  @apply text-accent-500;

  &.v-emoji {
    @apply bg-accent-100;
  }
}

.c-danger {
  @apply text-red-500;

  &.v-emoji {
    @apply bg-red-100;
  }
}
</style>
