<template>
	<div>
		<div class="relative">
			<div class="overlay-text">
				<span class="outline">
					{{ $t('Dashboard.Charts.HideCyberScore') }}
				</span>
			</div>
		</div>
		<div class="overlay-css">
			<AnalyticsCard layout="vertical" :title="$t('Dashboard.Charts.RiskEvolution')" :chart-height="500">
				<template #chart>
					<BarChart
						:datasets="chartData.datasets"
						:labels="chartData.labels"
						show-x-border
						show-y-labels
						show-x-labels
						stacked
						show-legend
						show-x-intra-lines
						show-y-intra-lines
						:chart-options="chartOptions"
					/>
				</template>
			</AnalyticsCard>
		</div>
	</div>
</template>

<script> 
/* eslint-disable */
import colors from "@/components/charts/dashboard/utils/colors";

export default {
	data() {
		return {
			// title: this.$t("Dashboard.Charts.RiskEvolution"),
			myself: "stacked100",
			showChart: false,
			chartData: [],
			chartLabels: [],
			chartOptions: {},
			riskGroup: { Unknown: 0, Serial: 1, Frequent: 2, Rare: 3, Defender: 4 }
		};
	},
	async created() {
		this.setOptions();
		this.chartLabels.push(this.$t('Dashboard.Charts.Defender'), this.$t('Dashboard.Charts.Strong'), this.$t('Dashboard.Charts.Intermediate'), this.$t('Dashboard.Charts.Weak'));
		this.createChart();
	},
	methods: {
		createChart() {
			let mockData = [{
				defenders: 60.0,
				serial_click: 3,
				frequent_click: 7,
				rare_click: 30,
				name: "C-12"
			}, {
				defenders: 55.0,
				serial_click: 2,
				frequent_click: 10,
				rare_click: 33,
				name: "C-11"
			},{
				defenders: 40.0,
				serial_click: 7,
				frequent_click: 13,
				rare_click: 40,
				name: "C-10"
			}, {
				defenders: 30.0,
				serial_click: 5,
				frequent_click: 15,
				rare_click: 50,
				name: "C-09"
			},{
				defenders: 40.0,
				serial_click: 10,
				frequent_click: 10,
				rare_click: 40,
				name: "C-08"
			}, {
				defenders: 25,
				serial_click: 5,
				frequent_click: 20,
				rare_click: 50,
				name: "C-07"
			},{
				defenders: 27.0,
				serial_click: 10,
				frequent_click: 10,
				rare_click: 53,
				name: "C-06"
			}, {
				defenders: 15,
				serial_click: 20,
				frequent_click: 10,
				rare_click: 55,
				name: "C-05"
			},{
				defenders: 20,
				serial_click: 18,
				frequent_click: 12,
				rare_click: 50.0,
				name: "C-04"
			}, {
				defenders: 10.0,
				serial_click: 18,
				frequent_click: 22,
				rare_click: 50,
				name: "C-03"
			},{
				defenders: 0.0,
				serial_click: 22,
				frequent_click: 25,
				rare_click: 53,
				name: "C-02"
			}, {
				defenders: 0.0,
				serial_click: 28,
				frequent_click: 22.0,
				rare_click: 50,
				name: "C-01"
			}];
			this.setData(mockData);

			this.setColors();
			this.showChart = true;
		},
		// Set the datasets of the chart
		setData(data) {
			this.chartData = {
				labels: [],
				active: [],
				datasets: []
			};
			this.chartLabels.forEach((label) => {
				const dataset = {};
				dataset.label = label;
				dataset.data = [];
				dataset.sent = [];
				dataset.clicked = [];
				dataset.backgroundColor = [];
				dataset.hoverBackgroundColor = [];
				dataset.hoverBorderColor = [];
				dataset.borderColor = [];
				dataset.borderWidth = 1;
				dataset.maxBarThickness = 150;
				this.chartData.datasets.push(dataset);
			});

			// Reverse dataset so that Smarter targets will appear closer to Cyber Chain x axis
			data.reverse().forEach((d) => {
				this.chartData.labels.push(d.name);
				this.chartData.datasets[this.getInvertedRiskGroup(this.riskGroup.Serial)].data.push(d.serial_click);
				this.chartData.datasets[this.getInvertedRiskGroup(this.riskGroup.Frequent)].data.push(d.frequent_click);
				this.chartData.datasets[this.getInvertedRiskGroup(this.riskGroup.Rare)].data.push(d.rare_click);
				this.chartData.datasets[this.getInvertedRiskGroup(this.riskGroup.Defender)].data.push(d.defenders);
			});
		},
		getInvertedRiskGroup(riskGroup) {
			const riskGroupDisplayed = 4
			const irs =  riskGroup + ((riskGroupDisplayed + 1)- 2 * riskGroup) -1;	
			return irs
		},
		setOptions() {
			this.chartOptions = {
				layout: {
					padding: {
						top: 0
					}
				},
				scales: {
					x: {
							stacked: true,
						},
					y: {
							stacked: true,
							ticks: {
								min: 0,
								callback: function (value) {
									return ((value / this.max) * 100).toFixed(0) + "%";
								}
							},
							scaleLabel: {
								display: true
							}
						}
				},
				legend: {
					display: true,
					labels: {
						fontColor: colors.plain.primary,
						fontSize: 16,
						padding: 20,
					}
				},
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					title: {
						display: false,
						// text: "Evoluzione del rischio",
						align: "start"

					},
					stacked100: { enable: true, replaceTooltipLabel: false },
					datalabels: {
						formatter: function (_value, context) {
							const data = context.chart.data;
							const { datasetIndex, dataIndex } = context;
							return data.calculatedData[datasetIndex][dataIndex] !== 0 ? data.calculatedData[datasetIndex][dataIndex].toFixed(1) + "%" : "";
						},
						display: function(context) {
							const data = context.chart.data;
							const { datasetIndex, dataIndex } = context;
							return data.calculatedData[datasetIndex][dataIndex] >= 4;
						},
						color: colors.plain.primary,
						clamp: true,
						clip: true,
						font: {
							size: 13
						}
					}
				}
			};
		},
		// Initialize the chart
		setColors() {
			this.chartData.datasets.forEach((dataset, datasetIndex) => {
				dataset.data.forEach((value, elementIndex) => {
					this.chartData.datasets[datasetIndex].backgroundColor[elementIndex] = colors.palette.riskGroup.alpha.cg2[datasetIndex];
					this.chartData.datasets[datasetIndex].hoverBackgroundColor[elementIndex] = colors.palette.riskGroup.alpha.cg2[datasetIndex];
					this.chartData.datasets[datasetIndex].hoverBorderColor[elementIndex] = colors.palette.riskGroup.alpha.cg2[datasetIndex];
					this.chartData.datasets[datasetIndex].borderColor[elementIndex] = colors.palette.riskGroup.alpha.cg2[datasetIndex];
				});
			});
		}
	}
}
</script>

<style lang="scss">
.overlay.ccs {
    z-index: 999;
	width: calc(100% - 31px);
	border-radius: .25rem;
	margin: 0 16px;
	opacity: 0.2;
}
.padding-200 {
	height: 200px;
}
.overlay-text {
	top: calc(100% - -280px) !important;
    z-index: 999;
	width: calc(100% - 31px);
	margin: 0 16px;
	height: 100%;
    position: absolute;
	text-align: center;
	word-break: break-word;
	font-size: 30px;
	font-weight: bold;
	.outline {
		-webkit-text-stroke: 1px #cac3c3;
		user-select: none;
	}
}
</style>