<template>
  <div class="cards-grid">
    <Txt v-if="title" as="h2" class="cards-grid-title" type="h4">{{ title }}</Txt>

    <div class="cards-grid-content">
      <slot />
    </div>

    <div v-if="showLoadMore" class="cards-grid-loadmore">
      <slot v-if="$slots.footer" name="footer" />
      <Button v-else :disabled="isLoading" @click="$emit('load-more')">{{ loadMoreLabel }}</Button>
    </div>
  </div>
</template>

<script>
import Button from './Button.vue'
import Txt from './Txt.vue'

export default {
  components: { Txt, Button },
  props: {
    title: {
      type: String,
      default: undefined,
    },
    showLoadMore: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    loadMoreLabel: {
      type: String,
      default: 'Carica di più',
    },
  },
  emits: ['load-more'],
}
</script>

<style lang="scss" scoped>
.cards-grid {
}

.cards-grid-title {
  @apply mb-5;
}

.cards-grid-content {
  @apply grid grid-flow-row gap-5;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.cards-grid-loadmore {
  @apply flex flex-col items-center justify-center;
  @apply p-6 mt-5;
}
</style>
