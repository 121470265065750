<template>
	<AnalyticsCard layout="vertical" :title="title" :chart-height="chartHeight" :chart-width="chartWidth || calcChartWidth" :showLoader="isLoading">
        <template #chart>
            <BarChart
                v-if="!isLoading && options.length && chart.datasets?.length && chart.datasets[0].data.length"
                :datasets="chart.datasets"
                :labels="chart.labels"
                show-x-border
                show-y-labels
                show-x-labels
                show-x-intra-lines
                show-y-intra-lines
                :aspect-ratio="aspectRatio"
                :chart-options="chartOptions"
                />
            <div
                v-if="!isLoading && (!options.length || chart.datasets?.length && !chart.datasets[0].data.length)"
                class="!min-h-[13rem] flex font-bold text-xl">
                <div class="m-auto"> <Txt bold>{{ $t('Dashboard.Charts.NoData') }}</Txt> </div>
            </div>
        </template>
        <template v-if="options.length" #menu-items>
            <MenuItem v-for="opt in options" :key="opt.key" @click="showData(opt)">{{opt.label || opt.key}}</MenuItem>
        </template>
    </AnalyticsCard>
</template> 

<script>
import phishingService from '@/services/phishing.service.js'
import colors from '@/utils/colors'

export default {
	data() {
		return {
            isLoading: false,
			data: {},
            calcChartWidth: null,
            title: this.$t("Dashboard.Charts.ClickRateByTagDefault"),
			chartOptions: {},
			chart : {},
            counter: 0
		}
	},
	props: {
        aspectRatio : Number,
        chartHeight: Number,
        chartWidth: Number,
        options : Array,
        apiFilter: Object,
        companyData: Object
	},
    watch: {
        '$props.apiFilter': {
            handler() {
                this.loadData()
            },
            deep: true
        }
    },
    mounted(){
        this.loadData()
	},
	methods : {
        loadData() {
            this.data = {}
            this.initDataSet('')
            const team = this.options && this.options.length > 0 && this.options.find(o=>o.team)
            this.showData(team || this.options[0])
        },
        initDataSet(tag){
            if (!tag) return;
            this.chart = {
                datasets: [
                    {
                        label: this.$t("Dashboard.Charts.ClickRateByTag", { tag: tag.label || tag.key }),
                        backgroundColor: colors.violet[500],
                        data: [],
                    }
                ],
                labels: []
            }
        },
        async showData(tag){
            try {
                this.calcChartWidth = 0;
                this.title = this.$t("Dashboard.Charts.ClickRateByTag", { tag: tag?.label || tag?.key || '-'})
                if (!tag) return;

                this.counter++;
                this.isLoading = true;
                this.$forceUpdate()
                if(!this.data[tag.key]) {
                    const resByTags = await phishingService.getCompanyTagSummary(this.companyData.company_id, tag.key, this.apiFilter)
                    let currData = (resByTags.data || []).sort((a,b)=>{ return a.tag? a.tag.localeCompare(b.tag) : 1 });
                    this.data[tag.key] = currData;
            
                    // Calculate the chart width (Namely add 200px for 15 elements, 400px for 38 elements and so on, plus 300px for each 60 elements)
                    if (currData.length) this.calcChartWidth = ((Math.ceil(currData.length / 3) * 100) + (300 * Math.ceil(currData.length / 60)));
                }

                this.initDataSet(tag);
                this.chart.datasets[0].label=tag.label || tag.key
                this.transformData(tag)
                this.setOptions()
                this.$emit('menu-item-click', tag)
            } catch (error) {
				this.$eventBus.$emit('show-alert', {title: this.$t('General.DataFetchError'), variant: 'danger'});
			} finally {
				if (this.counter > 0) this.counter--;
                
				if (!this.counter) this.isLoading = false;
			}
        },
		transformData(tag){
           for(const c of this.data[tag.key]){
                // rename null tag to the default 'Not Assigned' label
                if (!c.tag) c.tag = this.$t('Analytics.NA');
                
                this.chart.labels.push(c.tag)
                this.chart.datasets[0].data.push(c.click_rate)
            }
		},
		setOptions(){
			this.chartOptions = {
                scales:{
                    y : {
                        ticks: {
                                min: 0,
                                callback: function (value) {
                                    return value + "%";
                                }
                            },
                    }
                },
				plugins: {
                    tooltip: {
                        callbacks : {
                            label: (tooltipItem) => {
                                return `${this.$t('Dashboard.Charts.ClickRate')}: ${tooltipItem.formattedValue}%`;
                            },
                        }
                    }
                }
			}
		}
	}
};
</script>