<template>
  <Card
      :class="{
      'analytics-card': true,
      'l-horizontal': layout === 'horizontal',
      'l-vertical': layout === 'vertical',
      'l-empty': layout === 'empty',
      'l-chart-start': chartPosition === 'start',
      'l-chart-end': chartPosition === 'end',
      'v-has-menu': !!$slots['menu-items'],
    }"
      :to="to"
      :customClass="customClass"
      :customStyle="customStyle"
  >
    <Loader :show="showLoader"/>
    <div class="analytics-card-content">
      <Txt weight="bold" class="analytics-card-title" no-margin>
        <span v-if="title">{{ title }}</span>
        <slot v-if="$slots.title" name="title"/>
      </Txt>
      <div class="analytics-card-data">
        <Txt v-if="description" class="analytics-card-description" extra-small>{{ description }}</Txt>
        <div class="flex items-center">
          <div v-if="dotColor" class="dot" :style="{ backgroundColor: dotColor }"></div>
          <Txt v-if="value" class="analytics-card-value" no-margin>{{ value }}</Txt>
        </div>
        <div v-if="$slots.sub" class="analytics-card-sub">
          <slot name="sub"/>
        </div>
      </div>
      <div v-if="$slots.chart" class="analytics-card-chart" :style="{ minHeight: chartHeight ? `${chartHeight}px` : undefined, minWidth: chartWidth? `${chartWidth}px` : undefined }">
        <slot name="chart"/>
      </div>
      <div v-if="$slots['menu-items']" class="analytics-card-menu">
        <Menu>
          <template #button>
            <Button small variant="ghost" :aria-label="$t('General.Actions')">
              <Icon name="more" :size="20" class="text-violet-700/50"/>
            </Button>
          </template>
          <slot name="menu-items"/>
        </Menu>
      </div>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/atoms/Card.vue';
import Menu from '@/components/atoms/Menu.vue';
import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import Txt from '@/components/atoms/Txt.vue';

export default {
  components: {Card, Menu, Button, Icon, Txt},
  props: {
    title: {
      type: String,
      default: undefined,
    },
    value: {
      type: String,
      default: undefined,
    },
    description: {
      type: String,
      default: undefined,
    },
    layout: {
      type: String, // 'horizontal' | 'vertical' | 'empty'
      default: 'horizontal',
    },
    chartPosition: {
      type: String, // 'start' | 'end'
      default: 'end',
    },
    chartHeight: {
      type: Number,
      default: undefined,
    },
    chartWidth: {
      type: Number,
      default: undefined,
    },
    to: {
      type: String,
      default: undefined,
    },
    customClass: {
      type: String,
      default: 'bg-white',
    },
    customStyle: {
      type: [String, Object],
      default: '',
    },
    coloredDot: {
      type: Boolean,
      default: false,
    },
    dotColor: {
      type: String,
      default: null
    },
    showLoader: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style lang="scss" scoped>
.analytics-card {
  min-height: 150px;
  
  .white-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.analytics-card-content {
  &::-webkit-scrollbar {
    @apply appearance-none;
    // @apply w-3 #{!important};
    width: 0.75rem !important;
    @apply invisible;
  }
  &:hover, &:focus, &:active {
    &::-webkit-scrollbar {
      @apply visible
    }
    &::-webkit-scrollbar-track {
      @apply bg-transparent;
      @apply rounded-full;
      @apply my-2;
    }
    &::-webkit-scrollbar-thumb {
      @apply rounded-full;
      @apply bg-primary-300/50;
    }
    & {
      scrollbar-width: thin;
      scrollbar-color: rgb(131 135 154 / 0.5)  transparent;
      @apply scroll-my-1;
    }
  }

  @apply flex w-full h-full overflow-x-auto overflow-y-hidden;

  .l-horizontal & {
    @apply flex-row flex-wrap items-center;
  }

  .l-vertical & {
    @apply flex-col;
  }

  .l-empty & {
    @apply flex-col items-center text-center;
    @apply m-auto p-10;
    @apply max-w-md;
  }

  .dot {
    @apply w-4 h-4 rounded-full;
    @apply mr-2 -mt-1.5;
  }
}

.analytics-card-title {
  @apply flex items-start;
  @apply order-1;

  .l-horizontal &,
  .l-vertical & {
    @apply order-1;
  }

  .l-horizontal & {
    @apply basis-full;
  }

  .l-empty & {
    @apply order-2;
  }

  .v-has-menu & {
    @apply order-2;
    @apply pr-5;
  }
}

.analytics-card-data {
  @apply flex-1;
  @apply flex flex-col;

  .l-horizontal.l-chart-start &,
  .l-vertical.l-chart-start & {
    @apply order-3;
  }

  .l-horizontal.l-chart-end &,
  .l-vertical.l-chart-end & {
    @apply order-2;
  }

  .l-empty & {
    @apply order-3;
  }

  .l-horizontal.l-chart-start & {
    @apply ml-4;
  }

  .l-horizontal.l-chart-end & {
    @apply mr-4;
  }

  .v-has-menu.l-horizontal.l-chart-start & {
    @apply pt-4;
  }
}

.analytics-card-chart {
  @apply shrink-0;

  .l-horizontal & {
    @apply w-1/2;
  }

  .l-vertical & {
    @apply flex-grow min-h-[50%];
  }

  .l-horizontal.l-chart-start &,
  .l-vertical.l-chart-start & {
    @apply order-2;
  }

  .l-horizontal.l-chart-end &,
  .l-vertical.l-chart-end & {
    @apply order-3;
  }

  .l-vertical & {
    @apply mt-4;
  }

  .l-empty & {
    @apply order-1;
    @apply mb-4;
    @apply w-full max-w-xs mx-auto;
  }

  .v-has-menu.l-horizontal.l-chart-end & {
    @apply pt-5;
  }
}

.analytics-card-description {
  @apply mb-2;
  @apply text-primary-700/70;

  .l-empty & {
    @apply mt-2 mb-0;
    @apply opacity-70;
  }
}

.analytics-card-value {
  @apply text-4xl font-bold;
}

.analytics-card-sub {
  @apply mt-3;
}

.analytics-card-menu {
  @apply absolute right-2 top-3;
}
</style>
